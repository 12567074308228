import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import request from '../../plugins/api';
import CompanySetting from './CompanySetting';
import ShopSetting from './ShopSetting';
import ShopClientSetting from './ShopClientSetting';

export interface CreateShopRequest {
  name: string;
  enName: string;
}

export interface CreateShopClientRequest {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  roles: string[];
}

export interface CreateCompanyRequest {
  name: string;
  stripeOwnerId: string;
}

const OnBoardingNote: React.FC = () => (
  <div style={{ margin: '10px', border: '1px solid #4a4a4a', padding: '10px' }}>
    Note: <br />
    ・全項目を入力してください <br />
    ・StripeOwnerId以外はあとで修正が可能です
    <br />
    ・店舗アカウントは，[わかりやすい名前]@shop.dinii.jp としてください
    <br />
    ・パスワードは6文字以上で，店舗にシェアするので，3847tamaru9172 などのフォーマットにしてください
    <br />
    ・[後で修正します] パスワードは忘れないようにメモしてください
    <br />
    ・店舗アカウントのrole設定は，特に何もなければ初期値で進めると，Stripe設定権限がつきます
    <br />
  </div>
);

const createInitialData = async (
  company: CreateCompanyRequest,
  shop: CreateShopRequest,
  shopClient: CreateShopClientRequest,
) => {
  const createCompany = {
    name: company.name,
    stripeOwnerId: company.stripeOwnerId,
    allowedDomain: 'localhost',
  };
  const companyRes = await request.post('companies', createCompany, {
    headers: { 'x-company-id': 1 },
  });
  const companyId = companyRes!.data.companyId;
  const createCompanyConfig = {
    companyId: companyId,
    versionCode: '2',
    stripeOwnerId: company.stripeOwnerId,
    liffId: 'temp',
    allowedDomain: 'localhost',
  };
  const companyConfigRes = await request.post(
    `companies/${companyId}/config`,
    createCompanyConfig,
    {
      headers: { 'x-company-id': companyId },
    },
  );
  console.log('conf:', companyConfigRes.data);
  const createShop = {
    companyId,
    name: shop.name,
    nameEn: shop.enName,
    open: '11:00',
    close: '23:00',
    exTax: true,
    paymentType: 'all',
    topImageUrl: 'https://',
    naviImageUrl: 'https://',
    lastOrderText: 'xx',
  };
  const shopRes = await request.post('shops', createShop, {
    headers: { 'x-company-id': companyId },
  });
  const shopId = shopRes!.data.shopId;
  const createShopClient = {
    companyId,
    email: shopClient.email,
    password: shopClient.password,
    name: shopClient.name,
    roles: shopClient.roles,
  };
  const shopClientRes = await request.post(`shops/${shopId}/shop_clients`, createShopClient, {
    headers: { 'x-company-id': companyId },
  });
  console.log('shopClientRes:', shopClientRes.data);
};

const OnBoarding: React.FC = () => {
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [company, setCompany] = useState({} as CreateCompanyRequest);
  const [shop, setShop] = useState({} as CreateShopRequest);
  const [shopClient, setShopClient] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    roles: ['shop_admin', 'shop_stripe'],
  } as CreateShopClientRequest);
  const [responseMessage, setResponseMessage] = useState('' as string);

  const _setCompany = (data: any) => {
    setCompany(prev => ({ ...prev, ...data }));
  };
  const _setShop = (data: any) => {
    setShop(prev => ({ ...prev, ...data }));
  };
  const _setShopClient = (data: any) => {
    setShopClient(prev => ({ ...prev, ...data }));
  };

  const apply = () => {
    if (!shopClient.password || shopClient.password !== shopClient.confirmPassword) {
      return alert('パスワードが一致しません');
    }
    const f = async () => {
      try {
        await createInitialData(company, shop, shopClient);
        setResponseMessage(
          '作成に成功しました．ダッシュボードから登録できます．https://dashboard.self.dinii.jp',
        );
        setDialogMessage('OK');
      } catch (err) {
        setDialogMessage(`作成に失敗しました: ${err}`);
      }
    };
    f();
  };
  return (
    <>
      <div>
        <h1>{responseMessage}</h1>
      </div>
      <OnBoardingNote />
      <CompanySetting setCompany={_setCompany} />
      <ShopSetting setShop={_setShop} />
      <ShopClientSetting setShopClient={_setShopClient} createShopClientRequest={shopClient} />
      <Button
        style={{ width: '300px', fontSize: '20px', margin: '10px' }}
        color="primary"
        variant="contained"
        onClick={apply}
      >
        作成する
      </Button>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default OnBoarding;
