import { Role } from '../../../entity/role.entity';
import { Menu } from '../../../entity/menu.entity';
import { Category } from '../../../entity/category.entity';
import { DishUpReceiptGroup } from '../../../entity/dishUpReceiptGroup.entity';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import request from '../../../plugins/api';
import { Shop } from '../../../entity/shop.entity';
import { useShops } from '../../../hooks/useShops';
import { Plan } from '../../../entity/plan.entity';
import { uniq } from 'lodash';

interface UpdateDishUpReceiptGroupDto {
  name: string;
  roleIds: number[];
  menuIds: number[];
  planIds: number[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
    section: {
      margin: 20,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

interface Props {
  shop: Shop;
  visible: boolean;
  onDismiss(): void;
  dishUpReceiptGroup?: DishUpReceiptGroup;
  roles: Role[];
  menus: Menu[];
  plans: Plan[];
  categories: Category[];
  refresh(): void;
}

export const CreateDishUpReceiptGroupModal: React.FC<Props> = ({
  shop,
  visible,
  onDismiss,
  dishUpReceiptGroup,
  roles,
  menus,
  plans,
  categories,
  refresh,
}) => {
  const classes = useStyles();
  const { setIsLoading } = useShops();
  const [updateDto, setUpdateDto] = useState<UpdateDishUpReceiptGroupDto>({
    name: '',
    menuIds: [],
    planIds: [],
    roleIds: [],
  });

  useEffect(() => {
    if (dishUpReceiptGroup) {
      setUpdateDto({
        name: dishUpReceiptGroup.name,
        menuIds: dishUpReceiptGroup.menus?.map(menu => menu.menuId) ?? [],
        planIds: dishUpReceiptGroup.plans?.map(plan => plan.planId) ?? [],
        roleIds: dishUpReceiptGroup.roles?.map(role => role.roleId) ?? [],
      });
    } else {
      setUpdateDto({
        name: '',
        menuIds: [],
        planIds: [],
        roleIds: [],
      });
    }
  }, [dishUpReceiptGroup]);

  useEffect(() => console.log({ updateDto }), [updateDto]);

  const changeRole = useCallback(
    (roleId: number) => {
      const index = updateDto.roleIds.findIndex(id => id === roleId);
      if (index > -1) {
        const newArray = [...updateDto.roleIds];
        newArray.splice(index, 1);
        setUpdateDto({ ...updateDto, roleIds: newArray });
      } else {
        setUpdateDto({ ...updateDto, roleIds: updateDto.roleIds.concat([roleId]) });
      }
    },
    [updateDto],
  );

  const changeMenu = useCallback(
    (menuId: number) => {
      const index = updateDto.menuIds.findIndex(id => id === menuId);
      if (index > -1) {
        const newArray = [...updateDto.menuIds];
        newArray.splice(index, 1);
        setUpdateDto({ ...updateDto, menuIds: newArray });
      } else {
        setUpdateDto({ ...updateDto, menuIds: updateDto.menuIds.concat([menuId]) });
      }
    },
    [updateDto],
  );

  const applyAllMenus = useCallback(() => {
    setUpdateDto({ ...updateDto, menuIds: menus.map(m => m.menuId) });
  }, [menus, updateDto]);

  const applyAllMenusInCategory = useCallback(
    (categoryId: number | undefined) => {
      if (typeof categoryId === 'undefined') {
        return window.alert('カテゴリが選択されていません');
      }
      const menuIdsInCategory = menus.filter(m => m.categoryId === categoryId).map(m => m.menuId);
      const newIds = uniq([...updateDto.menuIds, ...menuIdsInCategory]);
      setUpdateDto({ ...updateDto, menuIds: newIds });
    },
    [menus, updateDto],
  );

  const changePlan = useCallback(
    (planId: number) => {
      const index = updateDto.planIds.findIndex(id => id === planId);
      if (index > -1) {
        const newArray = [...updateDto.planIds];
        newArray.splice(index, 1);
        setUpdateDto({ ...updateDto, planIds: newArray });
      } else {
        setUpdateDto({ ...updateDto, planIds: updateDto.planIds.concat([planId]) });
      }
    },
    [updateDto],
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(undefined);

  const confirm = useCallback(async () => {
    try {
      setIsLoading(true);
      if (dishUpReceiptGroup) {
        await request
          .post(
            `shops/${shop.shopId}/dish_up_receipt_groups/${dishUpReceiptGroup.dishUpReceiptGroupId}`,
            updateDto,
            {
              headers: { 'x-company-id': shop.companyId },
            },
          )
          .catch(e => window.alert(e))
          .finally();
      } else {
        request
          .post(`shops/${shop.shopId}/dish_up_receipt_groups`, updateDto, {
            headers: { 'x-company-id': shop.companyId },
          })
          .then(_ => {
            window.alert('更新成功');
            onDismiss();
            refresh();
          })
          .catch(e => window.alert(e));
      }
      window.alert('作成/更新に成功しました');
      onDismiss();
      refresh();
    } catch (error) {
      window.alert({ title: '失敗しました', error });
    } finally {
      setIsLoading(false);
    }
  }, [updateDto, shop, dishUpReceiptGroup, onDismiss, refresh, setIsLoading]);

  return (
    <Dialog fullWidth maxWidth={'md'} open={visible} onClose={onDismiss}>
      <DialogTitle id="simple-dialog-title">
        {dishUpReceiptGroup ? dishUpReceiptGroup.name : '新規作成'}
      </DialogTitle>
      <DialogContent>
        <Paper className={classes.container}>
          <div className={classes.section}>
            <Typography variant={'h5'}>グループ名設定</Typography>
            <TextField
              autoFocus
              label={'グループ名'}
              value={updateDto.name}
              onChange={event => setUpdateDto({ ...updateDto, name: event.target.value })}
            />
          </div>

          <div className={classes.section}>
            <Typography variant={'h5'}>Role設定</Typography>
            <List>
              {roles.map(role => {
                const key = role.roleId;
                return (
                  <ListItem key={key} role="listitem" button onClick={() => changeRole(key)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={updateDto.roleIds.includes(key)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={role.name} />
                  </ListItem>
                );
              })}
            </List>
          </div>

          <div className={classes.section}>
            <Typography variant={'h5'}>Menu設定</Typography>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <List style={{ width: '50%' }}>
                <Typography variant={'h6'}>Menu一覧</Typography>
                <Button color="primary" onClick={() => applyAllMenus()}>
                  全てONにする
                </Button>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label">カテゴリ選択</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectedCategoryId}
                    onChange={event => {
                      setSelectedCategoryId(event.target.value as number);
                    }}
                  >
                    {categories.map(category => (
                      <MenuItem key={category.categoryId} value={category.categoryId}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button onClick={() => setSelectedCategoryId(undefined)}>フィルタ解除</Button>
                <Button color="primary" onClick={() => applyAllMenusInCategory(selectedCategoryId)}>
                  このカテゴリの全メニューを登録する
                </Button>
                {menus
                  .filter(menu =>
                    selectedCategoryId ? menu.categoryId === selectedCategoryId : true,
                  )
                  .map(menu => {
                    const key = menu.menuId;
                    return (
                      <ListItem key={key} role="listitem" button onClick={() => changeMenu(key)}>
                        <ListItemIcon>
                          <Checkbox
                            checked={updateDto.menuIds.includes(key)}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={menu.name} />
                      </ListItem>
                    );
                  })}
              </List>
              <List style={{ width: '50%' }}>
                <Typography variant={'h6'}>選択中の全てのMenu一覧</Typography>
                {menus
                  .sort((a, b) => b.priority - a.priority)
                  .filter(menu => updateDto.menuIds.includes(menu.menuId))
                  .map(menu => {
                    const key = menu.menuId;
                    return (
                      <ListItem key={key} role="listitem" button onClick={() => changeMenu(key)}>
                        <ListItemIcon>
                          <Checkbox checked={true} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={menu.name} />
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </div>

          <div className={classes.section}>
            <Typography variant={'h5'}>Plan設定</Typography>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <List style={{ width: '50%' }}>
                <Typography variant={'h6'}>Plan一覧</Typography>
                {plans.map(plan => {
                  const key = plan.planId;
                  return (
                    <ListItem key={key} role="listitem" button onClick={() => changePlan(key)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={updateDto.planIds.includes(key)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={plan.receiptDisplayName} />
                    </ListItem>
                  );
                })}
              </List>
              <List style={{ width: '50%' }}>
                <Typography variant={'h6'}>選択中のPlan一覧</Typography>
                {plans
                  .sort((a, b) => b.priority - a.priority)
                  .filter(plan => updateDto.planIds.includes(plan.planId))
                  .map(plan => {
                    const key = plan.planId;
                    return (
                      <ListItem key={key} role="listitem" button onClick={() => changePlan(key)}>
                        <ListItemIcon>
                          <Checkbox checked={true} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={plan.receiptDisplayName} />
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} disabled={!updateDto?.name} variant={'contained'} color="primary">
          {dishUpReceiptGroup ? '更新' : '作成'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
