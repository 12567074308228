import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Paper,
  Switch,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Choice, Menu } from '../../../../entity/menu.entity';
import request from '../../../../plugins/api';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateChoiceRequest {
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  price: number;
  isDefaultSelection: boolean;
  priority: number;
  maxOrderableNum: number;
}

interface Props {
  menu: Menu;
  optionId: number;
  isOpen: boolean;
  onClose: () => void;
  updateChoice: (choice: Choice) => void;
}

const CreateChoice: React.FC<Props> = ({
  menu,
  optionId,
  isOpen,
  onClose,
  updateChoice,
}: Props) => {
  const [createChoiceRequest, setCreateChoiceRequest] = useState({
    name: '',
    nameEn: '',
    receiptDisplayName: '',
    price: 0,
    priority: 0,
    isDefaultSelection: false,
  } as CreateChoiceRequest);

  useEffect(() => {
    setCreateChoiceRequest({
      name: '',
      nameEn: '',
      receiptDisplayName: '',
      price: 0,
      isDefaultSelection: false,
      priority: 0,
      maxOrderableNum: -1,
    });
  }, [menu, optionId]);

  const createChoice = () => {
    const createBody = {
      companyId: menu.companyId,
      optionId,
      ...createChoiceRequest,
    };
    request
      .post(`shops/${menu.shopId}/choices`, createBody, {
        headers: { 'x-company-id': menu.companyId },
      })
      .then(response => {
        updateChoice(response.data);
        onClose();
      })
      .catch(err => console.log(JSON.stringify(err, null, 2)));
  };
  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Option</DialogTitle>
        <DialogContent>
          <Paper>
            <>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={1}>
                    <p>choice name:</p>
                    <TextField
                      defaultValue={createChoiceRequest.name}
                      type="string"
                      id="filled-basic"
                      label="name"
                      variant="filled"
                      onChange={ev =>
                        setCreateChoiceRequest({
                          ...createChoiceRequest,
                          name: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>choice enName:</p>
                    <TextField
                      defaultValue={createChoiceRequest.nameEn}
                      type="string"
                      id="filled-basic"
                      label="name"
                      variant="filled"
                      onChange={ev =>
                        setCreateChoiceRequest({
                          ...createChoiceRequest,
                          nameEn: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>choice receiptDisplayName:</p>
                    <TextField
                      defaultValue={createChoiceRequest.receiptDisplayName}
                      type="string"
                      id="filled-basic"
                      label="receiptDisplayName"
                      variant="filled"
                      onChange={ev =>
                        setCreateChoiceRequest({
                          ...createChoiceRequest,
                          receiptDisplayName: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>isDefaultSelection:</p>
                    <Switch
                      required
                      checked={createChoiceRequest.isDefaultSelection}
                      onChange={() =>
                        setCreateChoiceRequest({
                          ...createChoiceRequest,
                          isDefaultSelection: !createChoiceRequest.isDefaultSelection,
                        })
                      }
                      value="checkedA"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>price</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createChoiceRequest.price}
                        type="number"
                        id="filled-basic"
                        label="price"
                        variant="filled"
                        onChange={ev =>
                          setCreateChoiceRequest({
                            ...createChoiceRequest,
                            price: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>maxOrderableNum</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createChoiceRequest.maxOrderableNum}
                        type="number"
                        id="filled-basic"
                        label="maxOrderableNum"
                        variant="filled"
                        onChange={ev =>
                          setCreateChoiceRequest({
                            ...createChoiceRequest,
                            maxOrderableNum: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>priority</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createChoiceRequest.priority}
                        type="number"
                        id="filled-basic"
                        label="minChoiceNum"
                        variant="filled"
                        onChange={ev =>
                          setCreateChoiceRequest({
                            ...createChoiceRequest,
                            priority: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                </Grid>
                <Button onClick={() => createChoice()}>Create</Button>
              </div>
            </>
          </Paper>
          <Button onClick={onClose}>close</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateChoice;
