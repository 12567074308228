import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import CreateShopAdminDialog from './CreateShopAdminDialog/CreateShopAdminDialog';
import { ShopAdmin } from '../../../entity/shopAdmin.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const ShopAdmins: React.FC = () => {
  const classes = useStyles();
  const [createShopAdminModalOpen, setCreateShopAdminModalOpen] = useState(false);

  const [shopAdmins, setShopAdmins] = useState<ShopAdmin[]>([]);

  useEffect(() => {
    request
      .get('shop_admins', {
        headers: { 'x-company-id': 1 },
      })
      .then(response => {
        setShopAdmins(response.data);
      });
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateShopAdminModalOpen(true)}
      >
        Create shopAdmin
      </Button>
      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ShopAdmin ID</TableCell>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopAdmins.map((shopAdmin, index) => (
              <TableRow key={index}>
                <TableCell align="left">{shopAdmin.shopAdminId}</TableCell>
                <TableCell align="left">{shopAdmin.companyId}</TableCell>
                <TableCell align="left">{shopAdmin.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <CreateShopAdminDialog
        isOpen={createShopAdminModalOpen}
        onClose={() => setCreateShopAdminModalOpen(false)}
      />
    </>
  );
};

export default ShopAdmins;
