import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import CreateMenuTypeDialog from './CreateMenuTypeDialog/CreateMenuTypeDialog';
import { Role } from '../../../entity/role.entity';
import { MenuType } from '../../../entity/menuType.entity';
import { CreateRoleDialog } from './CreateRoleDialog/CreateRoleDialog';
import { MenuTypeTypes } from '../../../constants';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    sectionLabel: {
      fontSize: '35px',
      margin: 20,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Roles: React.FC = () => {
  const classes = useStyles();
  const [roles, setRoles] = useState([] as Role[]);
  const [menuTypes, setMenuTypes] = useState([] as MenuType[]);
  const { selectedShop, selectedCompany } = useShops();
  // edit flags
  const [editStartIndex, setEditStartIndex] = useState(null as null | number);
  const [editEndIndex, setEditEndIndex] = useState(null as null | number);
  const [editRoleNameIndex, setEditRoleNameIndex] = useState(null as null | number);
  // edited data
  const [editedStart, setEditedStart] = useState(null as null | string);
  const [editedEnd, setEditedEnd] = useState(null as null | string);
  const [editedRoleName, setEditedRoleName] = useState(null as null | string);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createMenuTypeModalOpen, setCreateMenuTypeModalOpen] = useState(false);
  const [createRoleModalOpen, setCreateRoleModalOpen] = useState(false);

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/menu_types`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setMenuTypes(response.data);
      });
    request
      .get(`shops/${selectedShop.shopId}/roles`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setRoles(response.data);
      });
  }, [selectedShop, createMenuTypeModalOpen, createRoleModalOpen]);

  // eslint-disable-next-line max-len
  const handleShouldPrintPropertiesChange = (dataIndex: number, name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/roles/${roles[dataIndex].roleId}`,
        { [name]: event.target.checked },
        { headers: { 'x-company-id': selectedShop.companyId } },
      )
      .then(response => {
        setRoles(
          roles.map(role => {
            if (role.roleId === response.data.roleId) {
              return response.data;
            }
            return role;
          }),
        );
      });
  };
  const cancelEdit = () => {
    setEditStartIndex(null);
    setEditedStart(null);
    setEditEndIndex(null);
    setEditedEnd(null);
  };
  const applyChange = (editedIndex: number) => {
    const targetMenuType = menuTypes[editedIndex];
    // eslint-disable-next-line radix
    const updateValueBody = {
      start: editedStart || undefined,
      end: editedEnd || undefined,
    };
    request
      .post(
        `shops/${targetMenuType.shopId}/menu_types/${targetMenuType.menuTypeId}`,
        updateValueBody,
        { headers: { 'x-company-id': targetMenuType.companyId } },
      )
      .then(response => {
        setMenuTypes(
          menuTypes.map(menuType => {
            if (menuType.menuTypeId === response.data.menuTypeId) {
              return response.data;
            }
            return menuType;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };
  const applyRoleChange = (editedIndex: number) => {
    const targetRole = roles[editedIndex];
    const updateValueBody = {
      name: editedRoleName || undefined,
    };
    request
      .post(`shops/${targetRole.shopId}/roles/${targetRole.roleId}`, updateValueBody, {
        headers: { 'x-company-id': targetRole.companyId },
      })
      .then(response => {
        setRoles(
          roles.map(role => {
            if (role.roleId === response.data.roleId) {
              return response.data;
            }
            return role;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };

  const handleRolePrinterSoundSettings = (roleId: number, printerSound: number) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/roles/${roleId}`,
        { printerSound },
        { headers: { 'x-company-id': selectedShop.companyId } },
      )
      .then(response => {
        setRoles(
          roles.map(role => {
            if (role.roleId === response.data.roleId) {
              return response.data;
            }
            return role;
          }),
        );
        setDialogMessage('printer sound update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };

  const handleMenuTypeSetting = (menuTypeId: number, typeValue: string) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/menu_types/${menuTypeId}`,
        { type: typeValue },
        { headers: { 'x-company-id': selectedShop.companyId } },
      )
      .then(response => {
        setMenuTypes(
          menuTypes.map(menuType => {
            if (menuType.menuTypeId === response.data.menuTypeId) {
              return response.data;
            }
            return menuType;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };

  return (
    <>
      <div>
        <Button
          disabled={!selectedShop}
          variant="outlined"
          color="primary"
          style={{ width: '30vw', margin: '30px' }}
          onClick={() => setCreateRoleModalOpen(true)}
        >
          Create role
        </Button>
        <Button
          disabled={!selectedShop}
          variant="outlined"
          color="primary"
          style={{ width: '30vw', margin: '30px' }}
          onClick={() => setCreateMenuTypeModalOpen(true)}
        >
          Create menuType
        </Button>
      </div>
      <Paper>
        <p className={classes.sectionLabel}>Role</p>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Role ID</TableCell>
              <TableCell align="right">Role名</TableCell>
              <TableCell align="right">Printer Sound</TableCell>
              <TableCell align="right">TableIds</TableCell>
              <TableCell align="right">ShouldPrintReceipt</TableCell>
              <TableCell align="right">デシャップ印刷</TableCell>
              <TableCell align="right">デシャップ印刷時に、無料注文をスキップする</TableCell>
              <TableCell align="right">PCからの自動会計伝票発行</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {role.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {selectedCompany?.name}
                </TableCell>
                <TableCell align="right">{role.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">{role.roleId}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: role.name,
                    startEdit: () => setEditRoleNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyRoleChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedRoleName(val),
                    isShowEditForm: editRoleNameIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {['A', 'B', 'C', 'D', 'E'].map((soundValue, index) => (
                    <ListItem key={index} button>
                      <Typography>{soundValue}</Typography>
                      <Checkbox
                        edge="end"
                        onChange={() => handleRolePrinterSoundSettings(role.roleId, index + 1)}
                        checked={role.printerSound === index + 1}
                      />
                    </ListItem>
                  ))}
                </TableCell>
                <TableCell align="right">{role.tableIds}</TableCell>
                <TableCell align="right">
                  {role.shouldPrintReceipt}
                  <Switch
                    checked={role.shouldPrintReceipt}
                    onChange={handleShouldPrintPropertiesChange(index, 'shouldPrintReceipt')}
                    value="shouldPrintReceipt"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell align="right">
                  {role.shouldPrintDishUpReceipt}
                  <Switch
                    checked={role.shouldPrintDishUpReceipt}
                    onChange={handleShouldPrintPropertiesChange(index, 'shouldPrintDishUpReceipt')}
                    value="shouldPrintDishUpReceipt"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell align="right">
                  {role.removeFreeOrderFromDishUpReceipt}
                  <Switch
                    checked={role.removeFreeOrderFromDishUpReceipt}
                    onChange={handleShouldPrintPropertiesChange(
                      index,
                      'removeFreeOrderFromDishUpReceipt',
                    )}
                    value="removeFreeOrderFromDishUpReceipt"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell align="right">
                  {role.shouldPrintPaymentReceipt}
                  <Switch
                    checked={role.shouldPrintPaymentReceipt}
                    onChange={handleShouldPrintPropertiesChange(index, 'shouldPrintPaymentReceipt')}
                    value="shouldPrintPaymentReceipt"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Paper>
        <p className={classes.sectionLabel}>MenuType</p>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">MenuType ID</TableCell>
              <TableCell align="right">MenuType Name</TableCell>
              <TableCell align="right">MenuType start time</TableCell>
              <TableCell align="right">MenuType end time</TableCell>
              <TableCell align="right">MenuType type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuTypes.map((menuType, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {menuType.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {selectedCompany?.name}
                </TableCell>
                <TableCell align="right">{menuType.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">{menuType.menuTypeId}</TableCell>
                <TableCell align="right">{menuType.name}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menuType.start,
                    startEdit: () => setEditStartIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedStart(val),
                    isShowEditForm: editStartIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menuType.end,
                    startEdit: () => setEditEndIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedEnd(val),
                    isShowEditForm: editEndIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  <List
                    dense
                    style={{
                      maxHeight: '200px',
                      width: '300px',
                      overflow: 'auto',
                    }}
                  >
                    {Object.values(MenuTypeTypes).map((typeValue, typeIndex) => (
                      <ListItem key={typeIndex} button>
                        <ListItemText id={`${typeValue}`} primary={typeValue} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => handleMenuTypeSetting(menuType.menuTypeId, typeValue)}
                            checked={menuType.type === typeValue}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
      {selectedShop && (
        <>
          <CreateMenuTypeDialog
            shop={selectedShop}
            isOpen={createMenuTypeModalOpen}
            onClose={() => setCreateMenuTypeModalOpen(false)}
          />
          <CreateRoleDialog
            shop={selectedShop}
            isOpen={createRoleModalOpen}
            onClose={() => setCreateRoleModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Roles;
