import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import ApiResponseDialogComponent from '../components/apiResponseDialog';
import { Category } from '../../../entity/category.entity';
import CreateCategoryDialog from './CreateCategoryDialog/CreateCategoryDialog';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Categories: React.FC = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([] as Category[]);

  const { selectedShop, selectedCompany } = useShops();

  // edit row flags
  const [editNameIndex, setEditNameIndex] = useState(null as null | number);
  const [editNameEnIndex, setEditNameEnIndex] = useState(null as null | number);
  const [editPriorityIndex, setEditPriorityIndex] = useState(null as null | number);
  const [editShopSideNameIndex, setEditShopSideNameIndex] = useState(null as null | number);
  const [editImageUrlIndex, setEditImageUrlIndex] = useState(null as null | number);
  // edit data
  const [editedName, setEditedName] = useState(null as null | string);
  const [editedNameEn, setEditedNameEn] = useState(null as null | string);
  const [editedPriority, setEditedPriority] = useState(null as null | number);
  const [editedShopSideName, setEditedShopSideName] = useState<string | null>(null);
  const [editedImageUrl, setEditedImageUrl] = useState<string | null>(null);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const cancelEdit = () => {
    setEditNameIndex(null);
    setEditedName(null);
    setEditNameEnIndex(null);
    setEditedNameEn(null);
    setEditPriorityIndex(null);
    setEditedPriority(null);
    setEditedShopSideName(null);
  };

  useEffect(() => {
    if (selectedShop) {
      request
        .get(`shops/${selectedShop.shopId}/categories`, {
          headers: { 'x-company-id': selectedShop.companyId },
        })
        .then(response => {
          setCategories(response.data);
        });
    }
  }, [selectedShop]);

  const applyChange = (editedIndex: number) => {
    if (selectedShop) {
      const targetCategory = categories[editedIndex];
      const updateValueBody = {
        name: editedName || undefined,
        nameEn: editedNameEn || undefined,
        priority: editedPriority || undefined,
        shopSideName: editedShopSideName || undefined,
        imageUrl: editedImageUrl || undefined,
      };
      request
        .post(
          `shops/${selectedShop.shopId}/categories/${targetCategory.categoryId}`,
          updateValueBody,
          { headers: { 'x-company-id': selectedShop.companyId } },
        )
        .then(response => {
          setCategories(
            categories.map(category => {
              if (response.data.categoryId === category.categoryId) {
                return response.data;
              }
              return category;
            }),
          );
          setDialogMessage('update success');
        })
        .catch(err => setDialogMessage(JSON.stringify(err)));
    }
    cancelEdit();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateModalOpen(true)}
      >
        Create category
      </Button>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Category ID</TableCell>
              <TableCell align="right">Category Name</TableCell>
              <TableCell align="right">Category en name</TableCell>
              <TableCell align="right">店舗表示名</TableCell>
              <TableCell align="right">Category Priority</TableCell>
              <TableCell align="right">Category Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {selectedCompany?.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {selectedCompany?.name}
                </TableCell>
                <TableCell align="right">{selectedShop?.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">{category.categoryId}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: category.name,
                    startEdit: () => {
                      setEditNameIndex(index);
                      setEditedName(category.name);
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedName(val),
                    isShowEditForm: editNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: category.nameEn,
                    startEdit: () => {
                      setEditNameEnIndex(index);
                      setEditedNameEn(category.nameEn);
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedNameEn(val),
                    isShowEditForm: editNameEnIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: category.shopSideName,
                    startEdit: () => {
                      setEditShopSideNameIndex(index);
                      setEditedShopSideName(category.shopSideName);
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedShopSideName(val),
                    isShowEditForm: editShopSideNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: category.priority,
                    startEdit: () => {
                      setEditPriorityIndex(index);
                      setEditedPriority(category.priority);
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedPriority(Number(val)),
                    isShowEditForm: editPriorityIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: category.imageUrl,
                    startEdit: () => {
                      setEditImageUrlIndex(index);
                      setEditedImageUrl(category.imageUrl ?? '');
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedImageUrl(val),
                    isShowEditForm: editImageUrlIndex === index,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ApiResponseDialogComponent
        dialogMessage={dialogMessage}
        resetDialogMessage={() => setDialogMessage(null)}
      />
      {selectedShop && (
        <CreateCategoryDialog
          shop={selectedShop}
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        />
      )}
    </>
  );
};

export default Categories;
