import React from 'react';
import { Role } from '../../../entity/role.entity';
import { PrintStatus } from '../../../entity/order.entity';

interface Props {
  printStatus: PrintStatus;
  roles: Role[];
}

const PrintStatusComponent = ({ printStatus, roles }: Props) => {
  const findRoleNameById = (roleId: number): string => {
    const foundRole = roles.find(role => role.roleId === roleId);
    if (foundRole) {
      return foundRole.name;
    } else {
      return 'no-name';
    }
  };
  return (
    <>
      {Object.entries(printStatus).map(([roleId, { isRead, isPrinted }]) => {
        return (
          <p key={roleId}>
            {roleId}:, {findRoleNameById(Number(roleId))}
            <p
              style={{
                margin: '10px',
                padding: '10px',
                fontWeight: 'bold',
                color: 'white',
                background: isRead ? '#769df2' : '#f47a55',
              }}
            >
              {isRead ? 'read' : 'unread'}
            </p>
            <p
              style={{
                margin: '10px',
                padding: '10px',
                fontWeight: 'bold',
                color: 'white',
                background: isPrinted ? '#769df2' : '#f47a55',
              }}
            >
              {isPrinted ? 'printed' : 'not-printed'}
            </p>
          </p>
        );
      })}
    </>
  );
};

export default PrintStatusComponent;
