import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface Props {
  dialogMessage: string | null;
  resetDialogMessage: () => void;
}

export default function ApiResponseDialogComponent({ dialogMessage, resetDialogMessage }: Props) {
  return (
    <>
      <Dialog
        open={dialogMessage !== null}
        onClose={resetDialogMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
}
