import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Paper,
  Button,
  TextField,
  Grid,
  Switch,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Plan, PlanOption } from '../../../../entity/plan.entity';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreatePlanOptionRequest {
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  maxChoiceNum: number;
  minChoiceNum: number;
  priority: number;
  isMainOption: boolean;
}

interface Props {
  plan: Plan;
  isOpen: boolean;
  onClose: () => void;
  updateOption: (option: PlanOption) => void;
}

export const CreatePlanOptionDialog: React.FC<Props> = ({
  plan,
  isOpen,
  onClose,
  updateOption,
}: Props) => {
  const [createOptionRequest, setCreateOptionRequest] = useState({} as CreatePlanOptionRequest);

  useEffect(() => {
    setCreateOptionRequest({
      name: '',
      nameEn: '',
      receiptDisplayName: '',
      maxChoiceNum: 0,
      minChoiceNum: 0,
      priority: 0,
      isMainOption: false,
    });
  }, [plan]);

  const createOption = () => {
    const createBody = {
      companyId: plan.companyId,
      planId: plan.planId,
      name: createOptionRequest.name,
      nameEn: createOptionRequest.nameEn,
      receiptDisplayName: createOptionRequest.receiptDisplayName,
      minChoiceNum: createOptionRequest.minChoiceNum,
      maxChoiceNum: createOptionRequest.maxChoiceNum,
      priority: createOptionRequest.priority,
      isMainOption: createOptionRequest.isMainOption,
    };
    request
      .post(`shops/${plan.shopId}/plan_options`, createBody, {
        headers: { 'x-company-id': plan.companyId },
      })
      .then(response => {
        console.log('create option:', response);
        updateOption(response.data);
        onClose();
      })
      .catch(err => console.log(JSON.stringify(err, null, 2)));
  };
  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Plan Option</DialogTitle>
        <DialogContent>
          <Paper>
            <>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={1}>
                    <p>option name:</p>
                    <TextField
                      defaultValue={createOptionRequest.name}
                      type="string"
                      id="filled-basic"
                      label="name"
                      variant="filled"
                      onChange={ev =>
                        setCreateOptionRequest({
                          ...createOptionRequest,
                          name: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>option enName:</p>
                    <TextField
                      defaultValue={createOptionRequest.nameEn}
                      type="string"
                      id="filled-basic"
                      label="name"
                      variant="filled"
                      onChange={ev =>
                        setCreateOptionRequest({
                          ...createOptionRequest,
                          nameEn: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>option receiptDisplayName:</p>
                    <TextField
                      defaultValue={createOptionRequest.receiptDisplayName}
                      type="string"
                      id="filled-basic"
                      label="receiptDisplayName"
                      variant="filled"
                      onChange={ev =>
                        setCreateOptionRequest({
                          ...createOptionRequest,
                          receiptDisplayName: ev.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>maxChoiceNum</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createOptionRequest.maxChoiceNum}
                        type="number"
                        id="filled-basic"
                        label="maxChoiceNum"
                        variant="filled"
                        onChange={ev =>
                          setCreateOptionRequest({
                            ...createOptionRequest,
                            maxChoiceNum: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>minChoiceNum:</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createOptionRequest.minChoiceNum}
                        type="number"
                        id="filled-basic"
                        label="minChoiceNum"
                        variant="filled"
                        onChange={ev =>
                          setCreateOptionRequest({
                            ...createOptionRequest,
                            minChoiceNum: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>priority:</p>
                    <form noValidate autoComplete="off">
                      <TextField
                        defaultValue={createOptionRequest.priority}
                        type="number"
                        id="filled-basic"
                        label="minChoiceNum"
                        variant="filled"
                        onChange={ev =>
                          setCreateOptionRequest({
                            ...createOptionRequest,
                            priority: Number(ev.target.value),
                          })
                        }
                      />
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <p>isMainOption(プラン注文時にチビ伝票として出すか):</p>
                    <form noValidate autoComplete="off">
                      <Switch
                        value={createOptionRequest.isMainOption}
                        onChange={(event, checked) =>
                          setCreateOptionRequest({
                            ...createOptionRequest,
                            isMainOption: checked,
                          })
                        }
                      />
                    </form>
                  </Grid>
                </Grid>
                <Button onClick={() => createOption()}>Create</Button>
              </div>
            </>
          </Paper>
          <Button onClick={onClose}>close</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
