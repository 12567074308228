import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import MenuImageUrlEditor from './MenuImageUrlEditor';
import { Menu, TaxMethod } from '../../../entity/menu.entity';
import CreateMenuDialog from './CreateMenuDialog/CreateMenuDialog';
import { Category } from '../../../entity/category.entity';
import { MenuType } from '../../../entity/menuType.entity';
import { Role } from '../../../entity/role.entity';
import OptionDialog from './OptionDialog/OptionDialog';
import pagingComponent from './OptionDialog/pagingComponent';
import { useShops } from '../../../hooks/useShops';

const PAGE_SIZE = 50;

const MENU_FILTER_KEYS = ['noRole', 'isDisplay', 'none'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: '250px',
      width: '200px',
      overflow: 'auto',
    },
  }),
);

const Menus: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { selectedShop, selectedCompany } = useShops();
  const [selectedMenu, setSelectedMenu] = useState(null as Menu | null);
  const [pageIndex, setPageIndex] = useState(1);
  const [menus, setMenus] = useState([] as Menu[]);
  const [paginatedMenus, setPaginatedMenus] = useState([] as Menu[]);
  const [categories, setCategories] = useState([] as Category[]);
  const [menuTypes, setMenuTypes] = useState([] as MenuType[]);
  const [roles, setRoles] = useState([] as Role[]);
  // edit flags
  const [editNameIndex, setEditNameIndex] = useState(null as null | number);
  const [editNameEnIndex, setEditNameEnIndex] = useState(null as null | number);
  const [editReceiptDisplayNameIndex, setEditReceiptDisplayNameIndex] = useState(
    null as null | number,
  );
  const [editShopSideNameIndex, setEditShopSideNameIndex] = useState(null as null | number);
  const [editEdiPriceIndex, setEditPriceIndex] = useState(null as null | number);
  const [editPriorityIndex, setEditPriorityIndex] = useState(null as null | number);
  const [editReceiptPriorityIndex, setEditReceiptPriorityIndex] = useState(null as null | number);
  const [editImageUrlIndex, setEditImageUrlIndex] = useState(null as null | number);
  const [editDescriptionIndex, setEditDescriptionIndex] = useState(null as null | number);
  const [editDescriptionEnIndex, setEditDescriptionEnIndex] = useState(null as null | number);
  const [editMaxNumIndex, setEditMaxNumIndex] = useState(null as null | number);
  const [editOrderMaxNumIndex, setEditOrderMaxNumIndex] = useState(null as null | number);
  const [editCurrentNumIndex, setEditCurrentNumIndex] = useState(null as null | number);
  const [editUnitMaxOrderNumForNumPeopleIndex, setEditUnitMaxOrderNumForNumPeopleIndex] = useState(
    null as null | number,
  );
  const [editUnitMaxOrderNumForTableUserIndex, setEditUnitMaxOrderNumForTableUserIndex] = useState(
    null as null | number,
  );
  const [editPosMenuIdIndex, setEditPosMenuIdIndex] = useState(null as null | number);
  // edit data
  const [editedName, setEditedName] = useState(null as null | string);
  const [editedNameEn, setEditedNameEn] = useState(null as null | string);
  const [editedReceiptDisplayName, setEditedReceiptDisplayName] = useState(null as null | string);
  const [editedShopSideName, setEditedShopSideName] = useState(null as null | string);
  const [editedPrice, setEditedPrice] = useState(null as null | number);
  const [editedPriority, setEditedPriority] = useState(null as null | number);
  const [editedReceiptPriority, setEditedReceiptPriority] = useState(null as null | number);
  const [editedImageUrl, setEditedImageUrl] = useState(null as null | string);
  const [editedDescription, setEditedDescription] = useState(null as null | string);
  const [editedDescriptionEn, setEditedDescriptionEn] = useState(null as null | string);
  const [editedMaxNum, setEditedMaxNum] = useState(null as null | number);
  const [editedOrderMaxNum, setEditedOrderMaxNum] = useState(null as null | number);
  const [editedCurrentNum, setEditedCurrentNum] = useState(null as null | number);
  const [editedUnitMaxOrderNumForNumPeople, setEditedUnitMaxOrderNumForNumPeople] = useState(
    null as null | number,
  );
  const [editedUnitMaxOrderNumForTableUser, setEditedUnitMaxOrderNumForTableUser] = useState(
    null as null | number,
  );
  const [editedPosMenuId, setEditedPosMenuId] = useState(null as null | number);

  // image url response dialog
  const [isOpenImageUrlEditor, setIsOpenImageUrlEditor] = useState(false);

  // menu filter
  const [menuFilterKey, setMenuFilterKey] = useState(null as null | string);
  const [categoryFilterId, setCategoryFilterId] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createMenuModalOpen, setCreateMenuModalOpen] = useState(false);
  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [editMenuTypeMenuId, setEditMenuTypeMenuId] = useState(null as null | number);
  const [editCategoryMenuId, setEditCategoryMenuId] = useState(null as null | number);

  const cancelEdit = () => {
    setEditNameIndex(null);
    setEditNameEnIndex(null);
    setEditShopSideNameIndex(null);
    setEditPriceIndex(null);
    setEditPriorityIndex(null);
    setEditReceiptPriorityIndex(null);
    setEditImageUrlIndex(null);
    setEditDescriptionIndex(null);
    setEditDescriptionEnIndex(null);
    setEditMaxNumIndex(null);
    setEditCurrentNumIndex(null);
    setEditOrderMaxNumIndex(null);
    setEditReceiptDisplayNameIndex(null);
    setEditUnitMaxOrderNumForNumPeopleIndex(null);
    setEditUnitMaxOrderNumForTableUserIndex(null);
    setEditPosMenuIdIndex(null);
    setEditedPrice(null);
    setEditedName(null);
    setEditedNameEn(null);
    setEditedShopSideName(null);
    setEditedPriority(null);
    setEditedReceiptPriority(null);
    setEditedImageUrl(null);
    setEditedDescription(null);
    setEditedDescriptionEn(null);
    setEditedMaxNum(null);
    setEditedCurrentNum(null);
    setEditedOrderMaxNum(null);
    setEditedReceiptDisplayName(null);
    setEditedUnitMaxOrderNumForNumPeople(null);
    setEditedUnitMaxOrderNumForTableUser(null);
    setEditedPosMenuId(null);
  };

  const sortedMenus = useCallback(() => {
    if (menuFilterKey || categoryFilterId) {
      return menus
        .filter(menu => {
          if (menuFilterKey === 'noRole') {
            return menu.roles?.length === 0;
          } else if (menuFilterKey === 'isDisplay') {
            return menu.isDisplay;
          } else {
            return true;
          }
        })
        .filter(menu => {
          if (categoryFilterId !== null) {
            return menu.categoryId === categoryFilterId;
          } else {
            return true;
          }
        })
        .sort((menu1, menu2) => menu1.menuId - menu2.menuId);
    } else {
      return menus.sort((menu1, menu2) => menu1.menuId - menu2.menuId);
    }
  }, [categoryFilterId, menuFilterKey, menus]);

  const updateMenuRequest = useCallback(
    (menuId: number, updateValue: Partial<Menu>) =>
      request
        .post(`shops/${selectedShop!.shopId}/menus/${menuId}`, updateValue, {
          headers: { 'x-company-id': selectedCompany?.companyId },
        })
        .then(response => {
          setMenus(
            menus.map(existingMenu =>
              existingMenu.menuId === response.data.menuId ? response.data : existingMenu,
            ),
          );
          setDialogMessage('update success');
        }),
    [menus, selectedShop, selectedCompany],
  );

  const updateRolesRequest = useCallback(
    (menuId: number, roleIds: number[]) =>
      request
        .post(
          `shops/${selectedShop!.shopId}/menus/${menuId}/roles`,
          { roleIds },
          {
            headers: { 'x-company-id': selectedCompany?.companyId },
          },
        )
        .then(response => {
          setMenus(
            menus.map(existingMenu =>
              existingMenu.menuId === response.data.menuId ? response.data : existingMenu,
            ),
          );
        }),
    [menus, selectedCompany, selectedShop],
  );

  const reloadMenus = () => {
    if (!selectedShop) return;
    (async () => {
      const menusRes = await request.get(`shops/${selectedShop.shopId}/menus`, {
        headers: { 'x-company-id': selectedShop.companyId },
      });
      setMenus(menusRes.data as Menu[]);
    })();
  };

  const reloadMenu = (menuId: number) => {
    request
      .get(`shops/${selectedShop!.shopId}/menus/${menuId}`, {
        headers: { 'x-company-id': selectedShop!.companyId },
      })
      .then(response => {
        if (selectedMenu?.menuId === menuId) {
          setSelectedMenu(response.data);
        }
        setMenus(prev =>
          prev.map(existingMenu =>
            existingMenu.menuId === response.data.menuId ? response.data : existingMenu,
          ),
        );
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
  };

  const applyChange = (menuId: number) => {
    const targetMenu = menus.find(menu => menu.menuId === menuId);
    if (!targetMenu) return;
    const updateValueBody = {
      name: editedName || undefined,
      nameEn: editedNameEn || undefined,
      shopSideName: editedShopSideName || undefined,
      receiptDisplayName: editedReceiptDisplayName || undefined,
      price: typeof editedPrice === 'number' ? editedPrice : undefined,
      priority: typeof editedPriority === 'number' ? editedPriority : undefined,
      receiptPriority:
        typeof editedReceiptPriority === 'number' ? editedReceiptPriority : undefined,
      imageUrl: editedImageUrl || undefined,
      description: editedDescription || undefined,
      descriptionEn: editedDescriptionEn || undefined,
      maxNum: editedMaxNum || undefined,
      orderMaxNum: editedOrderMaxNum || undefined,
      currentNum: editedCurrentNum === null ? undefined : editedCurrentNum!,
      unitMaxOrderNumForNumPeople:
        typeof editedUnitMaxOrderNumForNumPeople === 'number'
          ? editedUnitMaxOrderNumForNumPeople
          : undefined,
      unitMaxOrderNumForTableUser:
        editedUnitMaxOrderNumForTableUser === null ? undefined : editedUnitMaxOrderNumForTableUser,
      posMenuId: editedPosMenuId ?? undefined,
    };
    updateMenuRequest(targetMenu.menuId, updateValueBody);
    cancelEdit();
  };

  const deleteProperty = (menuId: number, key: keyof Menu) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/menus/${menuId}`,
        { [key]: null },
        { headers: { 'x-company-id': selectedCompany?.companyId } },
      )
      .then(response => {
        setMenus(
          menus.map(existingMenu =>
            existingMenu.menuId === response.data.menuId ? response.data : existingMenu,
          ),
        );
        cancelEdit();
      });
  };
  const deleteImage = (menuId: number) => {
    deleteProperty(menuId, 'imageUrl');
  };
  const deleteUnitMaxOrderNumForNumPeople = (menuId: number) => {
    deleteProperty(menuId, 'unitMaxOrderNumForNumPeople');
  };
  const deleteUnitMaxOrderNumForTableUser = (menuId: number) => {
    deleteProperty(menuId, 'unitMaxOrderNumForTableUser');
  };
  const deleteDescription = (menuId: number) => {
    deleteProperty(menuId, 'description');
  };
  const deleteDescriptionEn = (menuId: number) => {
    deleteProperty(menuId, 'descriptionEn');
  };

  // fetch menu
  useEffect(() => {
    if (!selectedShop) return;
    const f = async () => {
      const menusRes = await request.get(`shops/${selectedShop.shopId}/menus`, {
        headers: { 'x-company-id': selectedShop.companyId },
      });
      setMenus(menusRes.data as Menu[]);
    };
    f();
  }, [selectedShop, pageIndex]);

  // fetch side data
  useEffect(() => {
    if (!selectedShop) return;
    const f = async () => {
      const [categoriesRes, menuTypesRes, rolesRes] = await Promise.all([
        request.get(`shops/${selectedShop.shopId}/categories`, {
          headers: { 'x-company-id': selectedShop!.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/menu_types`, {
          headers: { 'x-company-id': selectedShop!.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/roles`, {
          headers: { 'x-company-id': selectedShop!.companyId },
        }),
      ]);
      setCategories(categoriesRes.data as Category[]);
      setMenuTypes(menuTypesRes.data as MenuType[]);
      setRoles(rolesRes.data as Role[]);
    };
    f();
  }, [selectedShop]);

  useEffect(() => {
    setPaginatedMenus(sortedMenus().slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE));
  }, [menus, pageIndex, sortedMenus]);

  const openOptionDialog = (menu: Menu) => {
    setSelectedMenu(menu);
    setOptionModalOpen(true);
  };
  const updateRoleId = (menu: Menu, role: Role) => {
    if (menu.roles?.map(role => role.roleId)?.includes(role.roleId)) {
      updateRolesRequest(
        menu.menuId,
        menu.roles?.filter(menuRole => role.roleId !== menuRole.roleId)?.map(role => role.roleId),
      );
    } else {
      updateRolesRequest(
        menu.menuId,
        [...(menu.roles ? menu.roles : []), role].map(role => role.roleId),
      );
    }
  };
  const toggleSoldout = (menu: Menu) =>
    updateMenuRequest(menu.menuId, { isSoldout: !menu.isSoldout });
  const toggleDisplay = (menu: Menu) =>
    updateMenuRequest(menu.menuId, { isDisplay: !menu.isDisplay });
  const updateCategory = (menu: Menu, category: Category) =>
    updateMenuRequest(menu.menuId, { categoryId: category.categoryId });
  const updateMenuType = (menu: Menu, menuType: MenuType) =>
    updateMenuRequest(menu.menuId, { menuTypeId: menuType.menuTypeId });

  const findNameById = (id: number, source: any[], key: string) => {
    const ret = source.find(v => v[key] === id);
    return ret ? ret.name : '';
  };
  return (
    <>
      <Button
        color="primary"
        disabled={menus.length === 0}
        onClick={() => setIsOpenImageUrlEditor(true)}
      >
        一括設定
      </Button>

      <Button
        disabled={selectedShop === null}
        variant="outlined"
        color="primary"
        style={{ width: '30vw', marginBottom: '30px' }}
        onClick={() => setCreateMenuModalOpen(true)}
      >
        Create menu
      </Button>

      <div style={{ display: 'flex' }}>
        <div>
          <p>フィルター設定{categoryFilterId}</p>
          <List
            dense
            style={{
              width: '300px',
              overflow: 'auto',
            }}
          >
            {MENU_FILTER_KEYS.map((filterKey, index) => (
              <ListItem key={index} button>
                <ListItemText id={`${filterKey}`} primary={filterKey} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => setMenuFilterKey(filterKey)}
                    checked={menuFilterKey === filterKey}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
        <div>
          <p>カテゴリフィルター設定</p>
          <List
            dense
            style={{
              width: '300px',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            {categories.map((category, index) => (
              <ListItem key={index} button>
                <ListItemText id={`${category.categoryId}`} primary={category.name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => {
                      console.log('on change:', category);
                      setCategoryFilterId(category.categoryId);
                    }}
                    checked={categoryFilterId === category.categoryId}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button onClick={() => setCategoryFilterId(null)}>カテゴリフィルタのリセット</Button>
        </div>
      </div>

      <Paper>
        {pagingComponent({
          disabled: menus.length === 0,
          currentPageIndex: pageIndex,
          updatePageIndex: newPageIndex => setPageIndex(newPageIndex),
          dataSize: menus.length,
        })}
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Shop ID</TableCell>
              <TableCell align="left">Shop Name</TableCell>
              <TableCell align="left">Menu ID</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">NameEn</TableCell>
              <TableCell align="left">ReceiptDisplayName</TableCell>
              <TableCell align="left">ShopSideName(店舗アプリ表示名)</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">TaxMethod</TableCell>
              <TableCell align="left">TaxRate</TableCell>
              <TableCell align="left">IsSoldout</TableCell>
              <TableCell align="left">IsDisplay</TableCell>
              <TableCell align="left">CategoryId</TableCell>
              <TableCell align="left">MenuTypeId</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">DescriptionEn</TableCell>
              <TableCell align="left">ImageURL</TableCell>
              <TableCell align="left">MaxNum</TableCell>
              <TableCell align="left">OrderMaxNum</TableCell>
              <TableCell align="left">人数あたりの1オーダーの上限注文数</TableCell>
              <TableCell align="left">1来店あたり・1人あたりの上限注文数</TableCell>
              <TableCell align="left">CurrentNum</TableCell>
              <TableCell align="left">POS MenuId</TableCell>
              <TableCell align="left">Priority</TableCell>
              <TableCell align="left">ReceiptPriority</TableCell>
              <TableCell align="left">RoleIds</TableCell>
              <TableCell align="left">Options</TableCell>
              <TableCell align="left">
                OpenPrice? (ハンディから注文時に価格を設定するモード)
              </TableCell>
              <TableCell align="left">Featured?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedMenus.map((menu, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {menu.companyId}
                </TableCell>
                <TableCell align="right">{menu.shopId}</TableCell>
                <TableCell align="right">{selectedShop!.name}</TableCell>
                <TableCell align="right">{menu.menuId}</TableCell>
                <TableCell align="right" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  <div style={{ width: '300px' }}>
                    {dataTableRowComponent({
                      defaultValue: menu.name,
                      startEdit: () => setEditNameIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(menu.menuId),
                      formClassName: classes.form,
                      setValue: val => setEditedName(val),
                      isShowEditForm: editNameIndex === index,
                    })}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.nameEn,
                    startEdit: () => setEditNameEnIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedNameEn(val),
                    isShowEditForm: editNameEnIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.receiptDisplayName,
                    startEdit: () => setEditReceiptDisplayNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedReceiptDisplayName(val),
                    isShowEditForm: editReceiptDisplayNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.shopSideName,
                    startEdit: () => setEditShopSideNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedShopSideName(val),
                    isShowEditForm: editShopSideNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.price,
                    startEdit: () => setEditPriceIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedPrice(Number(val)),
                    isShowEditForm: editEdiPriceIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  <List dense className={classes.list}>
                    {Object.values(TaxMethod).map((taxMethodValue, taxMethodIndex) => (
                      <ListItem key={taxMethodIndex} button>
                        <ListItemText id={`${taxMethodIndex}`} primary={taxMethodValue} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() =>
                              updateMenuRequest(menu.menuId, { taxMethod: taxMethodValue })
                            }
                            checked={menu.taxMethod === taxMethodValue}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell align="right">
                  <List dense className={classes.list}>
                    {[0, 0.08, 0.1].map((taxRate, taxRateIndex) => (
                      <ListItem key={taxRateIndex} button>
                        <ListItemText id={`${taxRateIndex}`} primary={taxRate} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => updateMenuRequest(menu.menuId, { taxRate })}
                            checked={menu.taxRate === taxRate}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={menu.isSoldout}
                    onChange={() => toggleSoldout(menu)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={menu.isDisplay}
                    onChange={() => toggleDisplay(menu)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  name:
                  {findNameById(menu.categoryId, categories, 'categoryId')}
                  <Button onClick={() => setEditCategoryMenuId(menu.menuId)}>open editor</Button>
                  <Dialog
                    open={editCategoryMenuId === menu.menuId}
                    onClose={() => setEditCategoryMenuId(null)}
                  >
                    <DialogContent>
                      <List dense className={classes.list}>
                        {categories.map((category, categoryIndex) => (
                          <ListItem key={categoryIndex} button>
                            <ListItemText id={`${category.categoryId}`} primary={category.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => updateCategory(menu, category)}
                                checked={menu.categoryId === category.categoryId}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell align="right">
                  name:
                  {findNameById(menu.menuTypeId, menuTypes, 'menuTypeId')}
                  <Button onClick={() => setEditMenuTypeMenuId(menu.menuId)}>open editor</Button>
                  <Dialog
                    open={editMenuTypeMenuId === menu.menuId}
                    onClose={() => setEditMenuTypeMenuId(null)}
                  >
                    <DialogContent>
                      <List dense className={classes.list}>
                        {menuTypes.map((menuType, menuTypeIndex) => (
                          <ListItem key={menuTypeIndex} button>
                            <ListItemText id={`${menuType.menuTypeId}`} primary={menuType.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => updateMenuType(menu, menuType)}
                                checked={menu.menuTypeId === menuType.menuTypeId}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                      <Button onClick={() => setEditMenuTypeMenuId(-2)}>close</Button>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.description,
                    startEdit: () => setEditDescriptionIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedDescription(val),
                    isShowEditForm: editDescriptionIndex === index,
                    dataType: 'string',
                  })}
                  <Button onClick={() => deleteDescription(menu.menuId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.descriptionEn,
                    startEdit: () => setEditDescriptionEnIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedDescriptionEn(val),
                    isShowEditForm: editDescriptionEnIndex === index,
                    dataType: 'string',
                  })}
                  <Button onClick={() => deleteDescriptionEn(menu.menuId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.imageUrl,
                    startEdit: () => setEditImageUrlIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedImageUrl(val),
                    isShowEditForm: editImageUrlIndex === index,
                    dataType: 'string',
                  })}
                  <div>
                    {menu.imageUrl && (
                      <img src={menu.imageUrl} alt="menuImage" width="100px" height="100px" />
                    )}
                  </div>
                  <Button onClick={() => deleteImage(menu.menuId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.maxNum,
                    startEdit: () => setEditMaxNumIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedMaxNum(Number(val)),
                    isShowEditForm: editMaxNumIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.orderMaxNum,
                    startEdit: () => setEditOrderMaxNumIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedOrderMaxNum(Number(val)),
                    isShowEditForm: editOrderMaxNumIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.unitMaxOrderNumForNumPeople,
                    startEdit: () => setEditUnitMaxOrderNumForNumPeopleIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedUnitMaxOrderNumForNumPeople(Number(val)),
                    isShowEditForm: editUnitMaxOrderNumForNumPeopleIndex === index,
                    dataType: 'number',
                  })}
                  <Button onClick={() => deleteUnitMaxOrderNumForNumPeople(menu.menuId)}>
                    Delete setting
                  </Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.unitMaxOrderNumForTableUser,
                    startEdit: () => setEditUnitMaxOrderNumForTableUserIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedUnitMaxOrderNumForTableUser(Number(val)),
                    isShowEditForm: editUnitMaxOrderNumForTableUserIndex === index,
                    dataType: 'number',
                  })}
                  <Button onClick={() => deleteUnitMaxOrderNumForTableUser(menu.menuId)}>
                    Delete setting
                  </Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.currentNum,
                    startEdit: () => setEditCurrentNumIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedCurrentNum(Number(val)),
                    isShowEditForm: editCurrentNumIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.posMenuId,
                    startEdit: () => setEditPosMenuIdIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedPosMenuId(Number(val)),
                    isShowEditForm: editPosMenuIdIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.priority,
                    startEdit: () => setEditPriorityIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedPriority(Number(val)),
                    isShowEditForm: editPriorityIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: menu.receiptPriority,
                    startEdit: () => setEditReceiptPriorityIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(menu.menuId),
                    formClassName: classes.form,
                    setValue: val => setEditedReceiptPriority(Number(val)),
                    isShowEditForm: editReceiptPriorityIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {menu.roles?.length === 0 && (
                    <div
                      style={{
                        background: 'red',
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      印刷設定がありません
                    </div>
                  )}
                  <List dense className={classes.list}>
                    {roles.map((role, roleIndex) => (
                      <ListItem key={roleIndex} button>
                        <ListItemText id={`${role.roleId}`} primary={role.name} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => updateRoleId(menu, role)}
                            checked={menu.roles?.map(role => role.roleId)?.includes(role.roleId)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    menu.options && menu.options.length > 0 ? classes.okCell : classes.ngCell
                  }
                >
                  {menu.options && menu.options.length > 0 ? 'オプションあり' : 'オプションなし'}
                  <Button onClick={() => openOptionDialog(menu)}>option</Button>
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    edge="end"
                    onChange={event =>
                      updateMenuRequest(menu.menuId, { openPrice: event.target.checked })
                    }
                    checked={menu.openPrice}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    edge="end"
                    onChange={event =>
                      updateMenuRequest(menu.menuId, { featured: event.target.checked })
                    }
                    checked={menu.featured}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {pagingComponent({
          disabled: menus.length === 0,
          currentPageIndex: pageIndex,
          updatePageIndex: newPageIndex => setPageIndex(newPageIndex),
          dataSize: menus.length,
        })}
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog
        fullScreen
        open={isOpenImageUrlEditor}
        onClose={() => setIsOpenImageUrlEditor(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MenuImageUrlEditor
          menus={menus}
          onClose={() => setIsOpenImageUrlEditor(false)}
          reloadMenus={() => reloadMenus()}
        />
      </Dialog>

      {selectedShop && menuTypes && categories && roles && (
        <CreateMenuDialog
          roles={roles}
          categories={categories}
          menuTypes={menuTypes}
          shop={selectedShop}
          isOpen={createMenuModalOpen}
          onClose={() => setCreateMenuModalOpen(false)}
        />
      )}

      {selectedShop && selectedMenu && (
        <OptionDialog
          reloadMenu={reloadMenu}
          menu={selectedMenu}
          shop={selectedShop}
          isOpen={optionModalOpen}
          onClose={() => {
            if (selectedMenu) {
              reloadMenu(selectedMenu.menuId);
            }
            setOptionModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Menus;
