import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import firebase from '../../../plugins/firebase';
import ConnectionLogsDialog from './ConnectionLogsDialog/ConnectionLogsDialog';
import moment from 'moment-timezone';
import { useShops } from '../../../hooks/useShops';

const Moment = require('moment');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface ConnectionStatus {
  lastUpdatedAt: any;
  lastUpdatedType: string;
  companyId: string;
  shopId: string;
  uid: string;
}

const formatDate = (date: Date) => Moment(date).format('YYYY-MM-DD HH:mm:ss');

const ConnectionStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as ConnectionStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as ConnectionStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);

  const { shops } = useShops();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('connectionStatus')
      .orderBy('lastUpdatedAt', 'desc')
      .endAt(
        moment()
          .subtract(5, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .toDate(),
      )
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logs = snapshot.docs
            .filter(d => d.id.includes('DiniiDoc'))
            .map(value => value.data() as ConnectionStatus)
            .filter(d => d.uid !== undefined);
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  const getShopNameById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.name;
    } else {
      return '';
    }
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>Note: 5日前までのログをリアルタイム表示</p>
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">UID</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  setSelectedStatus(status);
                  setLogModalOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">{getShopNameById(status.shopId)}</TableCell>
                <TableCell align="right">{status.uid}</TableCell>
                <TableCell align="right">{formatDate(status.lastUpdatedAt.toDate())}</TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConnectionLogsDialog
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        uid={selectedStatus.uid}
        isOpen={logModalOpen}
        onClose={() => setLogModalOpen(false)}
      />
    </>
  );
};

export default ConnectionStatus;
