import { Role } from './role.entity';

export enum TaxMethod {
  INCLUDED = 'included',
  EXCLUDED = 'excluded',
  NON_TAXABLE = 'nonTaxable',
}

export interface Menu {
  companyId: number;
  shopId: string;
  menuId: number;
  posMenuId?: number;
  categoryId: number;
  menuTypeId: number;
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  shopSideName?: string;
  options?: Option[];
  price: number;
  priority: number;
  receiptPriority: number;
  description: string;
  descriptionEn: string;
  maxNum: number;
  orderMaxNum: number;
  currentNum: number;
  imageUrl?: string;
  isSoldout: boolean;
  isDisplay: boolean;
  unitMaxOrderNumForNumPeople?: number | null;
  unitMaxOrderNumForTableUser?: number | null;
  featured?: boolean;
  openPrice?: boolean;
  roles?: Role[];
  taxMethod?: TaxMethod;
  taxRate?: number;
}

export interface Option {
  companyId: number;
  shopId: string;
  optionId: number;
  posOptionId?: number;
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  priority: number;
  isSoldout: boolean;
  isDisplay: boolean;
  description?: string;
  descriptionEn?: string;
  menuId: number;
  choices?: Choice[];
  meta?: object | null;
  isRequired: boolean;
  isMultiple: boolean;
  maxChoiceNum: number;
  minChoiceNum: number;
}

export interface Choice {
  companyId: number;
  shopId: string;
  choiceId: number;
  posChoiceId?: number;
  optionId: number;
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  price: number;
  maxOrderableNum: number;
  priority: number;
  isSoldout: boolean;
  isDisplay: boolean;
  meta?: {} | null;
  imageUrl?: string | null;
  discount: number;
  currentNum: number;
  maxNum: number;
  description?: string | null;
  descriptionEn?: string | null;
  isDefaultSelection: boolean;
}
