import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import CreatePlanDialog from './CreatePlanDialog/CreatePlanDialog';
import CreatePlanGroupDialog from './CreatePlanGroupDialog/CreatePlanGroupDialog';
import PlanOptionDialog from './OptionDialog/PlanOptionDialog';
import { MenuType } from '../../../entity/menuType.entity';
import { Plan } from '../../../entity/plan.entity';
import { Menu, TaxMethod } from '../../../entity/menu.entity';
import { PlanGroup } from '../../../entity/planGroup.entity';
import { Category } from '../../../entity/category.entity';
import { Role } from '../../../entity/role.entity';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: '250px',
      width: '200px',
      overflow: 'auto',
    },
  }),
);

const Plans: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { selectedShop, selectedCompany } = useShops();
  const [categories, setCategories] = useState([] as Category[]);
  const [menuTypes, setMenuTypes] = useState([] as MenuType[]);
  const [planGroups, setPlanGroups] = useState([] as PlanGroup[]);
  const [plans, setPlans] = useState([] as Plan[]);
  const [roles, setRoles] = useState([] as Role[]);
  const [menus, setMenus] = useState([] as Menu[]);
  const [selectedPlan, setSelectedPlan] = useState(null as Plan | null);
  // edit flags
  const [editNameIndex, setEditNameIndex] = useState(null as null | number);
  const [editReceiptDisplayNameIndex, setEditReceiptDisplayNameIndex] = useState(
    null as null | number,
  );
  const [editPlanTimeIndex, setEditPlanTimeIndex] = useState(null as null | number);
  const [editStartIndex, setEditStartIndex] = useState(null as null | number);
  const [editEndIndex, setEditEndIndex] = useState(null as null | number);
  const [editPriorityIndex, setEditPriorityIndex] = useState(null as null | number);
  const [editNameEnIndex, setEditNameEnIndex] = useState(null as null | number);
  const [editShopSideNameIndex, setEditShopSideNameIndex] = useState(null as null | number);
  const [editImageUrlIndex, setEditImageUrlIndex] = useState(null as null | number);
  const [editDescriptionIndex, setEditDescriptionIndex] = useState(null as null | number);
  const [editDescriptionEnIndex, setEditDescriptionEnIndex] = useState(null as null | number);
  const [editMaxMenuKindNumIndex, setEditMaxMenuKindNumIndex] = useState(null as null | number);
  const [editDescriptionLabelTextIndex, setEditDescriptionLabelTextIndex] = useState<null | number>(
    null,
  );
  const [editPosMenuIdIndex, setEditPosMenuIdIndex] = useState(null as null | number);
  // edit data
  const [editedName, setEditedName] = useState(null as null | string);
  const [editedNameEn, setEditedNameEn] = useState(null as null | string);
  const [editedReceiptDisplayName, setEditedReceiptDisplayName] = useState(null as null | string);
  const [editedShopSideName, setEditedShopSideName] = useState(null as null | string);
  const [editedPlanTime, setEditedPlanTime] = useState(null as null | number);
  const [editedStart, setEditedStart] = useState(null as null | string);
  const [editedEnd, setEditedEnd] = useState(null as null | string);
  const [editedPriority, setEditedPriority] = useState(null as null | number);
  const [editedImageUrl, setEditedImageUrl] = useState(null as null | string);
  const [editedDescription, setEditedDescription] = useState(null as null | string);
  const [editedDescriptionEn, setEditedDescriptionEn] = useState(null as null | string);
  const [editedMaxMenuKindNum, setEditedMaxMenuKindNum] = useState(null as null | number);
  const [editedDescriptionLabelText, setEditedDescriptionLabelText] = useState<null | string>(null);
  const [editedPosMenuId, setEditedPosMenuId] = useState<number | null>(null);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createPlanModalOpen, setCreatePlanModalOpen] = useState(false);
  const [createPlanGroupModalOpen, setCreatePlanGroupModalOpen] = useState(false);
  const [optionModalOpen, setOptionModalOpen] = useState(false);

  // dialog flags
  const [editPlanGroupPlanId, setEditPlanGroupPlanId] = useState<number | null>(null);
  const [editCategoryIdPlanId, setEditCategoryIdPlanId] = useState<number | null>(null);
  const [editMenuCategoryIdsPlanId, setEditMenuCategoryIdsPlanId] = useState<number | null>(null);
  const [editFirstOrderMenuIdsPlanId, setEditFirstOrderMenuIdsPlanId] = useState<number | null>(
    null,
  );
  const [editRoleIdsPlanId, setEditRoleIdsPlanId] = useState<number | null>(null);

  // filter key
  const [planNameFilter, setPlanNameFilter] = useState<string | null>(null);

  const cancelEdit = () => {
    setEditNameIndex(null);
    setEditedName(null);
    setEditedNameEn(null);
    setEditReceiptDisplayNameIndex(null);
    setEditedReceiptDisplayName(null);
    setEditStartIndex(null);
    setEditImageUrlIndex(null);
    setEditDescriptionIndex(null);
    setEditDescriptionEnIndex(null);
    setEditMaxMenuKindNumIndex(null);
    setEditDescriptionLabelTextIndex(null);
    setEditShopSideNameIndex(null);
    setEditedStart(null);
    setEditEndIndex(null);
    setEditedEnd(null);
    setEditPriorityIndex(null);
    setEditedPriority(null);
    setEditedImageUrl(null);
    setEditedDescription(null);
    setEditedDescriptionEn(null);
    setEditedMaxMenuKindNum(null);
    setEditedDescriptionLabelText(null);
    setEditedShopSideName(null);
  };

  const reloadPlan = (planId: number) => {
    request
      .get(`shops/${selectedShop!.shopId}/plans/${planId}`, {
        headers: { 'x-company-id': selectedShop!.companyId },
      })
      .then(response => {
        if (selectedPlan?.planId === planId) {
          setSelectedPlan(response.data);
        }
        setPlans(prev =>
          prev.map(existingPlan =>
            existingPlan.planId === response.data.planId ? response.data : existingPlan,
          ),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
  };

  const openOptionDialog = (plan: Plan) => {
    setSelectedPlan(plan);
    setOptionModalOpen(true);
  };

  const applyChange = (updateValue: any, updateKey: string, plan: Plan) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/plans/${plan.planId}`,
        { [updateKey]: updateValue },
        { headers: { 'x-company-id': selectedShop.companyId } },
      )
      .then(res => {
        setPlans(prev => prev.map(p => (p.planId === plan.planId ? res.data : p)));
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
    cancelEdit();
  };

  useEffect(() => {
    if (!selectedShop) return;
    const f = async () => {
      const [categoriesRes, menuTypesRes, rolesRes, planGroupsRes, menusRes] = await Promise.all([
        request.get(`shops/${selectedShop.shopId}/categories`, {
          headers: { 'x-company-id': selectedShop.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/menu_types`, {
          headers: { 'x-company-id': selectedShop.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/roles`, {
          headers: { 'x-company-id': selectedShop.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/planGroups`, {
          headers: { 'x-company-id': selectedShop.companyId },
        }),
        request.get(`shops/${selectedShop.shopId}/menus`, {
          headers: { 'x-company-id': selectedShop.companyId },
        }),
      ]);
      setCategories(categoriesRes.data);
      setMenuTypes(menuTypesRes.data);
      setRoles(rolesRes.data);
      setMenus(menusRes.data);
      setPlanGroups(planGroupsRes.data);
      const plansRes = await request.get(`shops/${selectedShop.shopId}/plans`, {
        headers: { 'x-company-id': selectedShop.companyId },
      });
      setPlans(plansRes.data);
    };
    f();
  }, [selectedShop]);

  const replaceNewPlan = (newPlan: Plan) => {
    setPlans(prev =>
      prev.map(p => {
        if (p.planId === newPlan.planId) {
          return newPlan;
        } else {
          return p;
        }
      }),
    );
  };

  // const sortedPlans = useCallback(
  //   () =>
  //     plans
  //       .filter(plan => {
  //         if (planNameFilter) {
  //           return plan.planName.indexOf(planNameFilter) !== -1;
  //         } else {
  //           return true;
  //         }
  //       })
  //       .sort((a, b) => a.priority - b.priority),
  //   [plans, planNameFilter],
  // );

  const handlePlanCategoryIdSetting = (plan: Plan, category: Category) => {
    request
      .post(
        `shops/${plan.shopId}/plans/${plan.planId}`,
        { planCategoryId: category.categoryId },
        { headers: { 'x-company-id': plan.companyId } },
      )
      .then(response => replaceNewPlan(response.data));
  };

  const handleCategoryIdsSetting = (plan: Plan, category: Category) => {
    const currentCategoryIds = plan.menuCategories.map(c => c.categoryId);
    let updatedCategoryIds = [];
    if (currentCategoryIds.includes(category.categoryId)) {
      updatedCategoryIds = currentCategoryIds.filter(c => c !== category.categoryId);
    } else {
      updatedCategoryIds = [...currentCategoryIds, category.categoryId];
    }
    request
      .post(
        `shops/${plan.shopId}/plans/${plan.planId}/menu_categories`,
        { categoryIds: updatedCategoryIds },
        { headers: { 'x-company-id': plan.companyId } },
      )
      .then(response => replaceNewPlan(response.data));
  };

  const handleFirstOrderMenuIdsSetting = (plan: Plan, menu: Menu) => {
    const currentFirstOrderMenuIds = plan.firstOrderMenus.map(m => m.menuId);
    let updatedFirstOrderMenuIds = [];
    if (currentFirstOrderMenuIds.includes(menu.menuId)) {
      updatedFirstOrderMenuIds = currentFirstOrderMenuIds.filter(m => m !== menu.menuId);
    } else {
      updatedFirstOrderMenuIds = [...currentFirstOrderMenuIds, menu.menuId];
    }
    request
      .post(
        `shops/${plan.shopId}/plans/${plan.planId}/first_order_menus`,
        { menuIds: updatedFirstOrderMenuIds },
        { headers: { 'x-company-id': plan.companyId } },
      )
      .then(response => replaceNewPlan(response.data));
  };

  const handlePlanGroupIdSetting = (plan: Plan, planGroup: PlanGroup) => {
    request
      .post(
        `shops/${plan.shopId}/plans/${plan.planId}`,
        { planGroupId: planGroup.planGroupId },
        { headers: { 'x-company-id': plan.companyId } },
      )
      .then(response => {
        setPlans(prev =>
          prev.map(p => {
            if (p.planId === plan.planId) {
              return response.data;
            } else {
              return p;
            }
          }),
        );
      });
  };

  const handleRoleIdsSetting = (planIndex: number, role: Role) => {
    if (!selectedShop) return;
    const plan = plans[planIndex];
    let updatedRoleIds = plan.roles ? plan.roles.map(role => role.roleId) : [];
    if (updatedRoleIds.includes(role.roleId)) {
      updatedRoleIds = updatedRoleIds.filter(roleId => roleId !== role.roleId);
    } else {
      updatedRoleIds = [...updatedRoleIds, role.roleId];
    }
    request
      .post(
        `shops/${selectedShop.shopId}/plans/${plan.planId}/roles`,
        { roleIds: updatedRoleIds },
        { headers: { 'x-company-id': selectedCompany?.companyId } },
      )
      .then(response => {
        replaceNewPlan(response.data);
        setDialogMessage('update success');
      });
  };

  const updatePlan = (planId: number, updateValue: Partial<Plan>) =>
    request
      .post(`shops/${selectedShop!.shopId}/plans/${planId}`, updateValue, {
        headers: { 'x-company-id': selectedCompany?.companyId },
      })
      .then(response => {
        replaceNewPlan(response.data);
        setDialogMessage('update success');
      });

  const toggleSoldout = (plan: Plan) => updatePlan(plan.planId, { isSoldout: !plan.isSoldout });

  const toggleDisplay = (plan: Plan) => updatePlan(plan.planId, { isDisplay: !plan.isDisplay });

  const deleteProperty = (planId: number, key: keyof Plan) => {
    if (!selectedShop) return;
    request
      .post(
        `shops/${selectedShop.shopId}/plans/${planId}`,
        { [key]: null },
        { headers: { 'x-company-id': selectedCompany?.companyId } },
      )
      .then(response => {
        replaceNewPlan(response.data);
        cancelEdit();
      });
  };

  const deletePlanTime = (planId: number) => {
    deleteProperty(planId, 'planTime');
  };

  const deleteDescription = (planId: number) => {
    deleteProperty(planId, 'description');
  };

  const deleteDescriptionEn = (planId: number) => {
    deleteProperty(planId, 'descriptionEn');
  };

  const deleteMaxMenuKindNum = (planId: number) => {
    deleteProperty(planId, 'maxMenuKindNum');
  };
  const deletePlanGroupId = (planId: number) => {
    deleteProperty(planId, 'planGroupId');
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '20vw', marginBottom: '30px' }}
        onClick={() => setCreatePlanModalOpen(true)}
      >
        Create plan
      </Button>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '20vw', marginBottom: '30px' }}
        onClick={() => setCreatePlanGroupModalOpen(true)}
      >
        Create plan group
      </Button>
      <div>
        <TextField
          required
          id="name-input"
          label="名前でフィルタ"
          value={planNameFilter}
          variant="filled"
          type="string"
          onChange={ev => setPlanNameFilter(ev.target.value)}
        />
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Plan ID</TableCell>
              <TableCell align="right">Plan Name</TableCell>
              <TableCell align="right">Plan NameEn</TableCell>
              <TableCell align="right">Plan ReceiptDisplayName</TableCell>
              <TableCell align="right">Plan ShopSideName(店舗アプリ表示名)</TableCell>
              <TableCell align="right">Plan Description</TableCell>
              <TableCell align="right">Plan DescriptionEn</TableCell>
              <TableCell align="left">TaxMethod</TableCell>
              <TableCell align="left">TaxRate</TableCell>
              <TableCell align="right">Plan Time</TableCell>
              <TableCell align="right">Max Menu Kind Num(1注文あたりの最大種類数)</TableCell>
              <TableCell align="right">Image URL</TableCell>
              <TableCell align="right">isSolout</TableCell>
              <TableCell align="right">isDisplay</TableCell>
              <TableCell align="right">Plan StartTime</TableCell>
              <TableCell align="right">Plan EndTime</TableCell>
              <TableCell align="right">PosId</TableCell>
              <TableCell align="right">Plan Priority</TableCell>
              <TableCell align="right">
                Plan Group ID
                <p>プランのグループ分け設定</p>
              </TableCell>
              <TableCell align="right">
                Plan Category ID
                <p>プラン自体に振られるカテゴリー</p>
              </TableCell>
              <TableCell align="right">
                Plan menu categories
                <p>プランを適用した時にオーダー可能になるメニューのカテゴリー</p>
              </TableCell>
              <TableCell align="right">
                Plan first order menus
                <p>プランを適用した時に最初に強制オーダーされるメニュー</p>
              </TableCell>
              <TableCell align="right">
                Plan Roles
                <p>プランを適用した時の伝票印刷設定</p>
              </TableCell>
              <TableCell align="right">Plan Options</TableCell>
              <TableCell align="left">説明ラベルテキスト</TableCell>
              <TableCell align="left">Featured?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {plan.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {selectedCompany?.name}
                </TableCell>
                <TableCell align="right">{plan.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">{plan.planId}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.planName,
                    startEdit: () => setEditNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedName, 'planName', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedName(val),
                    isShowEditForm: editNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.planNameEn,
                    startEdit: () => setEditNameEnIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedNameEn, 'planNameEn', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedNameEn(val),
                    isShowEditForm: editNameEnIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.receiptDisplayName,
                    startEdit: () => setEditReceiptDisplayNameIndex(index),
                    cancelEdit,
                    applyChange: () =>
                      applyChange(editedReceiptDisplayName, 'receiptDisplayName', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedReceiptDisplayName(val),
                    isShowEditForm: editReceiptDisplayNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.shopSideName,
                    startEdit: () => setEditShopSideNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedShopSideName, 'shopSideName', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedShopSideName(val),
                    isShowEditForm: editShopSideNameIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  <div style={{ maxHeight: '100px', overflowY: 'scroll' }}>
                    {dataTableRowComponent({
                      defaultValue: plan.description,
                      startEdit: () => setEditDescriptionIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(editedDescription, 'description', plan),
                      formClassName: classes.form,
                      setValue: val => setEditedDescription(val),
                      isShowEditForm: editDescriptionIndex === index,
                    })}
                  </div>
                  <Button onClick={() => deleteDescription(plan.planId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  <div style={{ maxHeight: '100px', overflowY: 'scroll' }}>
                    {dataTableRowComponent({
                      defaultValue: plan.descriptionEn,
                      startEdit: () => setEditDescriptionEnIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(editedDescriptionEn, 'descriptionEn', plan),
                      formClassName: classes.form,
                      setValue: val => setEditedDescriptionEn(val),
                      isShowEditForm: editDescriptionEnIndex === index,
                    })}
                  </div>
                  <Button onClick={() => deleteDescriptionEn(plan.planId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  <List dense className={classes.list}>
                    {Object.values(TaxMethod).map((taxMethodValue, taxMethodIndex) => (
                      <ListItem key={taxMethodIndex} button>
                        <ListItemText id={`${taxMethodIndex}`} primary={taxMethodValue} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => updatePlan(plan.planId, { taxMethod: taxMethodValue })}
                            checked={plan.taxMethod === taxMethodValue}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell align="right">
                  <List dense className={classes.list}>
                    {[0, 0.08, 0.1].map((taxRate, taxRateIndex) => (
                      <ListItem key={taxRateIndex} button>
                        <ListItemText id={`${taxRateIndex}`} primary={taxRate} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => updatePlan(plan.planId, { taxRate })}
                            checked={plan.taxRate === taxRate}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.planTime,
                    startEdit: () => setEditPlanTimeIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedPlanTime, 'planTime', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedPlanTime(val),
                    isShowEditForm: editPlanTimeIndex === index,
                    dataType: 'number',
                  })}
                  <Button onClick={() => deletePlanTime(plan.planId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.maxMenuKindNum,
                    startEdit: () => setEditMaxMenuKindNumIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedMaxMenuKindNum, 'maxMenuKindNum', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedMaxMenuKindNum(val),
                    isShowEditForm: editMaxMenuKindNumIndex === index,
                    dataType: 'number',
                  })}
                  <Button onClick={() => deleteMaxMenuKindNum(plan.planId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.imageUrl,
                    startEdit: () => setEditImageUrlIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedImageUrl, 'imageUrl', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedImageUrl(val),
                    isShowEditForm: editImageUrlIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={plan.isSoldout}
                    onChange={() => toggleSoldout(plan)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={plan.isDisplay}
                    onChange={() => toggleDisplay(plan)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.start,
                    startEdit: () => setEditStartIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedStart, 'start', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedStart(val),
                    isShowEditForm: editStartIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.end,
                    startEdit: () => setEditEndIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedEnd, 'end', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedEnd(val),
                    isShowEditForm: editEndIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.posMenuId,
                    startEdit: () => setEditPosMenuIdIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedPosMenuId, 'posMenuId', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedPosMenuId(val),
                    isShowEditForm: editPosMenuIdIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: plan.priority,
                    startEdit: () => setEditPriorityIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedPriority, 'priority', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedPriority(Number(val)),
                    isShowEditForm: editPriorityIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEditPlanGroupPlanId(plan.planId)}>open editor</Button>
                  <Dialog
                    open={editPlanGroupPlanId === plan.planId}
                    onClose={() => setEditPlanGroupPlanId(null)}
                  >
                    <DialogContent>
                      <List
                        dense
                        style={{
                          maxHeight: '80vh',
                          width: '500px',
                          overflow: 'auto',
                        }}
                      >
                        {planGroups.map((planGroup, planGroupIndex) => (
                          <ListItem key={planGroupIndex} button>
                            <ListItemText
                              id={`${planGroup.planGroupId}`}
                              primary={planGroup.name}
                            />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => handlePlanGroupIdSetting(plan, planGroup)}
                                checked={plan.planGroupId === planGroup.planGroupId}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                  <Button onClick={() => deletePlanGroupId(plan.planId)}>DELETE</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEditCategoryIdPlanId(plan.planId)}>open editor</Button>
                  <Dialog
                    open={editCategoryIdPlanId === plan.planId}
                    onClose={() => setEditCategoryIdPlanId(null)}
                  >
                    <DialogContent>
                      <List
                        dense
                        style={{
                          maxHeight: '80vh',
                          width: '500px',
                          overflow: 'auto',
                        }}
                      >
                        {categories.map((category, categoryIndex) => (
                          <ListItem key={categoryIndex} button>
                            <ListItemText id={`${category.categoryId}`} primary={category.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => handlePlanCategoryIdSetting(plan, category)}
                                checked={plan.planCategoryId === category.categoryId}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEditMenuCategoryIdsPlanId(plan.planId)}>
                    open editor
                  </Button>
                  <Dialog
                    open={editMenuCategoryIdsPlanId === plan.planId}
                    onClose={() => setEditMenuCategoryIdsPlanId(null)}
                  >
                    <DialogContent>
                      <List
                        dense
                        style={{
                          maxHeight: '80vh',
                          width: '500px',
                          overflow: 'auto',
                        }}
                      >
                        {categories.map((category, categoryIndex) => (
                          <ListItem key={categoryIndex} button>
                            <ListItemText id={`${category.categoryId}`} primary={category.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => handleCategoryIdsSetting(plan, category)}
                                checked={
                                  Array.isArray(plan.menuCategories) &&
                                  plan.menuCategories
                                    .map(c => c.categoryId)
                                    .includes(category.categoryId)
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEditFirstOrderMenuIdsPlanId(plan.planId)}>
                    open editor
                  </Button>
                  <Dialog
                    open={editFirstOrderMenuIdsPlanId === plan.planId}
                    onClose={() => setEditFirstOrderMenuIdsPlanId(null)}
                  >
                    <DialogContent>
                      <List
                        dense
                        style={{
                          maxHeight: '80vh',
                          width: '500px',
                          overflow: 'auto',
                        }}
                      >
                        {menus.map((menu, menuIndex) => (
                          <ListItem key={menuIndex} button>
                            <ListItemText id={`${menu.menuId}`} primary={menu.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => handleFirstOrderMenuIdsSetting(plan, menu)}
                                checked={
                                  Array.isArray(plan.firstOrderMenus) &&
                                  plan.firstOrderMenus.map(m => m.menuId).includes(menu.menuId)
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => setEditRoleIdsPlanId(plan.planId)}>open editor</Button>
                  <Dialog
                    open={editRoleIdsPlanId === plan.planId}
                    onClose={() => setEditRoleIdsPlanId(null)}
                  >
                    <DialogContent>
                      <List
                        dense
                        style={{
                          maxHeight: '80vh',
                          width: '500px',
                          overflow: 'auto',
                        }}
                      >
                        {roles.map((role, roleIndex) => (
                          <ListItem key={roleIndex} button>
                            <ListItemText id={`${role.roleId}`} primary={role.name} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() => handleRoleIdsSetting(index, role)}
                                checked={plan.roles
                                  ?.map(role => role.roleId)
                                  ?.includes(role.roleId)}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    plan.options && plan.options.length > 0 ? classes.okCell : classes.ngCell
                  }
                >
                  {plan.options && plan.options.length > 0 ? 'オプションあり' : 'オプションなし'}
                  <Button onClick={() => openOptionDialog(plan)}>plan option</Button>
                </TableCell>
                <TableCell align="left">
                  {dataTableRowComponent({
                    defaultValue: plan.descriptionLabelText,
                    startEdit: () => setEditDescriptionLabelTextIndex(index),
                    cancelEdit,
                    applyChange: () =>
                      applyChange(editedDescriptionLabelText, 'descriptionLabelText', plan),
                    formClassName: classes.form,
                    setValue: val => setEditedDescriptionLabelText(val),
                    isShowEditForm: editDescriptionLabelTextIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    edge="end"
                    onChange={event => applyChange(event.target.checked, 'featured', plan)}
                    checked={plan.featured}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
      {selectedShop && menuTypes && categories && (
        <CreatePlanDialog
          menuTypes={menuTypes}
          categories={categories}
          shop={selectedShop}
          isOpen={createPlanModalOpen}
          onClose={() => setCreatePlanModalOpen(false)}
        />
      )}
      {selectedShop && (
        <CreatePlanGroupDialog
          shop={selectedShop}
          isOpen={createPlanGroupModalOpen}
          onClose={() => setCreatePlanGroupModalOpen(false)}
        />
      )}
      {selectedShop && selectedPlan && (
        <PlanOptionDialog
          reloadPlan={reloadPlan}
          plan={selectedPlan}
          shop={selectedShop}
          isOpen={optionModalOpen}
          onClose={() => setOptionModalOpen(false)}
        />
      )}
    </>
  );
};

export default Plans;
