import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { ShopClient } from '../../../../entity/shopClient.entity';

const shopRoles = ['shop', 'shop_admin', 'shop_stripe'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface SetClaimRequest {
  roles: string[];
}

interface Props {
  shopClient: ShopClient;
  isOpen: boolean;
  onClose: () => void;
}

const SetShopClientClaimsDialog: React.FC<Props> = ({ shopClient, isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [claimsRequest, setClaimsRequest] = useState({
    roles: [] as string[],
  } as SetClaimRequest);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  useEffect(() => {
    setClaimsRequest({ roles: shopClient.roles });
  }, [shopClient]);

  const setClamis = (roleName: string) => {
    if (claimsRequest.roles.includes(roleName)) {
      setClaimsRequest({ roles: claimsRequest.roles.filter(r => r !== roleName) });
    } else {
      setClaimsRequest({ roles: [...claimsRequest.roles, roleName] });
    }
  };

  const setClaim = () => {
    request
      .post(
        `shops/${shopClient.shopId}/shop_clients/${shopClient.shopClientId}/role`,
        claimsRequest,
        { headers: { 'x-company-id': shopClient.companyId } },
      )
      .then(() => {
        setDialogMessage('update success');
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Shop</DialogTitle>
        <DialogContent>
          <div>
            <p>companyId: {shopClient.companyId}</p>
            <p>shopId: {shopClient.shopId}</p>
          </div>
          <form className={classes.root} autoComplete="off">
            <div>
              <List
                dense
                style={{
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {shopRoles.map((roleName, roleIndex) => (
                  <ListItem key={roleIndex} button>
                    <ListItemText id={`${roleName}-${roleIndex}`} primary={roleName} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => setClamis(roleName)}
                        checked={claimsRequest.roles.includes(roleName)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
            <Button onClick={setClaim}>send</Button>
            <Button onClick={onClose}>close</Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default SetShopClientClaimsDialog;
