import React, { useEffect, useMemo, useState } from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import moment from 'moment-timezone';
import { Order } from '../../entity/order.entity';
import { TableUser } from '../../entity/tableUser.entity';
import { firestoreVersionCode } from '../../constants';
import firebase from '../../plugins/firebase';
import { useShops } from '../../hooks/useShops';

const getQueryTimes = (
  dateChangeHour: number,
  startDateString: string,
  endDateString: string,
): { start: number; end: number } => {
  const queryStartDate = moment.tz(startDateString, 'YYYY-MM-DD', 'Asia/Tokyo');
  const queryEndDate = moment.tz(endDateString, 'YYYY-MM-DD', 'Asia/Tokyo');
  const start = moment()
    .tz('Asia/Tokyo')
    .year(queryStartDate.year())
    .month(queryStartDate.month())
    .date(queryStartDate.date())
    .subtract(dateChangeHour, 'h')
    .hour(dateChangeHour)
    .minute(0)
    .second(0)
    .unix();
  const end = moment()
    .tz('Asia/Tokyo')
    .year(queryEndDate.year())
    .month(queryEndDate.month())
    .date(queryEndDate.date())
    .subtract(dateChangeHour, 'h')
    .add(24, 'h')
    .hour(dateChangeHour)
    .minute(0)
    .second(0)
    .unix();
  return { start, end };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Stat: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { shops, companies, setSelectedShop, selectedShop } = useShops();
  const [tableUsers, setTableUsers] = useState([] as TableUser[]);
  const [orders, setOrders] = useState([] as Order[]);
  const [userCount, setUserCount] = useState(0);
  const [numPeopleCount, setNumPeopleCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [handyOrderCount, setHandyOrderCount] = useState(0);
  const [startDateString, setStartDateString] = useState(
    moment()
      .tz('Asia/Tokyo')
      .format('YYYY-MM-DD') as string,
  );
  const [endDateString, setEndDateString] = useState(
    moment()
      .tz('Asia/Tokyo')
      .format('YYYY-MM-DD') as string,
  );
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  useEffect(() => {
    if (!selectedShop) return;
    const dateChangeTime = selectedShop.changeDateTime;
    const dateChangeHour = Number(dateChangeTime.split(':')[0]);
    const { start, end } = getQueryTimes(dateChangeHour, startDateString, endDateString);
    firebase
      .firestore()
      .collection(`versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/tableUsers`)
      .orderBy('activatedAt', 'desc')
      .startAt(end)
      .endAt(start)
      .get()
      .then(snapshots => {
        setTableUsers(snapshots.docs.map(doc => doc.data() as TableUser));
      });
    firebase
      .firestore()
      .collection(`versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/orders`)
      .orderBy('orderedAt', 'desc')
      .startAt(end)
      .endAt(start)
      .get()
      .then(snapshots => {
        setOrders(snapshots.docs.map(doc => doc.data() as Order));
      });
  }, [endDateString, selectedShop, startDateString]);
  useMemo(() => {
    setUserCount(tableUsers.reduce((acc, tableUser) => acc + tableUser.userIds.length, 0));
    setNumPeopleCount(tableUsers.reduce((acc, tableUser) => acc + tableUser.numPeople, 0));
    setOrderCount(tableUsers.reduce((acc, tableUser) => acc + tableUser.orderIds.length, 0));
    setHandyOrderCount(
      orders.reduce((acc, order) => {
        if (order.userName.indexOf('店員') !== -1) {
          return acc + 1;
        }
        return acc;
      }, 0),
    );
  }, [orders, tableUsers]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedShopId = event.target.value as string;
    const shop = shops.find(mShop => mShop.shopId === selectedShopId);
    if (!shop) return;
    const company = companies.find(mCompany => mCompany.companyId === shop.companyId);
    if (!company) return;
    setSelectedShop(shop);
  };
  return (
    <>
      <FormControl className={classes.formControl} style={{ width: '300px' }}>
        <InputLabel id="demo-simple-select-label">CompanyId/ShopName selection</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedShop && selectedShop.shopId}
          onChange={handleChange}
        >
          {shops.map(shop => (
            <MenuItem
              value={shop.shopId}
              key={shop.shopId}
            >{`${shop.companyId}/${shop.name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        defaultValue={moment()
          .tz('Asia/Tokyo')
          .format('YYYY-MM-DD')}
        type={'string'}
        id="filled-basic"
        label="start: YYYY-MM-DD"
        variant="filled"
        onChange={ev => setStartDateString(ev.target.value)}
      />
      <TextField
        defaultValue={moment()
          .tz('Asia/Tokyo')
          .format('YYYY-MM-DD')}
        type={'string'}
        id="filled-basic"
        label="end: YYYY-MM-DD"
        variant="filled"
        onChange={ev => setEndDateString(ev.target.value)}
      />
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        Note: 人数は延べ人数
      </div>
      <Paper>
        <div style={{ margin: '30px', fontSize: '30px' }}>
          userCount(IDの取れた人数): {`${userCount}`}
        </div>
        <div style={{ margin: '30px', fontSize: '30px' }}>
          numPeople(入力された人数): {`${numPeopleCount}`}
        </div>
        <div style={{ margin: '30px', fontSize: '30px' }}>orderCount: {`${orderCount}`}</div>
        <div style={{ margin: '30px', fontSize: '30px' }}>
          handy orderCount(オーダー回数): {`${handyOrderCount}`}
        </div>
        <div>
          <p>TableUsers</p>
          {tableUsers.map(tableUser => (
            <div key={tableUser.tableUserId}>{JSON.stringify(tableUser)}</div>
          ))}
        </div>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default Stat;
