import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import firebase from '../../../plugins/firebase';
import { Role } from '../../../entity/role.entity';
import request from '../../../plugins/api';
import clsx from 'clsx';
import { useShops } from '../../../hooks/useShops';

const Moment = require('moment');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface PrinterLog {
  key: string;
  companyId: string;
  shopId: string;
  roleId: string;
  type: string;
  content: string;
  timestamp: any;
}

const errorPrinterLog = ['connect-fail', 'stop', 'offline', 'poweroff'];

const PrinterLog: React.FC = () => {
  const classes = useStyles();
  const { shops } = useShops();
  const [logs, setLogs] = useState([] as PrinterLog[]);
  const [roles, setRoles] = useState([] as Role[]);

  useEffect(() => {
    shops.forEach(shop => {
      request
        .get(`shops/${shop.shopId}/roles`, {
          headers: { 'x-company-id': shop.companyId },
        })
        .then(roleResponse => {
          setRoles(prev => [...prev, ...roleResponse.data]);
        });
    });
  }, [shops]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('printerLogs')
      .orderBy('timestamp', 'desc')
      .limit(100)
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logData = snapshot.docs.map(value => value.data()) as PrinterLog[];
          setLogs(logData);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  const getShopNameById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.name;
    } else {
      return '';
    }
  };

  const getRoleNameById = (roleId: number): string => {
    const role = roles.find(ro => ro.roleId === roleId);
    if (role) {
      return role.name;
    } else {
      return '';
    }
  };
  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>
          Contentについて:
          <br />
          EPSONプリンターから取得されるプリンター状態ログ．異常系は赤く表示
          <br />
          最終状態が赤の場合は異常が疑われる．ログを元に店舗に状況確認と復帰処理をする
        </p>
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>key</TableCell>
              <TableCell align="right">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Role ID</TableCell>
              <TableCell align="right">Role Name</TableCell>
              <TableCell align="right">Log Type</TableCell>
              <TableCell align="right">Content</TableCell>
              <TableCell align="right">TimeStamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index: number) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {log.key}
                </TableCell>
                <TableCell align="right">{log.companyId}</TableCell>
                <TableCell align="right">{log.shopId}</TableCell>
                <TableCell align="right">{getShopNameById(log.shopId)}</TableCell>
                <TableCell align="right">{log.roleId}</TableCell>
                <TableCell align="right">{getRoleNameById(Number(log.roleId))}</TableCell>
                <TableCell align="right">{log.type}</TableCell>
                <TableCell
                  align="right"
                  className={clsx(
                    log.content && errorPrinterLog.includes(log.content)
                      ? classes.ngCell
                      : classes.okCell,
                  )}
                >
                  {log.content}
                </TableCell>
                <TableCell align="right">
                  {Moment(log.timestamp.toDate()).format('YYYY-MM-DD HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default PrinterLog;
