import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import { Role } from '../../../entity/role.entity';
import moment from 'moment-timezone';
import firebase from '../../../plugins/firebase';
import { firestoreVersionCode } from '../../../constants';
import { TableUser } from '../../../entity/tableUser.entity';
import PrintStatusComponent from '../components/printStatusComponent';
import { PrintStatus } from '../../../entity/order.entity';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const TableUsers: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { selectedShop } = useShops();

  const [roles, setRoles] = useState([] as Role[]);
  const [tableUsers, setTableUsers] = useState([] as TableUser[]);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/roles`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setRoles(response.data);
      });
    const queryStartAt = moment()
      .subtract(48, 'h')
      .unix();
    const unsubscribe = firebase
      .firestore()
      .collection(`versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/tableUsers`)
      .where('activatedAt', '>', queryStartAt)
      .orderBy('activatedAt', 'desc')
      .onSnapshot(snapshot => {
        const tableUsers = snapshot.docs.map(snapshot => snapshot.data()) as TableUser[];
        setTableUsers(tableUsers);
      });
    return unsubscribe;
  }, [selectedShop]);

  const toInActive = (tableUser: TableUser) => {
    request.post(
      `shops/${tableUser.shopId}/tableUsers/${tableUser.tableUserId}`,
      { tableUserState: 'inactive' },
      { headers: { 'x-company-id': tableUser.companyId } },
    );
  };
  const toPrinted = (tableUser: TableUser) => {
    updatePrintStatus(tableUser, 'printed');
  };
  const toUnPrint = (tableUser: TableUser) => {
    updatePrintStatus(tableUser, 'unPrint');
  };
  const updatePrintStatus = (tableUser: TableUser, to: 'printed' | 'unPrint') => {
    const currentStatus = tableUser.status;
    const updatedStatus = {} as PrintStatus;
    Object.keys(currentStatus).forEach(_roleId => {
      const roleId = Number(_roleId);
      updatedStatus[roleId] = {
        isPrinted: to === 'printed',
        isRead: to === 'printed',
      };
    });
    request.post(
      `shops/${tableUser.shopId}/tableUsers/${tableUser.tableUserId}`,
      { status: updatedStatus },
      { headers: { 'x-company-id': tableUser.companyId } },
    );
  };
  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        Note: 過去48時間分のみ表示，リアルタイム更新
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">status</TableCell>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">table name</TableCell>
              <TableCell align="right">支払い予定金額</TableCell>
              <TableCell align="right">TableUser ID</TableCell>
              <TableCell align="right">Activated at</TableCell>
              <TableCell align="right">TableUserState</TableCell>
              <TableCell align="right">userIds</TableCell>
              <TableCell align="right">num people</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableUsers.map((tableUser, index) => (
              <TableRow key={index}>
                <TableCell align="right">
                  <PrintStatusComponent printStatus={tableUser.status} roles={roles} />
                  <Button onClick={() => toUnPrint(tableUser)}>未印刷にする</Button>
                  <Button onClick={() => toPrinted(tableUser)}>印刷済みにする</Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {tableUser.companyId}
                </TableCell>
                <TableCell align="right">{tableUser.shopId}</TableCell>
                <TableCell align="right">{selectedShop && selectedShop.name}</TableCell>
                <TableCell align="right">{tableUser.tableName}</TableCell>
                <TableCell align="right">
                  <p
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >{`${tableUser.paidAmount}円`}</p>
                </TableCell>
                <TableCell align="right">{tableUser.tableUserId}</TableCell>
                <TableCell align="right">
                  {moment(tableUser.activatedAt * 1000)
                    .tz('Asia/Tokyo')
                    .format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell align="right">
                  {tableUser.tableUserState}
                  <Button onClick={() => toInActive(tableUser)}>inactiveにする</Button>
                </TableCell>
                <TableCell align="right">{JSON.stringify(tableUser.userIds)}</TableCell>
                <TableCell align="right">{tableUser.numPeople}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default TableUsers;
