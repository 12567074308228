import React from 'react';
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link, Route, Switch } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  AggregationSummaryRoute,
  CategoriesRoute,
  ClerksRoute,
  CompaniesRoute,
  DeviceConnectionLogRoute,
  DeviceStatusLogRoute,
  DishUpReceiptGroupRoute,
  DishUpsRoute,
  FeesRoute,
  MenusRoute,
  OrdersRoute,
  PlansRoute,
  PrinterLogRoute,
  PrintTargetsRoute,
  RolesRoute,
  router,
  ServiceAdminsRoute,
  ShopAdminsRoute,
  ShopClientsRoute,
  ShopsRoute,
  StatsRoute,
  TablesRoute,
  TabletDeviceStatusLogRoute,
  TableUsersRoute,
  OnBoardingRoute,
  OldDeviceStatusLogRoute,
  OldDeviceConnectionLogRoute,
  PosRoute,
  PosPollingMonitoringRoute,
  PosPollingOrderMonitoringRoute,
} from './Router';
import firebase from '../plugins/firebase';
import PrinterLog from './deviceManagement/printerLog/PrinterLog';
import DeviceStatus from './deviceManagement/devicestatus/DeviceStatus';
import ConnectionStatus from './deviceManagement/connectionstatus/ConnectionStatus';
import Menus from './dataManagement/menus/Menus';
import ShopClients from './dataManagement/shopClients/ShopClients';
import Roles from './dataManagement/roles/Roles';
import Tables from './dataManagement/tables/Tables';
import Shops from './dataManagement/shops/Shops';
import Categories from './dataManagement/categories/Categories';
import Companies from './dataManagement/companies/Companies';
import Plans from './dataManagement/plans/Plans';
import Fees from './dataManagement/fees/Fees';
import Orders from './dataManagement/orders/Orders';
import TableUsers from './dataManagement/tableUsers/TableUsers';
import DishUps from './dataManagement/dishUps/dishUps';
import Stat from './stat/stat';
import OnBoarding from './onBoarding/OnBoarding';
import OldDeviceStatus from './deviceManagement/old-devicestatus/OldDeviceStatus';
import OldConnectionStatus from './deviceManagement/old-connectionstatus/OldConnectionStatus';
import EmergencyPrintBackup from './emergencyPrintBackup';
import { TabletDeviceStatus } from './deviceManagement/tabletdevicestatus/TabletDeviceStatus';
import { useShops } from '../hooks/useShops';
import Clerks from './dataManagement/clerks/Clerks';
import Pos from './dataManagement/pos/Pos';
import ServiceAdmins from './dataManagement/serviceAdmins/ServiceAdmins';
import ShopAdmins from './dataManagement/shopAdmins/ShopAdmins';
import { DishUpReceiptGroups } from './dataManagement/dishUpReceiptGroups/DishUpReceiptGroups';
import { AggregationSummary } from './dataManagement/aggregationSummary/AggregationSummary';
import { PrintTargets } from './deviceManagement/printTargets/PrintTargets';

import { PosPollingMonitoring } from './posManagement/PosPollingMonitoring';

import { PosPollingOrderMonitoring } from './posManagement/PosPollingOrderMonitoring';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  refreshButton: {
    position: 'absolute',
    right: 20,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

const signOut = async () => firebase.auth().signOut();

const Main: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('');
  const { shops, companies, setSelectedShop, selectedShop } = useShops();

  const { isLoading, setIsInitialized } = useShops(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const toggleDrawer = (side: string, isOpenDrawer: boolean) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpenDrawer);
  };

  const handleShopChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedShopId = event.target.value as string;
    const shop = shops.find(mShop => mShop.shopId === selectedShopId);
    if (!shop) return;
    const company = companies.find(mCompany => mCompany.companyId === shop.companyId);
    if (!company) return;
    setSelectedShop(shop);
  };

  const stage = process.env.REACT_APP_FIREBASE_PROJECT;
  let barColor = '#f47a55';
  if (stage && stage.indexOf('stag') !== -1) {
    barColor = '#4a4a4a';
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ background: barColor }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap>
            [{stage}]
          </Typography>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>
          <Button
            onClick={() => setIsInitialized(false)}
            disabled={isLoading}
            startIcon={<RefreshIcon />}
            variant={'contained'}
            className={classes.refreshButton}
          >
            Refresh companies & shops
          </Button>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onOpen={toggleDrawer('left', true)}
        onClose={toggleDrawer('left', false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {router.map((route, index) => (
            <Link
              to={route.path}
              key={index}
              onClick={() => {
                setOpen(false);
                setPageTitle(route.title);
              }}
            >
              <ListItem button key={index}>
                <ListItemText primary={route.title} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <ListItem>
          <Button style={{ width: '100%' }} onClick={signOut}>
            ログアウト
          </Button>
        </ListItem>
      </SwipeableDrawer>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <EmergencyPrintBackup />
          <div style={{ margin: 20 }}>
            <Typography>店舗選択</Typography>
            <FormControl style={{ margin: theme.spacing(1), minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">CompanyId/ShopName selection</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedShop?.shopId}
                onChange={handleShopChange}
              >
                {shops.map(shop => (
                  <MenuItem
                    value={shop.shopId}
                    key={shop.shopId}
                  >{`${shop.companyId}/${shop.name}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Switch>
            <Route exact path={DeviceStatusLogRoute.path} component={DeviceStatus} />
            <Route exact path={DeviceConnectionLogRoute.path} component={ConnectionStatus} />
            <Route exact path={TabletDeviceStatusLogRoute.path} component={TabletDeviceStatus} />
            <Route exact path={PrinterLogRoute.path} component={PrinterLog} />
            <Route exact path={AggregationSummaryRoute.path} component={AggregationSummary} />
            <Route exact path={CompaniesRoute.path} component={Companies} />
            <Route exact path={ShopsRoute.path} component={Shops} />
            <Route exact path={ClerksRoute.path} component={Clerks} />
            <Route exact path={TablesRoute.path} component={Tables} />
            <Route exact path={CategoriesRoute.path} component={Categories} />
            <Route exact path={MenusRoute.path} component={Menus} />
            <Route exact path={ShopClientsRoute.path} component={ShopClients} />
            <Route exact path={RolesRoute.path} component={Roles} />
            <Route exact path={DishUpReceiptGroupRoute.path} component={DishUpReceiptGroups} />
            <Route exact path={PlansRoute.path} component={Plans} />
            <Route exact path={FeesRoute.path} component={Fees} />
            <Route exact path={OrdersRoute.path} component={Orders} />
            <Route exact path={TableUsersRoute.path} component={TableUsers} />
            <Route exact path={DishUpsRoute.path} component={DishUps} />
            <Route exact path={StatsRoute.path} component={Stat} />
            <Route exact path={OnBoardingRoute.path} component={OnBoarding} />
            <Route exact path={PosRoute.path} component={Pos} />
            <Route exact path={PosPollingMonitoringRoute.path} component={PosPollingMonitoring} />
            <Route
              exact
              path={PosPollingOrderMonitoringRoute.path}
              component={PosPollingOrderMonitoring}
            />
            <Route exact path={ServiceAdminsRoute.path} component={ServiceAdmins} />
            <Route exact path={ShopAdminsRoute.path} component={ShopAdmins} />
            <Route exact path={OldDeviceStatusLogRoute.path} component={OldDeviceStatus} />
            <Route exact path={OldDeviceConnectionLogRoute.path} component={OldConnectionStatus} />
            <Route exact path={PrintTargetsRoute.path} component={PrintTargets} />
          </Switch>
        </main>
      )}
    </div>
  );
};

export default Main;
