import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Fab,
  Switch,
} from '@material-ui/core';
import { Menu } from '../../../entity/menu.entity';
import request from '../../../plugins/api';

const useStyles = makeStyles({
  floatButton: {
    position: 'fixed',
    right: '10px',
    bottom: '10px',
  },
  editedRow: {
    background: 'orange',
  },
  table: {
    minWidth: 650,
  },
});

interface Props {
  menus: Menu[];
  onClose: () => void;
  reloadMenus: () => void;
}

const MenuImageUrlEditor: React.FC<Props> = ({ menus, onClose, reloadMenus }) => {
  const classes = useStyles();

  const [editedMenuValues, setEditedMenuValues] = useState(
    {} as { [menuId: number]: Partial<Menu> },
  );
  const [nameFilter, setNameFilter] = useState('');

  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const filteredMenus = useCallback(
    () => menus.filter(menu => menu.name.indexOf(nameFilter) !== -1),
    [menus, nameFilter],
  );

  const setUpdateValue = <K extends keyof Menu>(menuId: number, key: K, value: Menu[K]) => {
    if (editedMenuValues[menuId]) {
      const updated = { ...editedMenuValues[menuId], [key]: value };
      setEditedMenuValues(prev => ({ ...prev, [menuId]: updated }));
    } else {
      setEditedMenuValues(prev => ({ ...prev, [menuId]: { [key]: value } }));
    }
  };

  const applyChange = () => {
    const updateTasks = Object.keys(editedMenuValues).map(async menuId => {
      const targetMenu = menus.find(menu => menu.menuId === Number(menuId));
      if (!targetMenu) {
        throw new Error();
      }
      return request.post(
        `shops/${targetMenu.shopId}/menus/${targetMenu.menuId}`,
        editedMenuValues[Number(menuId)],
        { headers: { 'x-company-id': targetMenu.companyId } },
      );
    });

    Promise.all(updateTasks)
      .then(() => {
        setDialogMessage('update success');
        setEditedMenuValues({});
        reloadMenus();
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
  };

  return (
    <>
      <Paper>
        <TextField
          fullWidth
          type="string"
          id={`name-filter`}
          label="name filter"
          onChange={ev => setNameFilter(ev.target.value)}
        />
        <Table className={classes.table} aria-label="simple table">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '80%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="right">Menu ID</TableCell>
              <TableCell align="right">Menu Name</TableCell>
              <TableCell align="right">伝票名</TableCell>
              <TableCell align="right">Menu ImageURL</TableCell>
              <TableCell align="right">IsDisplay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMenus().map((menu, index) => (
              <TableRow key={index}>
                <TableCell align="right">{menu.menuId}</TableCell>
                <TableCell align="right"> {menu.name} </TableCell>
                <TableCell align="right">
                  <TextField
                    fullWidth
                    defaultValue={menu.receiptDisplayName}
                    type="string"
                    id={`${menu.menuId}`}
                    label="receiptDisplayName"
                    onChange={ev =>
                      setUpdateValue(menu.menuId, 'receiptDisplayName', ev.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    fullWidth
                    defaultValue={menu.imageUrl}
                    type="string"
                    id={`${menu.menuId}`}
                    label="imageUrl"
                    onChange={ev => setUpdateValue(menu.menuId, 'imageUrl', ev.target.value)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    checked={editedMenuValues[menu.menuId]?.isDisplay ?? menu.isDisplay}
                    onChange={() => setUpdateValue(menu.menuId, 'isDisplay', !menu.isDisplay)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div className={classes.floatButton}>
        <Fab variant="extended" onClick={applyChange}>
          Send
        </Fab>
        <Fab variant="extended" onClick={onClose}>
          close
        </Fab>
      </div>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default MenuImageUrlEditor;
