import {
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useShops } from '../../../hooks/useShops';
import request from '../../../plugins/api';
import moment from 'moment';
import { sumBy } from 'lodash';

interface Detail {
  kind: string;
  amount: number;
}

interface LabelDetail {
  label: string;
  value: string;
}

interface AggregationSummaryResponse {
  paymentDetails: Detail[];
  discountDetails: Detail[];
  salesDetails: Detail[];
  expenses: {
    orderSummary: {
      name: string;
      price: number;
      quantity: number;
      priceAll: number;
      orderType: string;
      options: {
        name: string;
        choiceInfo: { name: string; quantity: number; price: number; priceAll: number }[];
      }[];
    }[];
  };
  info: LabelDetail[];
  salesesByInflowSourceTag?: {
    tag: string;
    totalNumberOfPeople: number;
    totalNumberOfGroups: number;
    totalSales: number;
    totalSalesPerPerson: number;
  }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      padding: 30,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

export const AggregationSummary: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { selectedShop } = useShops();
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'd')
      .set('h', 0)
      .set('m', 0),
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [startHour, setStartHour] = useState<number | null>(null);
  const [endHour, setEndHour] = useState<number | null>(null);

  const [response, setResponse] = useState<AggregationSummaryResponse | null>(null);

  useEffect(() => {
    if (selectedShop) {
      setIsLoading(true);
      request
        .get(
          `shop_app/cash_register/sales_report/${
            selectedShop.shopId
          }/aggregation_summary?startDate=${startDate.format('YYYY-MM-DD')}&startHour=${
            startHour ? startHour : 0
          }${
            endDate
              ? `&endDate=${endDate.format('YYYY-MM-DD')}&endHour=${endHour ? endHour : 24}`
              : ''
          }`,
          {
            headers: { 'x-company-id': selectedShop.companyId },
          },
        )
        .then(response => {
          setResponse(response.data);
          setError('');
        })
        .catch(e => {
          setResponse(null);
          setError(e.title ? e.title : e.toString());
        })
        .finally(() => setIsLoading(false));
    }
  }, [endDate, endHour, selectedShop, startDate, startHour]);

  return (
    <>
      <div>
        <TextField
          id="start"
          label="start date"
          type="date"
          style={{ width: 200, margin: 20 }}
          value={startDate.format('YYYY-MM-DD')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={event => setStartDate(moment(event.target.value))}
        />
        <TextField
          id="end"
          label="end date"
          type="date"
          style={{ width: 200, margin: 20 }}
          value={endDate ? endDate.format('YYYY-MM-DD') : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={event => setEndDate(moment(event.target.value))}
        />
      </div>
      <div>
        <TextField
          id="start"
          label="start hour"
          type="time"
          style={{ width: 200, margin: 20 }}
          value={startHour ? `${startHour}:00` : ''}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 3600, // 5 min
          }}
          onChange={event => setStartHour(parseInt(event.target.value.split(':')[0]))}
        />
        <TextField
          id="end"
          label="end hour"
          type="time"
          style={{ width: 200, margin: 20 }}
          value={endHour ? `${endHour}:00` : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={event => setEndHour(parseInt(event.target.value.split(':')[0]))}
          inputProps={{
            step: 3600, // 5 min
          }}
        />
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : response !== null ? (
        <>
          <Paper style={{ margin: 30 }}>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              出数情報
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>choice name</TableCell>
                  <TableCell>quantity</TableCell>
                  <TableCell>price</TableCell>
                  <TableCell>priceAll</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.expenses.orderSummary.map(summary => (
                  <>
                    <TableRow>
                      <TableCell>{summary.name}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{summary.quantity}</TableCell>
                      <TableCell>{summary.price}</TableCell>
                      <TableCell>{summary.priceAll}</TableCell>
                    </TableRow>
                    {summary.options
                      .flatMap(o => o.choiceInfo.map(c => ({ ...c, optionName: o.name })))
                      .map(c => (
                        <TableRow key={c.name}>
                          <TableCell />
                          <TableCell>{c.optionName + ': ' + c.name}</TableCell>
                          <TableCell>{c.quantity}</TableCell>
                          <TableCell>{c.price}</TableCell>
                          <TableCell>{c.priceAll}</TableCell>
                        </TableRow>
                      ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ margin: 30 }}>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              支払情報
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>value</TableCell>
                  <TableCell>label</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.paymentDetails.map(detail => (
                  <>
                    <TableRow>
                      <TableCell>{detail.kind}</TableCell>
                      <TableCell>{detail.amount}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ margin: 30 }}>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              割引情報
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>value</TableCell>
                  <TableCell>label</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.discountDetails.map(detail => (
                  <>
                    <TableRow>
                      <TableCell>{detail.kind}</TableCell>
                      <TableCell>{detail.amount}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ margin: 30 }}>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              売上情報
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>value</TableCell>
                  <TableCell>label</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.salesDetails.map(detail => (
                  <>
                    <TableRow>
                      <TableCell>{detail.kind}</TableCell>
                      <TableCell>{detail.amount}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ margin: 30 }}>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              媒体別売上
            </Typography>
            <Typography variant={'h3'} style={{ padding: 30 }}>
              合計 {sumBy(response?.salesesByInflowSourceTag ?? [], a => a.totalSales)}
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>媒体</TableCell>
                  <TableCell>人数</TableCell>
                  <TableCell>組数</TableCell>
                  <TableCell>売上</TableCell>
                  <TableCell>単価</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response?.salesesByInflowSourceTag?.map(data => (
                  <TableRow key={data.tag}>
                    <TableCell>{data.tag}</TableCell>
                    <TableCell>{data.totalNumberOfPeople}</TableCell>
                    <TableCell>{data.totalNumberOfGroups}</TableCell>
                    <TableCell>{data.totalSales}</TableCell>
                    <TableCell>{data.totalSalesPerPerson}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : null}
      {error ? <Typography>{error} </Typography> : <></>}
    </>
  );
};
