import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { PaymentType } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateShopDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState(4);
  const [open, setOpen] = useState('07:00');
  const [close, setClose] = useState('23:00');
  const [topImageUrl, setTopImageUrl] = useState(
    'https://s3-ap-northeast-1.amazonaws.com/assets.stag.mo.dinii.jp/app-images/sato_000/sh_000/shop.jpg',
  );
  const [naviImageUrl, setNaviImageUrl] = useState(
    'https://s3-ap-northeast-1.amazonaws.com/assets.stag.mo.dinii.jp/app-images/sato_000/sh_000/shop.jpg',
  );
  const [jaName, setJaName] = useState('');
  const [enName, setEnName] = useState('');
  const [exTax, setExTax] = useState(true);
  const [taxRate, setTaxRate] = useState(0.1);
  const [paymentType, setPaymentType] = useState(PaymentType.ALL);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createShop = () => {
    const createShopRequest = {
      name,
      nameEn: enName,
      companyId,
      open,
      close,
      topImageUrl,
      naviImageUrl,
      exTax,
      taxRate,
      paymentType,
      lastOrderText: '/',
    };
    request
      .post('shops', createShopRequest, {
        headers: { 'x-company-id': companyId },
      })
      .then(() => {
        setDialogMessage('update success');
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Shop</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="Name"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <TextField
                required
                id="company-id-input"
                label="CompanyId"
                value={companyId}
                variant="filled"
                type="number"
                onChange={ev => setCompanyId(parseInt(ev.target.value, 10))}
              />

              <TextField
                required
                id="open-input"
                label="Open(HH:MM)"
                variant="filled"
                value={open}
                type="string"
                inputProps={{ pattern: '[0-9][0-9]:[0-9][0-9]' }}
                onChange={ev => setOpen(ev.target.value)}
              />
              <TextField
                required
                id="close-input"
                label="Close(HH:MM)"
                variant="filled"
                value={close}
                type="string"
                inputProps={{ pattern: '[0-9][0-9]:[0-9][0-9]' }}
                onChange={ev => setClose(ev.target.value)}
              />
            </div>

            <div>
              <TextField
                required
                id="ja-name-input"
                label="JaName"
                value={jaName}
                variant="filled"
                type="string"
                onChange={ev => setJaName(ev.target.value)}
              />
              <TextField
                required
                id="en-name-input"
                value={enName}
                label="EnName"
                variant="filled"
                type="string"
                onChange={ev => setEnName(ev.target.value)}
              />
            </div>

            <div>
              <TextField
                id="top-image-url-input"
                value={topImageUrl}
                label="Top Image URL"
                variant="filled"
                type="string"
                onChange={ev => setTopImageUrl(ev.target.value)}
              />
              <TextField
                id="navi-image-url-input"
                label="Navi Image URL"
                value={naviImageUrl}
                variant="filled"
                type="string"
                onChange={ev => setNaviImageUrl(ev.target.value)}
              />
            </div>

            <div>
              <InputLabel>ex tax</InputLabel>
              <Switch required checked={exTax} onChange={() => setExTax(!exTax)} value="checkedA" />
              <TextField
                required
                id="tax-rate-input"
                value={taxRate}
                label="taxRate"
                variant="filled"
                type="number"
                onChange={ev => setTaxRate(parseInt(ev.target.value, 10))}
              />
            </div>

            <InputLabel id="demo-simple-select-label">PaymentType</InputLabel>
            <Select
              required
              style={{ width: '300px', marginRight: '30px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentType}
              onChange={ev => setPaymentType(ev.target.value as PaymentType)}
            >
              {Object.values(PaymentType).map(paymentTypeValue => (
                <MenuItem value={paymentTypeValue} key={paymentTypeValue}>
                  {paymentTypeValue}
                </MenuItem>
              ))}
            </Select>
            <div>
              <Button type="button" onClick={createShop}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateShopDialog;
