import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { TableData } from '../../../../entity/table.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateTableRequest {
  name: string;
}

interface Props {
  companyId: number;
  shopId: string;
  isOpen: boolean;
  onClose: () => void;
  updateTable: (createdTable: TableData) => void;
}

const CreateTableDialog: React.FC<Props> = ({
  companyId,
  shopId,
  updateTable,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [createTableRequest, setCreateTableRequest] = useState({} as CreateTableRequest);
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createTable = () => {
    request
      .post(
        `shops/${shopId}/tables`,
        { ...createTableRequest, companyId },
        { headers: { 'x-company-id': companyId } },
      )
      .then(res => {
        setDialogMessage('create success');
        updateTable(res.data as TableData);
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Table</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="Table name"
                value={createTableRequest.name}
                variant="filled"
                type="string"
                onChange={ev => setCreateTableRequest({ name: ev.target.value })}
              />
            </div>

            <div>
              <Button type="button" onClick={createTable}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateTableDialog;
