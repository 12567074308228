import React, { useEffect, useState } from 'react';
import {
  Button,
  makeStyles,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import firebase from '../../../plugins/firebase';
import DeviceLogsDialog from './DeviceLogsDialog/DeviceLogsDialog';
import moment from 'moment-timezone';
import { PrinterSettingsDialog } from './PrinterSettingsDialog/PrinterSettingsDialog';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface DeviceStatus {
  lastRebootedAt?: any;
  lastUpdatedAt: any;
  lastUpdatedType: string;
  companyId: string;
  shopId: string;
  uid: string;
  battery: number;
  appVersion: number;
}

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm');

const isToday = (date: Date) => {
  const now = moment
    .tz('Asia/Tokyo')
    .hour(0)
    .minute(0)
    .second(0);
  return moment(date).diff(now) > 0;
};

const DeviceStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as DeviceStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as DeviceStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [printerSettingsDialog, setPrinterSettingsDialog] = useState(false);
  const [filterShopIds, setFilterShopIds] = useState<string[]>([]);

  const { shops } = useShops();

  const updateFilterShopIds = (shopId: string) => {
    if (filterShopIds.includes(shopId)) {
      setFilterShopIds(prev => prev.filter(s => s !== shopId));
    } else {
      setFilterShopIds(prev => [...prev, shopId]);
    }
  };

  useEffect(() => {
    setFilterShopIds(shops.filter(shop => shop.enableAlert).map(shop => shop.shopId));
  }, [shops]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('deviceStatus')
      .orderBy('lastUpdatedAt', 'desc')
      .limit(100)
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logs = snapshot.docs
            .filter(d => d.id.startsWith('DiniiDoc'))
            .map(value => value.data() as DeviceStatus)
            .filter(d => d.uid !== undefined);
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  const ref = firebase.firestore().collection('deviceRemoteManagement');
  const softReboot = (uid: string) => {
    if (window.confirm(`${uid}を再起動しますか？`)) {
      ref
        .doc(uid)
        .set({ softRebootId: firebase.firestore.FieldValue.increment(1) }, { merge: true });
    } else {
      window.alert('キャンセルされました'); // 警告ダイアログを表示
    }
  };

  const reboot = (uid: string) => {
    if (window.confirm(`${uid}を再起動しますか？`)) {
      ref.doc(uid).set({ rebootId: firebase.firestore.FieldValue.increment(1) }, { merge: true });
    } else {
      window.alert('キャンセルされました'); // 警告ダイアログを表示
    }
  };

  const sendCommand = async (uid: string, command: string) => {
    if (window.confirm(`send ${command} to ${uid}?`)) {
      await ref.doc(uid).set({ command: 'none' }, { merge: true });
      setTimeout(async () => {
        await ref.doc(uid).set({ command }, { merge: true });
      }, 2000);
    } else {
      window.alert('キャンセルされました'); // 警告ダイアログを表示
    }
  };

  const getShopNameById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.name;
    } else {
      return '';
    }
  };

  const getOpenTimeById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.open;
    } else {
      return '';
    }
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>
          Statusについて:
          <br />
          reboot→再起動実行時のログ
          <br /> waitingHome→通信開始待ち受け画面に到達した時のログ <br />
          startOperation→通信開始を押した時のログ
          <br />
          <br />
          ・正しい遷移は，夜間にreboot→waitingHome→営業開始時間前にstartOperation
          <br />
          ・last rebooted at 欄は，一日以上リブートがない場合に赤くなります
        </p>
        <hr />
        <p>
          異常かなと思ったら:
          <br />
          waitingHomeから開店時刻になってもstartOperationされない→電源切れの可能性あり．店舗へ連絡する
          <br />
          Battery欄のバッテリーがゼロである → 電源切れの可能性あり．店舗へ連絡する
          <br />
          last rebooted at が赤く点灯している →
          起動時間が24時間を超えている．長時間起動は不具合になるため，早急に店舗に連絡し再起動処理をしてもらう
        </p>
      </div>
      <div>
        <p>ShopId Filter[初期値はenableAlertがONの店舗]</p>
        <Button onClick={() => setFilterShopIds([])}>フィルターリセット</Button>
        <List
          dense
          style={{
            width: '300px',
            maxHeight: '300px',
            overflow: 'auto',
          }}
        >
          {shops.map((shop, index) => (
            <ListItem key={index} button>
              <ListItemText id={`${shop.shopId}`} primary={shop.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => {
                    updateFilterShopIds(shop.shopId);
                  }}
                  checked={filterShopIds.includes(shop.shopId)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">開店時間</TableCell>
              <TableCell align="right">UID</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
              <TableCell align="right">Battery</TableCell>
              <TableCell align="right">Last rebooted at</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses
              .filter(stat => filterShopIds.length === 0 || filterShopIds.includes(stat.shopId))
              .map((status, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {status.companyId}
                  </TableCell>
                  <TableCell align="right">{status.shopId}</TableCell>
                  <TableCell align="right">{getShopNameById(status.shopId)}</TableCell>
                  <TableCell align="right">{getOpenTimeById(status.shopId)}</TableCell>
                  <TableCell align="right">{status.uid}</TableCell>
                  <TableCell align="right">
                    {moment(status.lastUpdatedAt.toDate()).format('YYYY-MM-DD HH:mm')}
                  </TableCell>
                  <TableCell align="right">{status.lastUpdatedType}</TableCell>
                  <TableCell align="right">{status.battery}</TableCell>
                  <TableCell
                    className={clsx(
                      status.lastRebootedAt && isToday(status.lastRebootedAt.toDate())
                        ? classes.okCell
                        : classes.ngCell,
                    )}
                    align="right"
                  >
                    {status.lastRebootedAt && formatDate(status.lastRebootedAt.toDate())}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        setSelectedStatus(status);
                        setLogModalOpen(true);
                      }}
                    >
                      ログを表示する
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => sendCommand(status.uid, 'pingPrinter')}
                    >
                      プリンターping
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        setSelectedStatus(status);
                        setPrinterSettingsDialog(true);
                      }}
                    >
                      プリンター設定
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => sendCommand(status.uid, 'startListenPrinter')}
                    >
                      通信開始
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => sendCommand(status.uid, 'stopListenPrinter')}
                    >
                      通信終了
                    </Button>
                    <Button variant={'contained'} onClick={() => softReboot(status.uid)}>
                      アプリ再起動
                    </Button>
                    <Button variant={'contained'} onClick={() => reboot(status.uid)}>
                      端末再起動
                    </Button>
                  </TableCell>
                  <TableCell align="left">{status.appVersion}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <DeviceLogsDialog
        isOpen={logModalOpen}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        uid={selectedStatus.uid}
        onClose={() => setLogModalOpen(false)}
      />
      <PrinterSettingsDialog
        isOpen={printerSettingsDialog}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        uid={selectedStatus.uid}
        onClose={() => setPrinterSettingsDialog(false)}
      />
    </>
  );
};

export default DeviceStatus;
