import React, { useCallback, useEffect, useState } from 'react';
import firebase from '../../../../plugins/firebase';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

interface Props {
  isOpen: boolean;
  companyId?: string;
  shopId?: string;
  uid?: string;
  onClose: () => void;
}

interface Settings {
  printerConnectionTargetName?: string;
  printerConnectionTarget?: string;
  drawerPassword?: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

export const TabletSettingsDialog: React.FC<Props> = ({
  isOpen,
  shopId,
  uid,
  companyId,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [settings, setSettings] = useState<Settings>({});
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(Date.now());

  const saveTarget = useCallback(async () => {
    setIsLoading(true);
    try {
      await firebase
        .firestore()
        .collection('tabletDeviceRemoteManagement')
        .doc(uid)
        .set(settings, { merge: true });
      setUpdatedAt(Date.now());
      window.alert('success');
    } catch (e) {
      console.log(e);
      window.alert('fail');
    } finally {
      setIsLoading(false);
    }
  }, [uid, settings]);

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);

      const r = await firebase
        .firestore()
        .collection('tabletDeviceRemoteManagement')
        .doc(uid)
        .get();
      if (r.exists) {
        const settings = r.data() as Settings;
        setSettings(settings);
      }
      setIsLoading(false);
    };
    if (uid) {
      f();
    }
  }, [updatedAt, companyId, shopId, uid]);

  return (
    <>
      <Dialog className={classes.table} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">{`${companyId}-${shopId}-${uid}`}</DialogTitle>
        <DialogTitle id="scroll-dialog-title">
          targetは「BT:MACAddress」の形で指定してください
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">項目</TableCell>
                  <TableCell align="left">value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">targetName</TableCell>
                  <TableCell align="left">
                    <TextField
                      value={settings.printerConnectionTargetName}
                      onChange={e => {
                        const newTarget = e.target.value as string;
                        setSettings({
                          ...settings,
                          printerConnectionTargetName: newTarget,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">target</TableCell>
                  <TableCell align="left">
                    <TextField
                      value={settings.printerConnectionTarget}
                      onChange={e => {
                        const newTarget = e.target.value as string;
                        setSettings({
                          ...settings,
                          printerConnectionTarget: newTarget,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">drawerPassword</TableCell>
                  <TableCell align="left">
                    <TextField
                      value={settings.drawerPassword}
                      onChange={e => {
                        const newTarget = e.target.value as string;
                        setSettings({
                          ...settings,
                          drawerPassword: newTarget,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Button
                      disabled={!settings.printerConnectionTarget?.startsWith('BT')}
                      variant={'contained'}
                      onClick={saveTarget}
                    >
                      保存する
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
