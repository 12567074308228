import React from 'react';
import { Paper, TextField } from '@material-ui/core';

interface Props {
  setShop: (data: {}) => void;
}

// TODO: Shop作成
const ShopSetting: React.FC<Props> = ({ setShop }) => {
  return (
    <Paper style={{ padding: '10px', margin: '10px' }}>
      <h1>店舗情報の登録</h1>
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="shopName"
        label="店舗名"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShop({ name: ev.target.value });
        }}
      />
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="enShopName"
        label="店舗名(英)"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShop({ enName: ev.target.value });
        }}
      />
    </Paper>
  );
};

export default ShopSetting;
