import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import CreateServiceAdminDialog from './CreateServiceAdminDialog/CreateServiceAdminDialog';
import { ServiceAdmin } from '../../../entity/serviceAdmin.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const ServiceAdmins: React.FC = () => {
  const classes = useStyles();
  const [createServiceAdminModalOpen, setCreateServiceAdminModalOpen] = useState(false);

  const [serviceAdmins, setServiceAdmins] = useState<ServiceAdmin[]>([]);

  useEffect(() => {
    request
      .get('service_admins', {
        headers: { 'x-company-id': 1 },
      })
      .then(response => {
        setServiceAdmins(response.data);
      });
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateServiceAdminModalOpen(true)}
      >
        Create ServiceAdmin
      </Button>
      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ServiceAdmin ID</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceAdmins.map((serviceAdmin, index) => (
              <TableRow key={index}>
                <TableCell align="left">{serviceAdmin.serviceAdminId}</TableCell>
                <TableCell align="left">{serviceAdmin.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <CreateServiceAdminDialog
        isOpen={createServiceAdminModalOpen}
        onClose={() => setCreateServiceAdminModalOpen(false)}
      />
    </>
  );
};

export default ServiceAdmins;
