import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';
import { MenuTypeTypes } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateMenuTypeRequest {
  companyId: number;
  name: string;
  type: string;
  start?: string;
  end?: string;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateMenuTypeDialog: React.FC<Props> = ({ isOpen, onClose, shop }: Props) => {
  const classes = useStyles();
  const [createMenuTypeRequest, setCreateMenuTypeRequest] = useState({} as CreateMenuTypeRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createMenuType = () => {
    request
      .post(
        `shops/${shop.shopId}/menu_types`,
        {
          companyId: shop.companyId,
          ...createMenuTypeRequest,
        },
        {
          headers: { 'x-company-id': shop.companyId },
        },
      )
      .then(() => {
        setDialogMessage('update success');
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  const handleMenuTypeSetting = (typeValue: string) => {
    setCreateMenuTypeRequest({
      ...createMenuTypeRequest,
      type: typeValue,
    });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create MenuType</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="name"
                value={createMenuTypeRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuTypeRequest({ ...createMenuTypeRequest, name: ev.target.value })
                }
              />
              <List
                dense
                style={{
                  maxHeight: '100px',
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {Object.values(MenuTypeTypes).map((typeValue, typeIndex) => (
                  <ListItem key={typeIndex} button>
                    <ListItemText id={`${typeValue}`} primary={typeValue} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleMenuTypeSetting(typeValue)}
                        checked={createMenuTypeRequest.type === typeValue}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <TextField
                id="name-input"
                label="menuType start time(HH:mm)"
                value={createMenuTypeRequest.start}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuTypeRequest({
                    ...createMenuTypeRequest,
                    start: ev.target.value,
                  })
                }
              />
              <TextField
                id="name-input"
                label="menuType end time(HH:mm)"
                value={createMenuTypeRequest.end}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuTypeRequest({
                    ...createMenuTypeRequest,
                    end: ev.target.value,
                  })
                }
              />
              <div>
                <Button type="button" onClick={createMenuType}>
                  Apply
                </Button>
                <Button onClick={onClose}> close </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateMenuTypeDialog;
