// eslint-disable-next-line no-unused-vars
import React from 'react';
import PrinterLog from './deviceManagement/printerLog/PrinterLog';
import ConnectionStatus from './deviceManagement/connectionstatus/ConnectionStatus';
import DeviceStatus from './deviceManagement/devicestatus/DeviceStatus';
import Menus from './dataManagement/menus/Menus';
import ShopClients from './dataManagement/shopClients/ShopClients';
import Roles from './dataManagement/roles/Roles';
import Shops from './dataManagement/shops/Shops';
import Categories from './dataManagement/categories/Categories';
import Plans from './dataManagement/plans/Plans';
import Companies from './dataManagement/companies/Companies';
import Fees from './dataManagement/fees/Fees';
import Orders from './dataManagement/orders/Orders';
import TableUsers from './dataManagement/tableUsers/TableUsers';
import DishUps from './dataManagement/dishUps/dishUps';
import OnBoarding from './onBoarding/OnBoarding';
import OldDeviceStatus from './deviceManagement/old-devicestatus/OldDeviceStatus';
import OldConnectionStatus from './deviceManagement/old-connectionstatus/OldConnectionStatus';
import Pos from './dataManagement/pos/Pos';
import { TabletDeviceStatus } from './deviceManagement/tabletdevicestatus/TabletDeviceStatus';
import Clerks from './dataManagement/clerks/Clerks';
import ServiceAdmins from './dataManagement/serviceAdmins/ServiceAdmins';
import ShopAdmins from './dataManagement/shopAdmins/ShopAdmins';
import { DishUpReceiptGroups } from './dataManagement/dishUpReceiptGroups/DishUpReceiptGroups';
import { AggregationSummary } from './dataManagement/aggregationSummary/AggregationSummary';
import { PrintTargets } from './deviceManagement/printTargets/PrintTargets';
import { PosPollingMonitoring } from './posManagement/PosPollingMonitoring';
import { PosPollingOrderMonitoring } from './posManagement/PosPollingOrderMonitoring';

export interface Route {
  title: string;
  path: string;
  component: React.FC;
}

export const PrinterLogRoute: Route = {
  title: 'プリンター監視',
  path: '/printer_monitoring',
  component: PrinterLog,
};

export const DeviceStatusLogRoute: Route = {
  title: '端末状態監視',
  path: '/',
  component: DeviceStatus,
};

export const TabletDeviceStatusLogRoute: Route = {
  title: 'タブレット状態監視',
  path: '/tablet_device_status',
  component: TabletDeviceStatus,
};

export const AggregationSummaryRoute: Route = {
  title: '集計サマリ',
  path: '/aggregation_summary',
  component: AggregationSummary,
};

export const DeviceConnectionLogRoute: Route = {
  title: '接続状態監視',
  path: '/device_connection_status',
  component: ConnectionStatus,
};

export const MenusRoute: Route = {
  title: 'Menus',
  path: '/menus',
  component: Menus,
};

export const TablesRoute: Route = {
  title: 'Tables',
  path: '/tables',
  component: Menus,
};

export const ShopClientsRoute: Route = {
  title: 'ShopClients',
  path: '/shopclients',
  component: ShopClients,
};

export const RolesRoute: Route = {
  title: 'Roles and MenuTypes',
  path: '/roles',
  component: Roles,
};

export const ShopsRoute: Route = {
  title: 'Shops',
  path: '/shops',
  component: Shops,
};

export const ClerksRoute: Route = {
  title: 'Clerks',
  path: '/clerks',
  component: Clerks,
};

export const CategoriesRoute: Route = {
  title: 'Categories',
  path: '/categories',
  component: Categories,
};

export const PlansRoute: Route = {
  title: 'Plans',
  path: '/plans',
  component: Plans,
};

export const CompaniesRoute: Route = {
  title: 'Companies',
  path: '/companies',
  component: Companies,
};

export const FeesRoute: Route = {
  title: 'Fees',
  path: '/fees',
  component: Fees,
};

export const OrdersRoute: Route = {
  title: 'Orders',
  path: '/orders',
  component: Orders,
};

export const TableUsersRoute: Route = {
  title: 'TableUsers',
  path: '/tableUsers',
  component: TableUsers,
};

export const DishUpsRoute: Route = {
  title: 'DishUps',
  path: '/dishUps',
  component: DishUps,
};

export const DishUpReceiptGroupRoute: Route = {
  title: 'デシャップグループ',
  path: '/dishUpReceiptGroups',
  component: DishUpReceiptGroups,
};

export const StatsRoute: Route = {
  title: 'Stats',
  path: '/stats',
  component: DishUps,
};

export const OnBoardingRoute: Route = {
  title: 'OnBoarding',
  path: '/onBoarding',
  component: OnBoarding,
};

export const ServiceAdminsRoute: Route = {
  title: 'ServiceAdmins',
  path: '/service_admins',
  component: ServiceAdmins,
};

export const ShopAdminsRoute: Route = {
  title: 'ShopAdmins',
  path: '/shop_admins',
  component: ShopAdmins,
};

export const OldDeviceStatusLogRoute: Route = {
  title: '[旧]端末状態監視',
  path: '/old_device_status',
  component: OldDeviceStatus,
};

export const OldDeviceConnectionLogRoute: Route = {
  title: '[旧]接続状態監視',
  path: '/old_device_connection_status',
  component: OldConnectionStatus,
};

export const PosRoute: Route = {
  title: 'POS連携設定',
  path: '/pos',
  component: Pos,
};

export const PrintTargetsRoute: Route = {
  title: '印刷基盤監視',
  path: '/print-targets',
  component: PrintTargets,
};

export const PosPollingMonitoringRoute: Route = {
  title: 'POS卓ポーリング監視',
  path: '/pos_polling',
  component: PosPollingMonitoring,
};

export const PosPollingOrderMonitoringRoute: Route = {
  title: 'POS注文ポーリング監視',
  path: '/pos_order_polling',
  component: PosPollingOrderMonitoring,
};

export const router = [
  DeviceStatusLogRoute,
  DeviceConnectionLogRoute,
  TabletDeviceStatusLogRoute,
  PrinterLogRoute,
  PrintTargetsRoute,
  OnBoardingRoute,
  // AggregationSummaryRoute,
  CompaniesRoute,
  ShopsRoute,
  ClerksRoute,
  TablesRoute,
  MenusRoute,
  ShopClientsRoute,
  RolesRoute,
  DishUpReceiptGroupRoute,
  CategoriesRoute,
  PlansRoute,
  FeesRoute,
  OrdersRoute,
  TableUsersRoute,
  DishUpsRoute,
  StatsRoute,
  ServiceAdminsRoute,
  ShopAdminsRoute,
  OldDeviceStatusLogRoute,
  OldDeviceConnectionLogRoute,
  PosRoute,
  PosPollingMonitoringRoute,
  PosPollingOrderMonitoringRoute,
];
