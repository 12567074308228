import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import ApiResponseDialogComponent from '../components/apiResponseDialog';
import { Clerk } from '../../../entity/clerk.entity';
import CreateClerkDialog from './CreateClerkDialog/CreateClerkDialog';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Clerks: React.FC = () => {
  const classes = useStyles();
  const [clerks, setClerks] = useState<Clerk[]>([]);

  const { selectedShop } = useShops();

  // edit row flags
  const [editNameIndex, setEditNameIndex] = useState<number | null>(null);
  // edit data
  const [editedName, setEditedName] = useState<string | null>(null);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState<string | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const cancelEdit = () => {
    setEditNameIndex(null);
    setEditedName(null);
  };

  useEffect(() => {
    if (selectedShop) {
      request
        .get(`shops/${selectedShop.shopId}/clerks`, {
          headers: { 'x-company-id': selectedShop.companyId },
        })
        .then(response => {
          setClerks(response.data);
        });
    }
  }, [selectedShop]);

  const applyChange = (editedIndex: number) => {
    if (selectedShop) {
      if (!editedName) {
        window.alert('名前を入力してください');
        return;
      }
      const targetClerk = clerks[editedIndex];
      const updateValueBody = {
        name: editedName || undefined,
      };
      request
        .post(`shops/${selectedShop.shopId}/clerks/${targetClerk.clerkId}`, updateValueBody, {
          headers: { 'x-company-id': selectedShop.companyId },
        })
        .then(response => {
          setClerks(
            clerks.map(clerk => {
              if (response.data.clerkId === clerk.clerkId) {
                return response.data;
              }
              return clerk;
            }),
          );
          setDialogMessage('update success');
        })
        .catch(err => setDialogMessage(JSON.stringify(err)));
    }
    cancelEdit();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateModalOpen(true)}
      >
        Create clerk
      </Button>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Clerk ID</TableCell>
              <TableCell align="right">Clerk Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clerks.map((clerk, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {selectedShop?.companyId}
                </TableCell>
                <TableCell align="right">{selectedShop?.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">{clerk.clerkId}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: clerk.name,
                    startEdit: () => {
                      setEditNameIndex(index);
                      setEditedName(clerk.name);
                    },
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedName(val),
                    isShowEditForm: editNameIndex === index,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ApiResponseDialogComponent
        dialogMessage={dialogMessage}
        resetDialogMessage={() => setDialogMessage(null)}
      />
      {selectedShop && (
        <CreateClerkDialog
          shop={selectedShop}
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        />
      )}
    </>
  );
};

export default Clerks;
