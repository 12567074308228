import React, { useState, useCallback } from 'react';
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import firebase from '../../plugins/firebase';
import moment from 'moment-timezone';
import { useShops } from '../../hooks/useShops';
import request from '../../plugins/api';
import { TableData } from '../../entity/table.entity';
import PosPollingLogModal from './PosPollingLogModal/PosPollingLogModal';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface PosPollingLog {
  shopId: string;
  tableId: number;
  posTableId: string;
  payload: string;
  loggedAt: number;
  pollingState: string;
}

const formatDate = (dateUnix: number) => moment(dateUnix).format('YYYY-MM-DD HH:mm:ss');

export const PosPollingMonitoring: React.FC = () => {
  const classes = useStyles();

  const { selectedShop } = useShops();
  const [pollingLogs, setPollingLogs] = useState<PosPollingLog[]>([]);
  const [tables, setTables] = useState<TableData[]>([]);
  const [selectedTable, setSelectedTable] = useState<TableData | undefined>(undefined);
  const [isOpenDetailedModal, setIsOpenDetailedModal] = useState(false);

  const fetchLogs = useCallback(async (companyId: number, shopId: string) => {
    const res = await request.get<TableData[]>(`shops/${shopId}/tables`, {
      headers: { 'x-company-id': companyId },
    });
    const tables = res.data;
    setTables(tables);

    setPollingLogs(
      tables.map(t => ({
        shopId: t.shopId,
        tableId: t.tableId,
        posTableId: t.posTableId ?? '0',
        payload: '',
        loggedAt: 0,
        pollingState: 'init',
      })),
    );

    const tableData = await Promise.all(
      tables.flatMap(async table => {
        const snapshots = await firebase
          .database()
          .ref(`pos/pollingLogs/syncTable/${shopId}/${table.tableId}`)
          .orderByChild('loggedAt')
          .limitToLast(1)
          .once('value');
        if (!snapshots.exists()) {
          return;
        } else {
          const key = Object.keys(snapshots.val())[0];
          return snapshots.val()[key];
        }
      }),
    );

    setPollingLogs(
      tables.map(t => {
        const p = tableData.find(td => td?.tableId === t.tableId);
        if (typeof p !== 'undefined') {
          return p;
        } else {
          return {
            shopId: t.shopId,
            tableId: t.tableId,
            posTableId: t.posTableId ?? '0',
            payload: '',
            loggedAt: 0,
            pollingState: 'init',
          };
        }
      }) as PosPollingLog[],
    );
  }, []);

  const tablePolling = async (companyId: number, shopId: string, tableId: number) => {
    await request.get('pos_polling/sync_one_table', {
      params: { companyId: companyId, shopId: shopId, tableId: tableId },
      headers: { 'x-company-id': companyId },
    });
  };

  return (
    <>
      {selectedShop ? (
        <>
          <Button onClick={() => fetchLogs(selectedShop.companyId, selectedShop.shopId)}>
            load
          </Button>

          <Paper>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Shop ID</TableCell>
                  <TableCell align="right">Shop Name</TableCell>
                  <TableCell align="right">loggedAt</TableCell>
                  <TableCell align="right">tableId</TableCell>
                  <TableCell align="right">posTableId</TableCell>
                  <TableCell align="right">Log Type</TableCell>
                  <TableCell align="right">Polling</TableCell>
                  <TableCell align="right">payload</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pollingLogs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell align="right">{log.shopId}</TableCell>
                    <TableCell align="right">{selectedShop?.name}</TableCell>
                    <TableCell align="right">{formatDate(log.loggedAt)}</TableCell>
                    <TableCell align="right">{log.tableId}</TableCell>
                    <TableCell align="right">{log.posTableId}</TableCell>
                    <TableCell align="right">{log.pollingState}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() =>
                          tablePolling(selectedShop.companyId, selectedShop.shopId, log.tableId)
                        }
                      >
                        Polling
                      </Button>
                    </TableCell>
                    <TableCell align="right">{log.payload}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          const t = tables.find(tab => tab.tableId === log.tableId);
                          if (t) {
                            setSelectedTable(t);
                            setIsOpenDetailedModal(true);
                          }
                        }}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : null}
      {selectedTable && (
        <PosPollingLogModal
          isOpen={isOpenDetailedModal}
          onClose={() => setIsOpenDetailedModal(false)}
          table={selectedTable}
          logType="table"
        />
      )}
    </>
  );
};
