import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';

const shopRoles = ['shop', 'shop_admin', 'shop_stripe'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateShopClientRequest {
  companyId: number;
  email: string;
  password: string;
  name: string;
  roles: string[];
  description?: string;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateShopClientDialog: React.FC<Props> = ({ shop, isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [createShopClientRequest, setCreateShopClientRequest] = useState({
    roles: [] as string[],
  } as CreateShopClientRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const setShopRole = (roleName: string) => {
    if (createShopClientRequest.roles.includes(roleName)) {
      setCreateShopClientRequest(
        Object.assign(createShopClientRequest, {
          roles: createShopClientRequest.roles.filter(r => r !== roleName),
        }),
      );
    } else {
      setCreateShopClientRequest({
        ...createShopClientRequest,
        roles: [...createShopClientRequest.roles, roleName],
      });
    }
  };

  const createShopClient = () => {
    request
      .post(
        `shops/${shop.shopId}/shop_clients`,
        { companyId: shop.companyId, ...createShopClientRequest },
        {
          headers: { 'x-company-id': shop.companyId },
        },
      )
      .then(() => {
        setDialogMessage('update success');
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Shop</DialogTitle>
        <DialogContent>
          <div>
            <p>companyId: {shop.companyId}</p>
            <p>shopId: {shop.shopId}</p>
            <p>shopName: {shop.name}</p>
          </div>
          {JSON.stringify(createShopClientRequest)}
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="Name"
                value={createShopClientRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateShopClientRequest({
                    ...createShopClientRequest,
                    name: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="Email"
                value={createShopClientRequest.email}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateShopClientRequest({
                    ...createShopClientRequest,
                    email: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="Password"
                value={createShopClientRequest.password}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateShopClientRequest({
                    ...createShopClientRequest,
                    password: ev.target.value,
                  })
                }
              />
              <List
                dense
                style={{
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {shopRoles.map((roleName, roleIndex) => (
                  <ListItem key={roleIndex} button>
                    <ListItemText id={`${roleName}-${roleIndex}`} primary={roleName} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => setShopRole(roleName)}
                        checked={createShopClientRequest.roles.includes(roleName)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
            <Button onClick={createShopClient}>send</Button>
            <Button onClick={onClose}>close</Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateShopClientDialog;
