import axiosBase from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';

export const getBaseURL = () => {
  switch (process.env.REACT_APP_BACKEND_ENV) {
    case 'staging0':
      return 'https://api.staging0.self.priv.dinii.jp';
    case 'staging1':
      return 'https://api.staging1.self.priv.dinii.jp';
    case 'local':
      return 'http://localhost:8080';
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};

const request = axiosBase.create({
  baseURL: getBaseURL(),
});

request.interceptors.request.use(async config => {
  const user = firebase.auth().currentUser;
  if (!user) throw new Error('user not login');
  // eslint-disable-next-line no-param-reassign
  config.headers['x-auth-key'] = await user.getIdToken();
  return config;
});

export default request;
