import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
  DialogActions,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import firebase from '../../../plugins/firebase';
import { TableData } from '../../../entity/table.entity';
import moment from 'moment-timezone';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  table: TableData;
  logType: 'table' | 'order';
}

interface PosPollingLog {
  shopId: string;
  tableId: number;
  posTableId: number;
  payload: string;
  loggedAt: number;
  pollingState: string;
}

const formatDate = (dateUnix: number) => moment(dateUnix).format('YYYY-MM-DD HH:mm:ss');

const PosPollingLogModal: React.FC<Props> = ({ isOpen, onClose, table, logType }: Props) => {
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [logs, setLogs] = useState<PosPollingLog[]>([]);
  const [getLastNum, setGetLastNum] = useState(100);

  useEffect(() => {
    (async () => {
      const refName = logType === 'order' ? 'syncOrder' : 'syncTable';
      const snapshots = await firebase
        .database()
        .ref(`pos/pollingLogs/${refName}/${table.shopId}/${table.tableId}`)
        .orderByChild('loggedAt')
        .limitToLast(getLastNum)
        .once('value');

      if (snapshots.exists()) {
        setLogs(
          (Object.values(snapshots.val()) as PosPollingLog[]).sort((a, b) =>
            a.loggedAt > b.loggedAt ? -1 : 0,
          ),
        );
      }
    })();
  }, [getLastNum, logType, table.shopId, table.tableId]);

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Polling Logs</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="name-input"
            label="取得数"
            value={getLastNum}
            variant="filled"
            type="number"
            onChange={ev => setGetLastNum(Number(ev.target.value))}
          />
          <Button onClick={() => onClose()}>閉じる</Button>

          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Shop ID</TableCell>
                <TableCell align="right">loggedAt</TableCell>
                <TableCell align="right">tableId</TableCell>
                <TableCell align="right">posTableId</TableCell>
                <TableCell align="right">Log Type</TableCell>
                <TableCell align="right">payload</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell align="right">{log.shopId}</TableCell>
                  <TableCell align="right">{formatDate(log.loggedAt)}</TableCell>
                  <TableCell align="right">{log.tableId}</TableCell>
                  <TableCell align="right">{log.posTableId}</TableCell>
                  <TableCell align="right">{log.pollingState}</TableCell>
                  <TableCell align="right">{log.payload}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default PosPollingLogModal;
