import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import firebase from '../../../plugins/firebase';
import DeviceLogsDialog from './DeviceLogsDialog/DeviceLogsDialog';
import moment from 'moment-timezone';
import { TabletSettingsDialog } from './TabletSettingsDialog/TabletSettingsDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface TabletDeviceStatus {
  shopName: string;
  lastClosedAt?: any;
  lastUpdatedAt?: any;
  lastReservedAt?: any;
  lastUpdatedType: string;
  lastRebootedAt?: any;
  lastPrinterStatus?: string;
  last: string;
  companyId: string;
  shopId: string;
  uid: string;
  version: string;
}

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm');

const isToday = (date: Date) => {
  const now = moment
    .tz('Asia/Tokyo')
    .hour(0)
    .minute(0)
    .second(0);
  return moment(date).diff(now) > 0;
};

export const TabletDeviceStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as TabletDeviceStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as TabletDeviceStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [printerSettingsDialog, setPrinterSettingsDialog] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('tabletDeviceStatus')
      .orderBy('lastUpdatedAt', 'desc')
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logs = snapshot.docs
            .map(value => value.data() as TabletDeviceStatus)
            .filter(d => d.uid !== undefined);
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  const ref = useMemo(() => firebase.firestore().collection('tabletDeviceRemoteManagement'), []);

  const softReboot = (uid: string) => {
    return sendCommand(uid, 'softReboot');
  };

  const reboot = (uid: string) => {
    return sendCommand(uid, 'reboot');
  };

  const sendCommand = async (uid: string, command: string) => {
    if (window.confirm(`send ${command} to ${uid}?`)) {
      await ref.doc(uid).set({ command: 'none' }, { merge: true });
      setTimeout(async () => {
        await ref.doc(uid).set({ command }, { merge: true });
      }, 2000);
    } else {
      window.alert('キャンセルされました'); // 警告ダイアログを表示
    }
  };

  return (
    <>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">UID</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
              <TableCell align="right">Last printer status</TableCell>
              <TableCell align="right">Last rebooted at</TableCell>
              <TableCell align="right">Last reserved at</TableCell>
              <TableCell align="right">Last closed at</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">{status.shopName}</TableCell>
                <TableCell align="right">{status.uid}</TableCell>
                <TableCell align="right">
                  {moment(status.lastUpdatedAt.toDate()).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
                <TableCell align="right">{status.lastPrinterStatus}</TableCell>
                <TableCell
                  className={clsx(
                    status.lastRebootedAt && isToday(status.lastRebootedAt.toDate())
                      ? classes.okCell
                      : classes.ngCell,
                  )}
                  align="right"
                >
                  {status.lastRebootedAt && formatDate(status.lastRebootedAt.toDate())}
                </TableCell>
                <TableCell
                  className={clsx(
                    status.lastReservedAt && isToday(status.lastReservedAt.toDate())
                      ? classes.okCell
                      : classes.ngCell,
                  )}
                  align="right"
                >
                  {status.lastReservedAt && formatDate(status.lastReservedAt.toDate())}
                </TableCell>
                <TableCell
                  className={clsx(
                    status.lastClosedAt && isToday(status.lastClosedAt.toDate())
                      ? classes.okCell
                      : classes.ngCell,
                  )}
                  align="right"
                >
                  {status.lastClosedAt && formatDate(status.lastClosedAt.toDate())}
                </TableCell>
                <TableCell>
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      setSelectedStatus(status);
                      setLogModalOpen(true);
                    }}
                  >
                    ログを表示する
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => sendCommand(status.uid, 'pingPrinter')}
                  >
                    プリンターping
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      setSelectedStatus(status);
                      setPrinterSettingsDialog(true);
                    }}
                  >
                    設定
                  </Button>
                  <Button variant={'contained'} onClick={() => softReboot(status.uid)}>
                    アプリ再起動
                  </Button>
                  <Button variant={'contained'} onClick={() => reboot(status.uid)}>
                    端末再起動
                  </Button>
                </TableCell>
                <TableCell align="left">{status.version}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <DeviceLogsDialog
        isOpen={logModalOpen}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        uid={selectedStatus.uid}
        onClose={() => setLogModalOpen(false)}
      />
      <TabletSettingsDialog
        isOpen={printerSettingsDialog}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        uid={selectedStatus.uid}
        onClose={() => setPrinterSettingsDialog(false)}
      />
    </>
  );
};
