import React, { useState, useEffect } from 'react';
import { EmergencyRoleIdReplacement } from '../entity/emergencyRoleIdReplacement';
import request from '../plugins/api';
import { Shop } from '../entity/shop.entity';
import { Role } from '../entity/role.entity';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Select,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useShops } from '../hooks/useShops';

const findCompanyIdFromShopId = (shopId: string, shops: Shop[]): number => {
  const found = shops.find(shop => shop.shopId === shopId);
  if (found) {
    return found.companyId;
  } else {
    throw new Error(`shop not found ${shopId}`);
  }
};

const findShopNameById = (shopId: string, shops: Shop[]): string => {
  const found = shops.find(shop => shop.shopId === shopId);
  if (found) {
    return found.name;
  } else {
    return 'no-name';
  }
};

const findRoleNameById = (roleId: number, roles: Role[]): string => {
  const found = roles.find(role => role.roleId === roleId);
  if (found) {
    return found.name;
  } else {
    return 'no-name';
  }
};

const stopReplacementRequest = async (
  companyId: number,
  shopId: string,
  roleId: number,
): Promise<string> => {
  try {
    await request.get(`emergency_role_replacement/${shopId}/roles/${roleId}/stop`, {
      headers: { 'x-company-id': companyId },
    });
    return 'ok';
  } catch (e) {
    return `error: ${e}`;
  }
};

const startReplacementRequest = async (
  companyId: number,
  shopId: string,
  roleId: number,
  replaceToRoleId: number,
): Promise<string> => {
  try {
    await request.post(
      `emergency_role_replacement/${shopId}/roles/${roleId}`,
      { replaceTo: replaceToRoleId },
      { headers: { 'x-company-id': companyId } },
    );
    return 'ok';
  } catch (e) {
    return `error: ${e}`;
  }
};

const EmergencyPrintBackup: React.FC = () => {
  const [emergencyRoleIdReplacement, setEmergencyRoleIdReplacement] = useState(
    [] as EmergencyRoleIdReplacement[],
  );
  const [roles, setRoles] = useState([] as Role[]);

  const { shops, companies, setSelectedShop, selectedShop } = useShops();

  useEffect(() => {
    shops.forEach(shop => {
      if (!shop.enableAlert) {
        return;
      }
      request
        .get(`emergency_role_replacement/${shop.shopId}`, {
          headers: { 'x-company-id': shop.companyId },
        })
        .then(ret => {
          setEmergencyRoleIdReplacement(prev => [
            ...prev,
            ...(ret.data as EmergencyRoleIdReplacement[]),
          ]);
        });
      request
        .get(`shops/${shop.shopId}/roles`, {
          headers: { 'x-company-id': shop.companyId },
        })
        .then(ret => {
          setRoles(prev => [...prev, ...(ret.data as Role[])]);
        });
    });
  }, [shops]);

  const [responseMessage, setResponseMessage] = useState(null as null | string);
  const [openReplacementDialog, setOpenReplacementDialog] = useState(false);
  const [fromRole, setFromRole] = useState(null as Role | null);
  const [toRole, setToRole] = useState(null as Role | null);

  const reloadData = () => {
    setEmergencyRoleIdReplacement([]);
    shops.forEach(shop => {
      request
        .get(`emergency_role_replacement/${shop.shopId}`, {
          headers: { 'x-company-id': shop.companyId },
        })
        .then(ret => {
          setEmergencyRoleIdReplacement(prev => [
            ...prev,
            ...(ret.data as EmergencyRoleIdReplacement[]),
          ]);
        });
    });
  };

  const onClickCancelButton = (shopId: string, roleId: number) => {
    const result = window.confirm('緊急伝票振り分け設定を解除します。よろしいですか？');
    if (!result) {
      return;
    }
    const companyId = findCompanyIdFromShopId(shopId, shops);
    stopReplacementRequest(companyId, shopId, roleId).then(ret => {
      setResponseMessage(ret);
      reloadData();
    });
  };

  const onClickStartButton = () => {
    if (!selectedShop || !fromRole || !toRole) {
      return;
    }
    const result = window.confirm('緊急伝票振り分け設定を適用します。よろしいですか？');
    if (!result) {
      return;
    }
    const shopId = selectedShop.shopId;
    const companyId = findCompanyIdFromShopId(shopId, shops);
    startReplacementRequest(companyId, shopId, fromRole.roleId, toRole.roleId).then(ret => {
      setResponseMessage(ret);
      reloadData();
      setOpenReplacementDialog(false);
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedShop(null);
    const selectedShopId = event.target.value as string;
    const shop = shops.find(mShop => mShop.shopId === selectedShopId);
    if (!shop) return;
    const company = companies.find(mCompany => mCompany.companyId === shop.companyId);
    if (!company) return;
    setSelectedShop(shop);
  };

  return (
    <div style={{ width: '80%', height: '300px', marginTop: '40px', overflowY: 'auto' }}>
      <div style={{ textAlign: 'center' }}>
        <h3>
          緊急伝票振替状況
          <Button
            style={{ marginLeft: '30px' }}
            color="primary"
            variant="contained"
            onClick={() => setOpenReplacementDialog(true)}
          >
            設定追加
          </Button>
        </h3>
      </div>
      <Grid
        container
        spacing={3}
        style={{ background: '#dd6666', color: 'white', fontWeight: 'bold' }}
      >
        <Grid item xs={12} sm={3}>
          CreatedAt
        </Grid>
        <Grid item xs={12} sm={3}>
          shopId
        </Grid>
        <Grid item xs={12} sm={2}>
          roleId
        </Grid>
        <Grid item xs={12} sm={2}>
          repalceTo
        </Grid>
        <Grid item xs={12} sm={1}>
          cancel
        </Grid>
      </Grid>
      {emergencyRoleIdReplacement.length > 0 &&
        emergencyRoleIdReplacement.map(em => (
          <Grid key={em.createdAt} container spacing={3}>
            <Grid item xs={12} sm={3}>
              {moment(em.createdAt)
                .tz('Asia/Tokyo')
                .format('YYYY/MM/DD HH:mm:ss')}
            </Grid>
            <Grid item xs={12} sm={3}>
              {em.shopId}/{findShopNameById(em.shopId, shops)}
            </Grid>
            <Grid item xs={12} sm={2}>
              {em.roleId}/{findRoleNameById(em.roleId, roles)}
            </Grid>
            <Grid item xs={12} sm={2}>
              {em.replaceToRoleId}/{findRoleNameById(em.replaceToRoleId, roles)}
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                onClick={() => onClickCancelButton(em.shopId, em.roleId)}
                color="secondary"
                variant="contained"
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        ))}
      <Dialog
        open={responseMessage !== null}
        onClose={() => setResponseMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{responseMessage}</DialogTitle>
      </Dialog>
      <Dialog
        open={openReplacementDialog}
        onClose={() => setOpenReplacementDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">振替設定</DialogTitle>
        <FormControl style={{ width: '80vw' }}>
          <InputLabel id="demo-simple-select-label">CompanyId/ShopName selection</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedShop && selectedShop.shopId}
            onChange={handleChange}
          >
            {shops.map(shop => (
              <MenuItem
                value={shop.shopId}
                key={shop.shopId}
              >{`${shop.companyId}/${shop.name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedShop && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <div style={{ textAlign: 'center' }}>
                  <h3>from</h3>
                </div>
                <List dense>
                  {roles
                    .filter(r => r.shopId === selectedShop.shopId)
                    .map((role, roleIndex) => (
                      <ListItem key={roleIndex} button>
                        <ListItemText id={`${role.roleId}`} primary={role.name} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            disabled={toRole?.roleId === role.roleId}
                            onChange={() => setFromRole(role)}
                            checked={fromRole?.roleId === role.roleId}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={1}>
                <div style={{ textAlign: 'center' }}>
                  <h3>→</h3>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div style={{ textAlign: 'center' }}>
                  <h3>to</h3>
                </div>
                <List dense>
                  {roles
                    .filter(r => r.shopId === selectedShop.shopId)
                    .map((role, roleIndex) => (
                      <ListItem key={roleIndex} button>
                        <ListItemText id={`${role.roleId}`} primary={role.name} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            disabled={fromRole?.roleId === role.roleId}
                            onChange={() => setToRole(role)}
                            checked={toRole?.roleId === role.roleId}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={() => onClickStartButton()}>
              適用する
            </Button>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default EmergencyPrintBackup;
