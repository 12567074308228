import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreatePlanGroupRequest {
  companyId: number;
  name: string;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreatePlanGroupDialog: React.FC<Props> = ({ isOpen, onClose, shop }: Props) => {
  const classes = useStyles();

  const [createPlanGroupRequest, setCreatePlanGroupRequest] = useState(
    {} as CreatePlanGroupRequest,
  );
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createPlanGroup = () => {
    request
      .post(
        `shops/${shop.shopId}/planGroups`,
        {
          companyId: shop.companyId,
          ...createPlanGroupRequest,
        },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(() => {
        setDialogMessage('create success');
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create PlanGroup</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="name"
                value={createPlanGroupRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanGroupRequest({ ...createPlanGroupRequest, name: ev.target.value })
                }
              />
            </div>

            <div>
              <Button type="button" color="primary" onClick={createPlanGroup}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreatePlanGroupDialog;
