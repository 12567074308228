import { Button } from '@material-ui/core';
import React from 'react';

interface Props {
  disabled: boolean;
  currentPageIndex: number;
  updatePageIndex: (pageIndex: number) => void;
  dataSize: number;
}

const pagingComponent = ({ disabled, currentPageIndex, updatePageIndex, dataSize }: Props) => (
  <div>
    <Button
      color="primary"
      disabled={disabled}
      onClick={() =>
        updatePageIndex(currentPageIndex > 1 ? currentPageIndex - 1 : currentPageIndex)
      }
    >
      prev page
    </Button>
    <span>
      現在ページ{`${currentPageIndex}`}/{`${Math.floor(dataSize / 50) + 1}`}
    </span>
    <Button
      color="primary"
      disabled={disabled}
      onClick={() => updatePageIndex(currentPageIndex + 1)}
    >
      next page
    </Button>
  </div>
);

export default pagingComponent;
