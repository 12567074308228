export const firestoreVersionCode = 2;

export enum PaymentType {
  ALL = 'all',
  EACH = 'each',
}

export enum MenuTypeTypes {
  FOOD = 'food',
  DRINK = 'drink',
  SPECIAL = 'special',
  APPETIZER = 'appetizer',
}

export enum PaymentMethod {
  CASH = 'cash',
  ON_SITE = 'onSite',
  CARD = 'card',
  LINE = 'line',
}

export enum TableUserState {
  Seated = 'seated',
  Active = 'active',
  Paying = 'paying', // TODO: remove after paymentMethod migration
  TableCheckRequested = 'tableCheckRequested',
  TableCheckInProgress = 'tableCheckInProgress',
  RegisterCheckInProgress = 'registerCheckInProgress',
  Inactive = 'inactive',
}
