import { createContext } from 'react';
import { Company } from '../entity/company.entity';
import { Shop } from '../entity/shop.entity';

export interface MainContextInterface {
  companies: Company[];
  shops: Shop[];
  selectedShop: Shop | null;
  isInitialized: boolean;
}

export const initialMainContext: MainContextInterface = {
  companies: [],
  shops: [],
  selectedShop: null,
  isInitialized: false,
};

export const MainContext = createContext<
  [MainContextInterface, (state: MainContextInterface) => void]
>([initialMainContext, () => {}]);

export const MainContextProvider = MainContext.Provider;
