import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import request from '../../../plugins/api';
import { useShops } from '../../../hooks/useShops';
import { DishUpReceiptGroup } from '../../../entity/dishUpReceiptGroup.entity';
import { Role } from '../../../entity/role.entity';
import { Menu } from '../../../entity/menu.entity';
import { Category } from '../../../entity/category.entity';
import { CreateDishUpReceiptGroupModal } from './CreateDishUpReceiptGroupModal';
import { Plan } from '../../../entity/plan.entity';

export const DishUpReceiptGroups: React.FC = () => {
  const { selectedShop } = useShops();
  const [dishUpReceiptGroups, setDishUpReceiptGroups] = useState<DishUpReceiptGroup[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<DishUpReceiptGroup | undefined>(undefined);

  useEffect(() => {
    if (!selectedShop) return;

    const f = async () => {
      await Promise.all([
        request
          .get(`shops/${selectedShop.shopId}/dish_up_receipt_groups`, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(response => {
            setDishUpReceiptGroups(response.data);
          }),
        request
          .get(`shops/${selectedShop.shopId}/roles`, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(response => {
            setRoles(response.data);
          }),
        request
          .get(`shops/${selectedShop.shopId}/menus`, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(response => {
            setMenus(response.data);
          }),
        request
          .get(`shops/${selectedShop.shopId}/plans`, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(response => {
            setPlans(response.data);
          }),
        request
          .get(`shops/${selectedShop.shopId}/categories`, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(response => {
            setCategories(response.data);
          }),
      ]);
    };

    f();
  }, [selectedShop]);

  const refresh = useCallback(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/dish_up_receipt_groups`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setDishUpReceiptGroups(response.data);
      });
  }, [selectedShop]);

  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <>
      <Button
        onClick={() => setDialogVisible(true)}
        style={{ margin: 30 }}
        color={'primary'}
        variant={'contained'}
        fullWidth
      >
        新規作成
      </Button>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell align="right">名称</TableCell>
            <TableCell align="right">roles</TableCell>
            <TableCell align="right">menus</TableCell>
            <TableCell align="right">plans</TableCell>
            <TableCell align="right">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dishUpReceiptGroups.map(group => (
            <TableRow key={group.dishUpReceiptGroupId}>
              <TableCell>{group.dishUpReceiptGroupId}</TableCell>
              <TableCell align="right">{group.name}</TableCell>
              <TableCell align="right">
                {group.roles.map(role => (
                  <Typography key={role.roleId}>{role.name}</Typography>
                ))}
              </TableCell>
              <TableCell align="right">
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {group.menus.map(menu => (
                    <Typography key={menu.menuId}>{menu.name}</Typography>
                  ))}
                </div>
              </TableCell>
              <TableCell align="right">
                {group.plans?.map(plan => (
                  <Typography key={plan.planId}>{plan.receiptDisplayName}</Typography>
                ))}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    setSelectedGroup(group);
                    setDialogVisible(true);
                  }}
                >
                  修正
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedShop ? (
        <CreateDishUpReceiptGroupModal
          shop={selectedShop}
          visible={dialogVisible}
          onDismiss={() => {
            setDialogVisible(false);
            setSelectedGroup(undefined);
          }}
          roles={roles}
          menus={menus}
          plans={plans}
          categories={categories}
          refresh={refresh}
          dishUpReceiptGroup={selectedGroup}
        />
      ) : null}
    </>
  );
};
