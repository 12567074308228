import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';
import { Category } from '../../../../entity/category.entity';
import { MenuType } from '../../../../entity/menuType.entity';
import { Role } from '../../../../entity/role.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateMenuRequest {
  companyId: number;
  name: string;
  nameEn: string;
  receiptDisplayName: string;
  price: number;
  priority: number;
  categoryId: number;
  menuTypeId: number;
  imageUrl?: string;
  description?: string;
  descriptionEn?: string;
  roleIds: number[];
  maxNum: number;
  orderMaxNum: number;
}

interface Props {
  categories: Category[];
  menuTypes: MenuType[];
  roles: Role[];
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateMenuDialog: React.FC<Props> = ({
  roles,
  categories,
  menuTypes,
  isOpen,
  onClose,
  shop,
}: Props) => {
  const classes = useStyles();
  const [createMenuRequest, setCreateMenuRequest] = useState({
    roleIds: [] as number[],
    maxNum: -1,
    orderMaxNum: -1,
  } as CreateMenuRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createMenu = () => {
    request
      .post(
        `shops/${shop.shopId}/menus`,
        {
          companyId: shop.companyId,
          ...createMenuRequest,
        },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(() => {
        setDialogMessage('update success');
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  const handleRoleIdsSetting = (roleId: number) => {
    const currentRoleIds = createMenuRequest.roleIds;
    let updatedRoleIds = [] as number[];
    if (currentRoleIds.includes(roleId)) {
      updatedRoleIds = currentRoleIds.filter(r => r !== roleId);
    } else {
      updatedRoleIds = [...currentRoleIds, roleId];
    }
    setCreateMenuRequest({
      ...createMenuRequest,
      roleIds: updatedRoleIds,
    });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create MenuType</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="ja name"
                value={createMenuRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    name: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="en name"
                value={createMenuRequest.nameEn}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    nameEn: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="receipt display name"
                value={createMenuRequest.receiptDisplayName}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    receiptDisplayName: ev.target.value,
                  })
                }
              />
              <div>Category</div>
              <List
                dense
                style={{
                  maxHeight: '100px',
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {categories.map((category, categoryIndex) => (
                  <ListItem key={categoryIndex} button>
                    <ListItemText id={`${category.categoryId}`} primary={category.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          setCreateMenuRequest({
                            ...createMenuRequest,
                            categoryId: category.categoryId,
                          })
                        }
                        checked={createMenuRequest.categoryId === category.categoryId}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <div>MenuType</div>
              <List
                dense
                style={{
                  maxHeight: '100px',
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {menuTypes.map((menuType, menuTypeIndex) => (
                  <ListItem key={menuTypeIndex} button>
                    <ListItemText id={`${menuType.menuTypeId}`} primary={menuType.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          setCreateMenuRequest({
                            ...createMenuRequest,
                            menuTypeId: menuType.menuTypeId,
                          })
                        }
                        checked={createMenuRequest.menuTypeId === menuType.menuTypeId}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <div>RoleIds</div>
              <List
                dense
                style={{
                  maxHeight: '100px',
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {roles.map((role, roleIndex) => (
                  <ListItem key={roleIndex} button>
                    <ListItemText id={`${role.roleId}`} primary={role.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleRoleIdsSetting(role.roleId)}
                        checked={createMenuRequest.roleIds.includes(role.roleId)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <TextField
                id="name-input"
                label="ja description"
                value={createMenuRequest.description}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    description: ev.target.value,
                  })
                }
              />
              <TextField
                id="name-input"
                label="en description"
                value={createMenuRequest.descriptionEn}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    descriptionEn: ev.target.value,
                  })
                }
              />
              <TextField
                id="name-input"
                label="priority"
                value={createMenuRequest.priority}
                variant="filled"
                type="number"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    priority: Number(ev.target.value),
                  })
                }
              />
              <TextField
                id="name-input"
                label="price"
                value={createMenuRequest.price}
                variant="filled"
                type="number"
                onChange={ev =>
                  setCreateMenuRequest({
                    ...createMenuRequest,
                    price: Number(ev.target.value),
                  })
                }
              />
              <div>
                <Button type="button" onClick={createMenu}>
                  Apply
                </Button>
                <Button onClick={onClose}> close </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateMenuDialog;
