import React from 'react';
import { CreateShopClientRequest } from './OnBoarding';
import {
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';

const shopRoles = ['shop', 'shop_admin', 'shop_stripe'];

interface Props {
  setShopClient: (data: {}) => void;
  createShopClientRequest: CreateShopClientRequest;
}

const ShopClientSetting: React.FC<Props> = ({ createShopClientRequest, setShopClient }) => {
  const setShopRole = (roleName: string) => {
    if (createShopClientRequest.roles.includes(roleName)) {
      setShopClient(
        Object.assign(createShopClientRequest, {
          roles: createShopClientRequest.roles.filter(r => r !== roleName),
        }),
      );
    } else {
      setShopClient({
        ...createShopClientRequest,
        roles: [...createShopClientRequest.roles, roleName],
      });
    }
  };
  return (
    <Paper style={{ padding: '10px', margin: '10px' }}>
      <h1>店舗アカウントの発行</h1>
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="shopClientId"
        label="アカウントID"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShopClient({ email: ev.target.value });
        }}
      />
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="shopClientId"
        label="アカウント名"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShopClient({ name: ev.target.value });
        }}
      />
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="shopClientPass"
        label="パスワード"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShopClient({ password: ev.target.value });
        }}
      />
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="shopClientPassConfirm"
        label="パスワード[確認]"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setShopClient({ confirmPassword: ev.target.value });
        }}
      />
      <div>
        <h2>店舗アカウント権限の設定</h2>
        <List
          dense
          style={{
            margin: '10px',
            width: '300px',
            overflow: 'auto',
          }}
        >
          {shopRoles.map((roleName, roleIndex) => (
            <ListItem key={roleIndex} button>
              <ListItemText id={`${roleName}-${roleIndex}`} primary={roleName} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => setShopRole(roleName)}
                  checked={createShopClientRequest.roles.includes(roleName)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
};

export default ShopClientSetting;
