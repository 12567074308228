import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import firebase from '../../../plugins/firebase';
import request from '../../../plugins/api';
import { Company } from '../../../entity/company.entity';
import { Shop } from '../../../entity/shop.entity';
import moment from 'moment-timezone';
import { Role } from '../../../entity/role.entity';
import OldConnectionLogsDialog from './OldConnectionLogsDialog/OldConnectionLogsDialog';
const oldCompanyIds = [1, 25, 27];

const Moment = require('moment');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface ConnectionStatus {
  lastUpdatedAt: any;
  lastUpdatedType: string;
  companyId: number;
  shopId: string;
  roleId: number;
}

const formatDate = (date: Date) => Moment(date).format('YYYY-MM-DD HH:mm:ss');

const OldConnectionStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as ConnectionStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as ConnectionStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);
  const [shops, setShops] = useState([] as Shop[]);
  const [roles, setRoles] = useState([] as Role[]);

  useEffect(() => {
    request.get('companies', { headers: { 'x-company-id': 1 } }).then(response => {
      setCompanies(response.data);
    });
  }, []);
  useEffect(() => {
    companies
      .filter(c => oldCompanyIds.includes(c.companyId))
      .forEach(company => {
        request
          .get('shops', {
            headers: { 'x-company-id': company.companyId },
          })
          .then(response => {
            const shops = response.data as Shop[];
            shops.forEach(shop => {
              request
                .get(`shops/${shop.shopId}/roles`, {
                  headers: { 'x-company-id': company.companyId },
                })
                .then(roleResponse => {
                  setRoles(prev => [...prev, ...roleResponse.data]);
                });
            });
            setShops(prev => [...prev, ...response.data]);
          });
      });
  }, [companies]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('connectionStatus')
      .orderBy('lastUpdatedAt', 'desc')
      .endAt(
        moment()
          .subtract(5, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .toDate(),
      )
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logs = snapshot.docs
            .filter(d => !d.id.includes('DiniiDoc'))
            .map(value => value.data() as ConnectionStatus)
            .filter(o => oldCompanyIds.includes(o.companyId)); // NOTE efhのみ
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  const getShopNameById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.name;
    } else {
      return '';
    }
  };

  const getRoleNameById = (roleId: number): string => {
    const role = roles.find(ro => ro.roleId === roleId);
    if (role) {
      return role.name;
    } else {
      return '';
    }
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>Note: 5日前までのログをリアルタイム表示</p>
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Role ID</TableCell>
              <TableCell align="right">Role Name</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  setSelectedStatus(status);
                  setLogModalOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">{getShopNameById(status.shopId)}</TableCell>
                <TableCell align="right">{status.roleId}</TableCell>
                <TableCell align="right">{getRoleNameById(status.roleId)}</TableCell>
                <TableCell align="right">{formatDate(status.lastUpdatedAt.toDate())}</TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <OldConnectionLogsDialog
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        roleId={selectedStatus.roleId}
        isOpen={logModalOpen}
        onClose={() => setLogModalOpen(false)}
      />
    </>
  );
};

export default OldConnectionStatus;
