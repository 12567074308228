import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { MenuType } from '../../../../entity/menuType.entity';
import { Category } from '../../../../entity/category.entity';
import { Shop } from '../../../../entity/shop.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreatePlanRequest {
  companyId: number;
  planName: string;
  receiptDisplayName: string;
  planNameEn: string;
  planTime: number; // 分単位
  imageUrl: string | null;
  menuTypeId: number;
  planCategoryId: number;
  roleIds: number[];
  description?: string;
  start?: string;
  end?: string;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
  menuTypes: MenuType[];
  categories: Category[];
}

const CreatePlanDialog: React.FC<Props> = ({
  menuTypes,
  categories,
  isOpen,
  onClose,
  shop,
}: Props) => {
  const classes = useStyles();

  const [createPlanRequest, setCreatePlanRequest] = useState({} as CreatePlanRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createPlan = () => {
    request
      .post(
        `shops/${shop.shopId}/plans`,
        {
          companyId: shop.companyId,
          ...createPlanRequest,
        },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(() => {
        setDialogMessage('update success');
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Plan</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="JaPlanName"
                value={createPlanRequest.planName}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanRequest({ ...createPlanRequest, planName: ev.target.value })
                }
              />
              <TextField
                required
                id="name-input"
                label="EnPlanName"
                value={createPlanRequest.planNameEn}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanRequest({ ...createPlanRequest, planNameEn: ev.target.value })
                }
              />
              <TextField
                required
                id="name-input"
                label="receiptDisplayName"
                value={createPlanRequest.receiptDisplayName}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanRequest({
                    ...createPlanRequest,
                    receiptDisplayName: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="PlanTime"
                value={createPlanRequest.planTime}
                variant="filled"
                type="number"
                onChange={ev =>
                  setCreatePlanRequest({
                    ...createPlanRequest,
                    planTime: Number(ev.target.value),
                  })
                }
              />
              <TextField
                id="name-input"
                label="imageUrl"
                value={createPlanRequest.imageUrl}
                variant="filled"
                type="number"
                onChange={ev =>
                  setCreatePlanRequest({
                    ...createPlanRequest,
                    imageUrl: ev.target.value,
                  })
                }
              />
              <div>
                <h3>menuTypeId</h3>
              </div>
              <List
                dense
                style={{
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {menuTypes.map((menuType, menuTypeIndex) => (
                  <ListItem key={menuTypeIndex} button>
                    <ListItemText
                      id={`${menuType.name}-${menuTypeIndex}`}
                      primary={menuType.name}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          setCreatePlanRequest({
                            ...createPlanRequest,
                            menuTypeId: menuType.menuTypeId,
                          })
                        }
                        checked={createPlanRequest.menuTypeId === menuType.menuTypeId}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <div>
                <h3>planCategory</h3>
              </div>
              <List
                dense
                style={{
                  width: '300px',
                  overflow: 'auto',
                }}
              >
                {categories.map((category, categoryIndex) => (
                  <ListItem key={categoryIndex} button>
                    <ListItemText
                      id={`${category.name}-${categoryIndex}`}
                      primary={category.name}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          setCreatePlanRequest({
                            ...createPlanRequest,
                            planCategoryId: category.categoryId,
                          })
                        }
                        checked={createPlanRequest.planCategoryId === category.categoryId}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {/*  roleIds: number[]; */}

              <TextField
                required
                id="name-input"
                label="start"
                value={createPlanRequest.start}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanRequest({
                    ...createPlanRequest,
                    start: ev.target.value,
                  })
                }
              />
              <TextField
                required
                id="name-input"
                label="end"
                value={createPlanRequest.end}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreatePlanRequest({
                    ...createPlanRequest,
                    end: ev.target.value,
                  })
                }
              />
            </div>

            <div>
              <Button type="button" color="primary" onClick={createPlan}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreatePlanDialog;
