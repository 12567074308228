import React, { useEffect, useState, useCallback } from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import { CompanyConfig } from '../../../entity/companyConfig.entity';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Companies: React.FC = () => {
  const classes = useStyles();
  const [companyConfigMap, setCompanyConfigMap] = useState([] as CompanyConfig[]);
  // edit flags
  const [editCompanyConfigIndex, setEditCompanyConfigIndex] = useState(null as null | number);
  // edit data
  const [editedCompanyConfig, setEditedCompanyConfig] = useState(
    null as null | Partial<CompanyConfig>,
  );
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const { companies } = useShops();

  const cancelEdit = () => {
    setEditCompanyConfigIndex(null);
    setEditedCompanyConfig(null);
  };

  const applyCompanyConfigChange = (editedIndex: number) => {
    const targetCompany = companies[editedIndex];
    const companyConfig = companyConfigMap.find(c => c.companyId === targetCompany.companyId);
    if (!companyConfig) return;

    request
      .post(
        `companies/${targetCompany.companyId}/config/${companyConfig.companyConfigId}`,
        editedCompanyConfig,
        { headers: { 'x-company-id': targetCompany.companyId } },
      )
      .then(res => {
        setCompanyConfigMap([
          ...companyConfigMap.map(ccm => (ccm.companyId === res.data.companyId ? res.data : ccm)),
        ]);
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };

  useEffect(() => {
    if (companies.length > 0) {
      request
        .get('company_configs', {
          headers: { 'x-company-id': companies[0].companyId },
        })
        .then(response => setCompanyConfigMap(response.data));
    }
  }, [companies]);

  const findConfig = useCallback(
    (companyId: number, configKey: keyof CompanyConfig) => {
      const config = companyConfigMap.find(companyConfig => companyConfig.companyId === companyId);
      if (!config) return null;
      return config[configKey];
    },
    [companyConfigMap],
  );

  return (
    <>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Company Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Version code</TableCell>
              <TableCell align="right">LIFF ID</TableCell>
              <TableCell align="left">Allowed domain</TableCell>
              <TableCell align="right">Stripe owner id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {company.companyId}
                </TableCell>
                <TableCell align="right">{company.name}</TableCell>
                <TableCell align="right">{company.description}</TableCell>
                <TableCell align="right">{findConfig(company.companyId, 'versionCode')}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: findConfig(company.companyId, 'liffId'),
                    startEdit: () => setEditCompanyConfigIndex(index),
                    cancelEdit,
                    applyChange: () => applyCompanyConfigChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedCompanyConfig({ liffId: val }),
                    isShowEditForm: editCompanyConfigIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {findConfig(company.companyId, 'allowedDomain')}
                </TableCell>
                <TableCell align="right">
                  {findConfig(company.companyId, 'stripeOwnerId') && (
                    <div>{findConfig(company.companyId, 'stripeOwnerId')}</div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Companies;
