import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import CreateShopDialog from './CreateShopDialog/CreateShopDialog';
import { PaymentType } from '../../../constants';
import { Shop, ShopMaintenanceInfo } from '../../../entity/shop.entity';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Shops: React.FC = () => {
  const classes = useStyles();
  const { shops, setShops } = useShops();
  // edit flags
  const [editMaxNumIndex, setEditMaxNumIndex] = useState(null as null | number);
  const [editMaxNumForNumPeopleIndex, setEditMaxNumForNumPeopleIndex] = useState(
    null as null | number,
  );
  const [editOpenIndex, setEditOpenIndex] = useState(null as null | number);
  const [editCloseIndex, setEditCloseIndex] = useState(null as null | number);
  const [editShopCloseConnectionTimeIndex, setEditShopCloseConnectionTimeIndex] = useState(
    null as null | number,
  );
  const [editShopRebootTimeIndex, setEditShopRebootTimeIndex] = useState(null as null | number);
  const [editChangeDateTimeIndex, setEditChangeDateTimeIndex] = useState(null as null | number);
  const [editOrderIntervalSecondsIndex, setEditOrderIntervalSecondsIndex] = useState<number | null>(
    null,
  );
  const [editLiffIdIndex, setEditLiffIdIndex] = useState<null | number>(null);
  // edit data
  const [editedName, setEditedName] = useState(null as null | string);
  const [editedMaxNum, setEditedMaxNum] = useState(null as null | number);
  const [editedMaxNumForNumPeople, setEditedMaxNumForNumPeople] = useState(null as null | number);
  const [editedOpen, setEditedOpen] = useState(null as null | string);
  const [editedClose, setEditedClose] = useState(null as null | string);
  const [editedShopCloseConnectionTime, setEditedShopCloseConnectionTime] = useState(
    null as null | string,
  );
  const [editedShopRebootTime, setEditedShopRebootTime] = useState(null as null | string);
  const [editedChangeDateTime, setEditedChangeDateTime] = useState(null as null | string);
  const [editedOrderIntervalSeconds, setEditedOrderIntervalSeconds] = useState<number | null>(null);
  const [editedLiffId, setEditedLiffId] = useState<string | null>(null);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createShopModalOpen, setCreateShopModalOpen] = useState(false);

  const [selectedShop, setSelectedShop] = useState<Shop | undefined>(undefined);
  const [shopMaintenanceInfo, setShopMaintenanceInfo] = useState<ShopMaintenanceInfo | undefined>(
    undefined,
  );
  const [showMaintenanceDialog, setShowMaintenanceDialog] = useState(false);

  useEffect(() => {
    setShopMaintenanceInfo(undefined);
    if (selectedShop) {
      request
        .get(`shop_maintenance_info/query_by_shop_id/${selectedShop.shopId}`, {
          headers: { 'x-company-id': selectedShop.companyId },
        })
        .then(response => {
          setShopMaintenanceInfo(response.data as ShopMaintenanceInfo);
        })
        .catch(() => {
          console.log('set default');
          setShopMaintenanceInfo({
            isMaintenance: false,
            maintenanceText: '',
            maintenanceTextEn: '',
            shopId: selectedShop.shopId,
          });
        });
    }
  }, [selectedShop]);

  const updateShopMaintenanceInfo = () => {
    if (selectedShop && shopMaintenanceInfo) {
      if (
        shopMaintenanceInfo.maintenanceText === '' ||
        shopMaintenanceInfo.maintenanceTextEn === '' ||
        typeof shopMaintenanceInfo.isMaintenance === 'undefined'
      ) {
        window.alert('必要事項を記入してください');
        return;
      }

      if (shopMaintenanceInfo.shopMaintenanceInfoId) {
        request
          .post(
            `shop_maintenance_info/${shopMaintenanceInfo.shopMaintenanceInfoId}`,
            shopMaintenanceInfo,
            {
              headers: { 'x-company-id': selectedShop.companyId },
            },
          )
          .then(() => {
            setDialogMessage('success');
          })
          .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
      } else {
        request
          .post(`shop_maintenance_info`, shopMaintenanceInfo, {
            headers: { 'x-company-id': selectedShop.companyId },
          })
          .then(() => {
            setDialogMessage('success');
          })
          .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
      }
    }
  };

  const cancelEdit = () => {
    setEditMaxNumIndex(null);
    setEditMaxNumForNumPeopleIndex(null);
    setEditOpenIndex(null);
    setEditCloseIndex(null);
    setEditShopCloseConnectionTimeIndex(null);
    setEditShopRebootTimeIndex(null);
    setEditChangeDateTimeIndex(null);
    setEditOrderIntervalSecondsIndex(null);
    setEditLiffIdIndex(null);
    setEditedName(null);
    setEditedMaxNum(null);
    setEditedMaxNumForNumPeople(null);
    setEditedOpen(null);
    setEditedClose(null);
    setEditedShopCloseConnectionTime(null);
    setEditedShopRebootTime(null);
    setEditedChangeDateTime(null);
    setEditedOrderIntervalSeconds(null);
    setEditedLiffId(null);
  };

  const applyChange = (editedIndex: number) => {
    const targetShop = shops[editedIndex];
    // eslint-disable-next-line radix
    const updateValueBody = {
      name: editedName || undefined,
      maxNum: editedMaxNum || undefined,
      maxNumForNumPeople: editedMaxNumForNumPeople || undefined,
      open: editedOpen || undefined,
      close: editedClose || undefined,
      shopCloseConnectionTime: editedShopCloseConnectionTime || undefined,
      shopRebootTime: editedShopRebootTime || undefined,
      changeDateTime: editedChangeDateTime || undefined,
      orderIntervalSeconds: editedOrderIntervalSeconds || undefined,
      liffId: editedLiffId || undefined,
    };
    request
      .post(`shops/${targetShop.shopId}`, updateValueBody, {
        headers: { 'x-company-id': targetShop.companyId },
      })
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    cancelEdit();
  };
  const toggleExTax = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { exTax: !shop.exTax },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleEnableAlert = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { enableAlert: !shop.enableAlert },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleEnablePos = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { enablePos: !shop.enablePos },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleEnableRemark = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { enableRemark: !shop.enableRemark },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleEnableAutoResetMenuSoldOut = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { enableAutoResetMenuSoldOut: !shop.enableAutoResetMenuSoldOut },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleDisableOnlinePayment = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { disableOnlinePayment: !shop.disableOnlinePayment },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleDisableOfflinePayment = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { disableOfflinePayment: !shop.disableOfflinePayment },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleDisableCallStaff = (shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { disableCallStaff: !shop.disableCallStaff },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const updatePaymentType = (paymentType: PaymentType, shop: Shop) => {
    request
      .post(
        `shops/${shop.shopId}`,
        { paymentType },
        { headers: { 'x-company-id': shop.companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };

  const removeOrderIntervalSeconds = (companyId: number, shopId: string) => {
    request
      .post(
        `shops/${shopId}`,
        { orderIntervalSeconds: null },
        { headers: { 'x-company-id': companyId } },
      )
      .then(response => {
        setShops(
          shops.map(existingShop => {
            if (existingShop.shopId === response.data.shopId) {
              return response.data;
            }
            return existingShop;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateShopModalOpen(true)}
      >
        Create shop
      </Button>
      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">PaymentType</TableCell>
              <TableCell align="right">Ex tax(ONで外税)</TableCell>
              <TableCell align="right">MaxNum</TableCell>
              <TableCell align="right">
                MaxNumForNumPeople(一人当たりのオーダー可能上限数)
              </TableCell>
              <TableCell align="right">Open</TableCell>
              <TableCell align="right">Close</TableCell>
              <TableCell align="right">enableAlert</TableCell>
              <TableCell align="right">enablePos</TableCell>
              <TableCell align="right">日時のメニュー売り切れリセット機能(ONで稼働)</TableCell>
              <TableCell align="right">注文間隔制限設定(秒単位)</TableCell>
              <TableCell align="right">備考欄の利用</TableCell>
              <TableCell align="right">ネット決済の無効化(ONで無効)</TableCell>
              <TableCell align="right">
                現地決済ボタンの無効化(ONで無効，レジで会計ボタンがユーザアプリで非表示になるだけで，レジ会計自体は可能です)
              </TableCell>
              <TableCell align="right">店員呼び出しの無効化(ONで無効)</TableCell>
              <TableCell align="right">LIFFID</TableCell>
              <TableCell align="right">
                Shop close connection time<p>自動的に営業終了を行う時間(HH:mm形式)</p>
              </TableCell>
              <TableCell align="right">
                Shop reboot time<p>自動的に再起動を行う時間(HH:mm形式)</p>
              </TableCell>
              <TableCell align="right">
                Change date time<p>営業日変更時刻</p>
              </TableCell>
              <TableCell align="right">メンテナンス設定</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shops.map((shop, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {shop.companyId}
                </TableCell>
                <TableCell align="right">{shop.shopId}</TableCell>
                <TableCell align="right">{shop.name}</TableCell>
                <TableCell align="right">
                  <InputLabel id="demo-simple-select-label">PaymentType</InputLabel>
                  <Select
                    required
                    style={{ width: '300px', marginRight: '30px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={shop.paymentType}
                    onChange={ev => updatePaymentType(ev.target.value as PaymentType, shop)}
                  >
                    {Object.values(PaymentType).map(paymentTypeValue => (
                      <MenuItem value={paymentTypeValue} key={paymentTypeValue}>
                        {paymentTypeValue}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.exTax}
                    onChange={() => toggleExTax(shop)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.maxNum,
                    startEdit: () => setEditMaxNumIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedMaxNum(Number(val)),
                    isShowEditForm: editMaxNumIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.maxNumForNumPeople,
                    startEdit: () => setEditMaxNumForNumPeopleIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedMaxNumForNumPeople(Number(val)),
                    isShowEditForm: editMaxNumForNumPeopleIndex === index,
                    dataType: 'number',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.open,
                    startEdit: () => setEditOpenIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedOpen(val),
                    isShowEditForm: editOpenIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.close,
                    startEdit: () => setEditCloseIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedClose(val),
                    isShowEditForm: editCloseIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enableAlert}
                    onChange={() => toggleEnableAlert(shop)}
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enablePos}
                    onChange={() => toggleEnablePos(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enableAutoResetMenuSoldOut}
                    onChange={() => toggleEnableAutoResetMenuSoldOut(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.orderIntervalSeconds,
                    startEdit: () => setEditOrderIntervalSecondsIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedOrderIntervalSeconds(val),
                    isShowEditForm: editOrderIntervalSecondsIndex === index,
                    dataType: 'number',
                  })}
                  <Button
                    color="secondary"
                    onClick={() => removeOrderIntervalSeconds(shop.companyId, shop.shopId)}
                  >
                    設定の削除
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enableRemark}
                    onChange={() => toggleEnableRemark(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.disableOnlinePayment}
                    onChange={() => toggleDisableOnlinePayment(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.disableOfflinePayment}
                    onChange={() => toggleDisableOfflinePayment(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.disableCallStaff}
                    onChange={() => toggleDisableCallStaff(shop)}
                  />
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.liffId,
                    startEdit: () => setEditLiffIdIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedLiffId(val),
                    isShowEditForm: editLiffIdIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.shopCloseConnectionTime,
                    startEdit: () => setEditShopCloseConnectionTimeIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedShopCloseConnectionTime(val),
                    isShowEditForm: editShopCloseConnectionTimeIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.shopRebootTime,
                    startEdit: () => setEditShopRebootTimeIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedShopRebootTime(val),
                    isShowEditForm: editShopRebootTimeIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: shop.changeDateTime,
                    startEdit: () => setEditChangeDateTimeIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(index),
                    formClassName: classes.form,
                    setValue: val => setEditedChangeDateTime(val),
                    isShowEditForm: editChangeDateTimeIndex === index,
                  })}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      setSelectedShop(shop);
                      setShowMaintenanceDialog(true);
                    }}
                  >
                    OPEN
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog
        open={showMaintenanceDialog && shopMaintenanceInfo !== undefined}
        onClose={() => setShowMaintenanceDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          メンテナンスモード設定({selectedShop?.name})
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: 400,
              height: 400,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  required
                  checked={shopMaintenanceInfo?.isMaintenance}
                  onChange={() => {
                    setShopMaintenanceInfo({
                      ...shopMaintenanceInfo!,
                      isMaintenance: !shopMaintenanceInfo?.isMaintenance,
                    });
                  }}
                  value="isMaintenance"
                />
              }
              label={'isMaintenance'}
            />
            <TextField
              label={'メンテナンステキスト'}
              name="メンテナンステキスト"
              onChange={(event: any) => {
                setShopMaintenanceInfo({
                  ...shopMaintenanceInfo!,
                  maintenanceText: event.target.value as string,
                });
              }}
              value={shopMaintenanceInfo?.maintenanceText}
            />
            <TextField
              label={'メンテナンステキストEn'}
              name="メンテナンステキストEn"
              onChange={(event: any) =>
                setShopMaintenanceInfo({
                  ...shopMaintenanceInfo!,
                  maintenanceTextEn: event.target.value as string,
                })
              }
              value={shopMaintenanceInfo?.maintenanceTextEn}
            />
            <Button
              onClick={() => {
                updateShopMaintenanceInfo();
                setShowMaintenanceDialog(false);
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
        <DialogActions />
      </Dialog>

      <CreateShopDialog
        isOpen={createShopModalOpen}
        onClose={() => setCreateShopModalOpen(false)}
      />
    </>
  );
};

export default Shops;
