import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateClerkRequest {
  companyId: number;
  name: string;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateClerkDialog: React.FC<Props> = ({ isOpen, onClose, shop }: Props) => {
  const classes = useStyles();

  const [createClerkRequest, setCreateClerkRequest] = useState({} as CreateClerkRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createClerk = () => {
    request
      .post(
        `shops/${shop.shopId}/clerks`,
        {
          companyId: shop.companyId,
          ...createClerkRequest,
        },
        {
          headers: { 'x-company-id': shop.companyId },
        },
      )
      .then(() => {
        setDialogMessage('update success');
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Clerk</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="JaClerkName"
                value={createClerkRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateClerkRequest({ ...createClerkRequest, name: ev.target.value })
                }
              />
            </div>

            <div>
              <Button type="button" onClick={createClerk}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateClerkDialog;
