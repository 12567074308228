import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import dataTableRowComponent from '../components/dataTableRowComponent';
import { Fee } from '../../../entity/shop.entity';
import { TableData } from '../../../entity/table.entity';
import CreateFeeDialog from './CreateFeeDialog/CreateFeeDialog';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Fees: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { selectedShop, selectedCompany } = useShops();
  const [tables, setTables] = useState([] as TableData[]);
  const [fees, setFees] = useState([] as Fee[]);
  // edit flags
  const [editNameIndex, setEditNameIndex] = useState(null as null | number);
  const [editValueIndex, setEditValueIndex] = useState(null as null | number);
  const [editStartIndex, setEditStartIndex] = useState(null as null | number);
  const [editEndIndex, setEditEndIndex] = useState(null as null | number);
  // const [editEditTableIdIndex, setEditTableIdIndex] = useState(null as null | number);
  // edit data
  const [editedName, setEditedName] = useState(null as null | string);
  const [editedValue, setEditedValue] = useState(null as null | number);
  const [editedStart, setEditedStart] = useState(null as null | string);
  const [editedEnd, setEditedEnd] = useState(null as null | string);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [createFeeModalOpen, setCreateFeeModalOpen] = useState(false);

  const cancelEdit = () => {
    setEditNameIndex(null);
    setEditValueIndex(null);
    setEditStartIndex(null);
    setEditEndIndex(null);

    setEditedName(null);
    setEditedValue(null);
    setEditedStart(null);
    setEditedEnd(null);
  };

  const applyChange = (editedIndex: number) => {
    if (!selectedShop) return;
    const targetFee = fees[editedIndex];
    const updateValueBody = {
      name: editedName || targetFee.name,
      value: typeof editedValue === 'number' ? editedValue : targetFee.value,
      start: editedStart || targetFee.start,
      end: editedEnd || targetFee.end,
    };
    const updateValues = [...selectedShop.fees];
    updateValues[editedIndex] = { ...targetFee, ...updateValueBody };
    request
      .post(
        `shops/${selectedShop!.shopId}`,
        { fees: updateValues },
        {
          headers: { 'x-company-id': selectedShop!.companyId },
        },
      )
      .then(res => {
        updateFee(res.data.fees[editedIndex], editedIndex);
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
    cancelEdit();
  };

  useEffect(() => {
    if (!selectedShop) return;
    setFees(selectedShop.fees ? selectedShop.fees : []);
    request
      .get(`shops/${selectedShop.shopId}/tables`, {
        headers: { 'x-company-id': selectedShop!.companyId },
      })
      .then(response => {
        setTables(response.data);
      });
  }, [selectedShop]);

  const handleFeeTypeSetting = (feeTypeString: string, editedIndex: number) => {
    if (!selectedShop) return;
    const targetFee = fees[editedIndex];
    const updateValueBody = { type: feeTypeString };
    const updateValues = [...selectedShop.fees];
    updateValues[editedIndex] = { ...targetFee, ...updateValueBody };
    request
      .post(
        `shops/${selectedShop!.shopId}`,
        { fees: updateValues },
        { headers: { 'x-company-id': selectedShop!.companyId } },
      )
      .then(res => {
        updateFee(res.data.fees[editedIndex], editedIndex);
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
    cancelEdit();
  };
  const updateFee = (updatedFee: Fee, feeIndex: number) => {
    setFees(
      fees.map((f, index) => {
        if (index === feeIndex) {
          return updatedFee;
        } else {
          return f;
        }
      }),
    );
  };
  return (
    <>
      <Button
        disabled={selectedShop === null}
        variant="outlined"
        color="primary"
        style={{ width: '70vw', marginBottom: '30px' }}
        onClick={() => setCreateFeeModalOpen(true)}
      >
        Create fee
      </Button>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        Note: 手数料の適用開始時間帯は，卓に流入した時刻で判定される
        <br />
        手数料のタイプ(rate/amount)を切り替える時は，制限があるため，一旦valueをゼロにする
        <br />
        type=rateの場合，valueは0 - 1の範囲．type=amountの場合，valueは整数
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Fee name</TableCell>
              <TableCell align="right">
                Fee price type
                <p>手数料の掛け方</p>
                <p>amountの場合は設定金額を合計金額に加算する</p>
                <p>rateの場合は，合計金額に設定割合を加算する</p>
              </TableCell>
              <TableCell align="right">
                Fee value<p>金額設定値</p>
                <p>type=amountの場合は絶対値</p>
                <p>type=rateの場合は割合</p>
              </TableCell>
              <TableCell align="right">
                Fee condition: start<p>手数料を適用開始する時間(HH:mm形式)</p>
              </TableCell>
              <TableCell align="right">
                Fee condition: end<p>手数料を適用終了する時間(HH:mm形式)</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedShop &&
              fees.map((fee, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {selectedShop.companyId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedCompany?.name}
                  </TableCell>
                  <TableCell align="right">{selectedShop.shopId}</TableCell>
                  <TableCell align="right">{selectedShop.name}</TableCell>
                  <TableCell align="right">
                    {dataTableRowComponent({
                      defaultValue: fee.name,
                      startEdit: () => setEditNameIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(index),
                      formClassName: classes.form,
                      setValue: val => setEditedName(val),
                      isShowEditForm: editNameIndex === index,
                    })}
                  </TableCell>
                  <TableCell align="right">
                    <List
                      dense
                      style={{
                        maxHeight: '100px',
                        width: '100px',
                        overflow: 'auto',
                      }}
                    >
                      {['amount', 'rate'].map((feeType, feeTypeIndex) => (
                        <ListItem key={feeTypeIndex} button>
                          <ListItemText id={feeType} primary={feeType} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => handleFeeTypeSetting(feeType, index)}
                              checked={fee.type === feeType}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                  <TableCell align="right">
                    {dataTableRowComponent({
                      defaultValue: fee.value,
                      startEdit: () => setEditValueIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(index),
                      formClassName: classes.form,
                      setValue: val => setEditedValue(Number(val)),
                      isShowEditForm: editValueIndex === index,
                      dataType: 'number',
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {dataTableRowComponent({
                      defaultValue: fee.start,
                      startEdit: () => setEditStartIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(index),
                      formClassName: classes.form,
                      setValue: val => setEditedStart(val),
                      isShowEditForm: editStartIndex === index,
                      dataType: 'string',
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {dataTableRowComponent({
                      defaultValue: fee.end,
                      startEdit: () => setEditEndIndex(index),
                      cancelEdit,
                      applyChange: () => applyChange(index),
                      formClassName: classes.form,
                      setValue: val => setEditedEnd(val),
                      isShowEditForm: editEndIndex === index,
                      dataType: 'string',
                    })}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
      {selectedShop && (
        <CreateFeeDialog
          tables={tables}
          shop={selectedShop}
          isOpen={createFeeModalOpen}
          onClose={() => setCreateFeeModalOpen(false)}
        />
      )}
    </>
  );
};

export default Fees;
