import React from 'react';
import { Button, TextField } from '@material-ui/core';

interface Props {
  defaultValue: any;
  formClassName: string;
  setValue: (value: any) => void;
  applyChange: () => void;
  cancelEdit: () => void;
  startEdit: () => void;
  isShowEditForm: boolean;
  dataType?: string;
}

export default function dataTableRowComponent({
  defaultValue,
  formClassName,
  setValue,
  applyChange,
  cancelEdit,
  startEdit,
  isShowEditForm,
  dataType,
}: Props) {
  return (
    <>
      <p>{defaultValue}</p>
      {isShowEditForm ? (
        <>
          <form className={formClassName} noValidate autoComplete="off">
            <TextField
              defaultValue={defaultValue}
              type={dataType || 'string'}
              id="filled-basic"
              label="Filled"
              variant="filled"
              onChange={ev => setValue(ev.target.value)}
            />
          </form>
          <Button onClick={() => applyChange()}>Apply</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </>
      ) : (
        <>
          <Button onClick={() => startEdit()}>Edit</Button>
        </>
      )}
    </>
  );
}
