import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateCategoryRequest {
  companyId: number;
  name: string;
  nameEn: string;
  shopSideName: string;
  priority: number;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateCategoryDialog: React.FC<Props> = ({ isOpen, onClose, shop }: Props) => {
  const classes = useStyles();

  const [createCategoryRequest, setCreateCategoryRequest] = useState({} as CreateCategoryRequest);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const createCategory = () => {
    request
      .post(
        `shops/${shop.shopId}/categories`,
        {
          companyId: shop.companyId,
          ...createCategoryRequest,
        },
        {
          headers: { 'x-company-id': shop.companyId },
        },
      )
      .then(() => {
        setDialogMessage('update success');
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create Shop</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="JaCategoryName"
                value={createCategoryRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateCategoryRequest({ ...createCategoryRequest, name: ev.target.value })
                }
              />
              <TextField
                required
                id="name-input"
                label="EnCategoryName"
                value={createCategoryRequest.nameEn}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateCategoryRequest({ ...createCategoryRequest, nameEn: ev.target.value })
                }
              />
              <TextField
                required
                id="name-input"
                label="店舗側表示名"
                value={createCategoryRequest.shopSideName}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateCategoryRequest({
                    ...createCategoryRequest,
                    shopSideName: ev.target.value,
                  })
                }
              />
              <TextField
                id="name-input"
                label="priority"
                value={createCategoryRequest.priority}
                variant="filled"
                type="number"
                onChange={ev =>
                  setCreateCategoryRequest({
                    ...createCategoryRequest,
                    priority: Number(ev.target.value),
                  })
                }
              />
            </div>

            <div>
              <Button type="button" onClick={createCategory}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateCategoryDialog;
