import React, { useCallback, useEffect, useState } from 'react';
import firebase from '../../../plugins/firebase';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { useShops } from '../../../hooks/useShops';
import {
  Button,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

interface PrintTarget {
  printTargetId: string;
  printTargetType: string;
  jsonContent: string;
  isPrinted: boolean;
  numRead: number;
  totalSlipNum: number;
  roleId: number;
  shopId: string;
  createdAt: firebase.firestore.Timestamp;
}

export const PrintTargets: React.FC = () => {
  const [targets, setTargets] = useState<PrintTarget[]>([]);
  const { selectedShop, shops } = useShops();
  const [loading, setLoading] = useState(false);
  const [onlyUnprinted, setOnlyUnprinted] = useState(false);
  const [all, setAll] = useState(selectedShop === null);

  const setIsPrinted = useCallback(async (printTarget: PrintTarget, isPrinted: boolean) => {
    try {
      await firebase
        .firestore()
        .collection('shops')
        .doc(printTarget.shopId)
        .collection('printTargets')
        .doc(printTarget.printTargetId)
        .set({ isPrinted }, { merge: true });
    } catch (e) {
      window.alert(e);
    }
  }, []);

  useEffect(() => {
    if (all) {
      setLoading(true);
      const unsubscribe = firebase
        .firestore()
        .collectionGroup('printTargets')
        .orderBy('createdAt', 'desc')
        .where(
          'createdAt',
          '>=',
          moment()
            .subtract(1, 'd')
            .toDate(),
        )
        .where('isPrinted', 'in', [!onlyUnprinted, false])
        .onSnapshot(
          snapshot => {
            setLoading(false);
            const logs = snapshot.docs.map(value => value.data() as PrintTarget);
            setTargets(logs);
          },
          err => {
            setLoading(false);
            console.log(`Encountered error: ${err}`);
          },
        );

      return unsubscribe;
    } else {
      if (!selectedShop) return;
      setLoading(true);
      const unsubscribe = firebase
        .firestore()
        .collection('shops')
        .doc(selectedShop.shopId)
        .collection('printTargets')
        .orderBy('createdAt', 'desc')
        .where(
          'createdAt',
          '>=',
          moment()
            .subtract(1, 'd')
            .toDate(),
        )
        .onSnapshot(
          snapshot => {
            setLoading(false);
            const logs = snapshot.docs.map(value => value.data() as PrintTarget);
            setTargets(logs.filter(l => (onlyUnprinted ? !l.isPrinted : true)));
          },
          err => {
            setLoading(false);
            console.log(`Encountered error: ${err}`);
          },
        );
      return unsubscribe;
    }
  }, [onlyUnprinted, selectedShop, all]);

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>Note: 1日前までのログをリアルタイム表示</p>
      </div>
      <Typography>全ての店舗を表示</Typography>
      <Switch value={all} onChange={(_, checked) => setAll(checked)} />
      <Typography>未読のみ</Typography>
      <Switch value={onlyUnprinted} onChange={(_, checked) => setOnlyUnprinted(checked)} />
      <Paper>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>印刷状況</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>店舗名</TableCell>
              <TableCell>ロールID</TableCell>
              <TableCell>タイプ</TableCell>
              <TableCell>コンテンツ</TableCell>
            </TableRow>
          </TableHead>
          {loading ? null : (
            <TableBody>
              {targets.map((target, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      fontSize: 18,
                      backgroundColor: target.isPrinted ? 'palegreen' : 'crimson',
                      color: target.isPrinted ? 'black' : 'white',
                    }}
                  >
                    {target.isPrinted ? '印刷済み' : '未印刷'}
                    <br />
                    {target.numRead + ' / ' + target.totalSlipNum}

                    <br />

                    {target.isPrinted ? (
                      <Button variant={'contained'} onClick={() => setIsPrinted(target, false)}>
                        未印刷にする
                      </Button>
                    ) : (
                      <Button variant={'contained'} onClick={() => setIsPrinted(target, true)}>
                        印刷済みにする
                      </Button>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(target.createdAt.toDate()).format('YYYY/MM HH:mm:ss')}
                  </TableCell>
                  <TableCell>{shops.find(s => s.shopId === target.shopId)?.name}</TableCell>
                  <TableCell>{target.roleId}</TableCell>
                  <TableCell>{target.printTargetType}</TableCell>
                  <TableCell>
                    <ReactJson src={JSON.parse(target.jsonContent)} theme="google" collapsed />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {loading ? <LinearProgress style={{ width: '100%' }} /> : null}
      </Paper>
    </>
  );
};
