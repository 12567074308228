import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  makeStyles,
  Slide,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import request from '../../../../plugins/api';
import { Shop } from '../../../../entity/shop.entity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface CreateRoleRequest {
  name: string;
  shouldPrintReceipt: boolean;
  shouldPrintDishUpReceipt: boolean;
  shouldPrintPaymentReceipt: boolean;
  removeFreeOrderFromDishUpReceipt: boolean;
  tableIds: number[];
  menuTypeIds: number[];
  printerSound: number;
}

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateRoleDialog: React.FC<Props> = ({ isOpen, onClose, shop }: Props) => {
  const classes = useStyles();
  const [createRoleRequest, setCreateRoleRequest] = useState<CreateRoleRequest>({
    name: '',
    shouldPrintPaymentReceipt: false,
    shouldPrintReceipt: false,
    shouldPrintDishUpReceipt: false,
    menuTypeIds: [],
    tableIds: [],
    removeFreeOrderFromDishUpReceipt: false,
    printerSound: 1,
  });

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  useEffect(() => {
    setCreateRoleRequest({
      name: '',
      shouldPrintPaymentReceipt: false,
      shouldPrintReceipt: false,
      shouldPrintDishUpReceipt: false,
      menuTypeIds: [],
      tableIds: [],
      removeFreeOrderFromDishUpReceipt: false,
      printerSound: 1,
    });
  }, [shop]);

  const handlePrinterSoundChange = useCallback(
    (printerSound: number) => {
      setCreateRoleRequest({ ...createRoleRequest, printerSound });
    },
    [createRoleRequest],
  );

  const createRole = () => {
    request
      .post(
        `shops/${shop.shopId}/roles`,
        {
          companyId: shop.companyId,
          ...createRoleRequest,
        },
        {
          headers: { 'x-company-id': shop.companyId },
        },
      )
      .then(() => {
        setDialogMessage('update success');
        onClose();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">Create MenuType</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="name"
                value={createRoleRequest.name}
                variant="filled"
                type="string"
                onChange={ev =>
                  setCreateRoleRequest({ ...createRoleRequest, name: ev.target.value })
                }
              />
              <p>
                デシャップ印刷
                <Switch
                  required
                  checked={createRoleRequest.shouldPrintDishUpReceipt}
                  onChange={() =>
                    setCreateRoleRequest(prev => {
                      return { ...prev, shouldPrintDishUpReceipt: !prev.shouldPrintDishUpReceipt };
                    })
                  }
                  value="checkedA"
                />
              </p>
              <p>
                デシャップ印刷時に無料注文をスキップ
                <Switch
                  required
                  checked={createRoleRequest.removeFreeOrderFromDishUpReceipt}
                  onChange={() =>
                    setCreateRoleRequest(prev => {
                      return {
                        ...prev,
                        removeFreeOrderFromDishUpReceipt: !prev.removeFreeOrderFromDishUpReceipt,
                      };
                    })
                  }
                  value="checkedA"
                />
              </p>
              <p>
                オーダー印刷
                <Switch
                  required
                  checked={createRoleRequest.shouldPrintReceipt}
                  onChange={() =>
                    setCreateRoleRequest(prev => {
                      return { ...prev, shouldPrintReceipt: !prev.shouldPrintReceipt };
                    })
                  }
                  value="checkedA"
                />
              </p>
              <p>
                PCからの自動会計伝票印刷
                <Switch
                  required
                  checked={createRoleRequest.shouldPrintPaymentReceipt}
                  onChange={() =>
                    setCreateRoleRequest(prev => {
                      return {
                        ...prev,
                        shouldPrintPaymentReceipt: !prev.shouldPrintPaymentReceipt,
                      };
                    })
                  }
                  value="checkedA"
                />
              </p>
              <p>
                role id
                {['A', 'B', 'C', 'D', 'E'].map((soundValue, index) => (
                  <ListItem key={index} button>
                    <Typography>{soundValue}</Typography>
                    <Checkbox
                      edge="end"
                      onChange={() => handlePrinterSoundChange(index + 1)}
                      checked={createRoleRequest.printerSound === index + 1}
                    />
                  </ListItem>
                ))}
              </p>
              <div>
                <Button type="button" onClick={createRole}>
                  create role
                </Button>
                <Button onClick={onClose}> close </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};
