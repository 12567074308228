import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  makeStyles,
  Theme,
  DialogContentText,
  DialogActions,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Shop } from '../../../../entity/shop.entity';
import { Choice, Menu, Option } from '../../../../entity/menu.entity';
import request from '../../../../plugins/api';
import CreateOption from './CreateOptionDialog';
import CreateChoice from './CreateChoiceDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  menu: Menu | null;
  reloadMenu: (menuId: number) => void;
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const OptionDialog: React.FC<Props> = ({ shop, menu, reloadMenu, isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [updateOptionRequest, setUpdateOptionRequest] = useState({});
  const [updateChoiceRequests, setUpdateChoiceRequests] = useState({} as { [key: number]: any });
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [options, setOptions] = useState(menu?.options || ([] as Option[]));
  const [createOptionModalOpen, setCreateOptionModalOpen] = useState(false);
  const [createChoiceModalOpen, setCreateChoiceModalOpen] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null as null | number);

  useEffect(() => {
    setOptions(menu?.options || ([] as Option[]));
  }, [menu]);

  const updateChoiceRequest = (choiceId: number, choiceValue: object) => {
    const currentChoiceRequest = updateChoiceRequests[choiceId]
      ? updateChoiceRequests[choiceId]
      : {};
    setUpdateChoiceRequests({
      ...updateChoiceRequests,
      [choiceId]: { ...currentChoiceRequest, ...choiceValue },
    });
  };

  const applyChoiceChange = (choiceId: number) => {
    if (!menu) return;
    request
      .post(`shops/${menu.shopId}/choices/${choiceId}`, updateChoiceRequests[choiceId], {
        headers: { 'x-company-id': menu.companyId },
      })
      .then(() => {
        reloadMenu(menu.menuId);
        setUpdateChoiceRequests({});
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const applyOptionChange = (optionId: number) => {
    if (!menu) return;
    request
      .post(`shops/${menu.shopId}/options/${optionId}`, updateOptionRequest, {
        headers: { 'x-company-id': menu.companyId },
      })
      .then(() => {
        reloadMenu(menu.menuId);
        setUpdateOptionRequest({});
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleOption = (option: Option, key: keyof Option) => {
    if (!menu) return;
    request
      .post(
        `shops/${menu.shopId}/options/${option.optionId}`,
        { [key]: !option[key] },
        { headers: { 'x-company-id': menu.companyId } },
      )
      .then(response => {
        if (!menu.options) return;
        setOptions(
          options.map(o => {
            if (o.optionId === option.optionId) {
              return { ...o, ...response.data };
            } else {
              return o;
            }
          }),
        );
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };
  const toggleChoice = (optionIndex: number, choice: Choice, key: keyof Choice) => {
    if (!menu) return;
    request
      .post(
        `shops/${menu.shopId}/choices/${choice.choiceId}`,
        { [key]: !choice[key] },
        { headers: { 'x-company-id': menu.companyId } },
      )
      .then(() => {
        reloadMenu(menu.menuId);
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
  };

  return (
    menu && (
      <>
        <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
          <DialogTitle id="scroll-dialog-title">Option</DialogTitle>
          <DialogContent>
            <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
              <p>
                Option Note:
                <br />
                maxChoiceNum: 最大チョイス選択可能数
                <br />
                minChoiceNum: 最低チョイス選択数
              </p>
              <p>
                Choice Note:
                <br />
                isDefaultSelection: 初期選択フラグチョイス
                <br />
              </p>
            </div>
            <Button
              variant="outlined"
              color="primary"
              style={{ width: '70vw', marginBottom: '30px' }}
              onClick={() => setCreateOptionModalOpen(true)}
            >
              Create Option
            </Button>
            {options.map((option, optionIndex) => (
              <Paper
                key={optionIndex}
                style={{ background: '#c0c0c0', padding: '5px', marginTop: '12px' }}
              >
                <div
                  style={{
                    margin: '10px',
                    padding: '10px',
                    background: 'white',
                    border: 'solid 1px black',
                  }}
                >
                  <h2>Option</h2>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={1}>
                      <p>option id: {option.optionId}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <p>
                        option name: {option.name}
                        <form noValidate autoComplete="off">
                          <TextField
                            style={{ width: '100%' }}
                            defaultValue={option.name}
                            type="string"
                            id="filled-basic"
                            label="name"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                name: ev.target.value,
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <p>
                        option nameEn: {option.nameEn}
                        <form noValidate autoComplete="off">
                          <TextField
                            style={{ width: '100%' }}
                            defaultValue={option.nameEn}
                            type="string"
                            id="filled-basic"
                            label="name"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                nameEn: ev.target.value,
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <p>
                        option receiptDisplayName: {option.receiptDisplayName}
                        <form noValidate autoComplete="off">
                          <TextField
                            style={{ width: '100%' }}
                            defaultValue={option.receiptDisplayName}
                            type="string"
                            id="filled-basic"
                            label="receiptDisplayName"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                receiptDisplayName: ev.target.value,
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>isDisplay:</p>
                      <Switch
                        required
                        checked={option.isDisplay}
                        onChange={() => toggleOption(option, 'isDisplay')}
                        value="checkedA"
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        maxChoiceNum: {option.maxChoiceNum}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.maxChoiceNum}
                            type="number"
                            id="filled-basic"
                            label="maxChoiceNum"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                maxChoiceNum: Number(ev.target.value),
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        minChoiceNum: {option.minChoiceNum}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.minChoiceNum}
                            type="number"
                            id="filled-basic"
                            label="minChoiceNum"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                minChoiceNum: Number(ev.target.value),
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        description: {option.description}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.description}
                            type="number"
                            id="filled-basic"
                            label="description"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                description: ev.target.value,
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        descriptionEn: {option.descriptionEn}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.descriptionEn}
                            type="number"
                            id="filled-basic"
                            label="descriptionEn"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                descriptionEn: ev.target.value,
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        posId: {option.posOptionId}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.posOptionId}
                            type="number"
                            id="filled-basic"
                            label="posOptionId"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                priority: Number(ev.target.value),
                                posOptionId: Number(ev.target.value),
                              })
                            }
                          />
                        </form>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <p>
                        priority: {option.priority}
                        <form noValidate autoComplete="off">
                          <TextField
                            defaultValue={option.priority}
                            type="number"
                            id="filled-basic"
                            label="priority"
                            variant="filled"
                            onChange={ev =>
                              setUpdateOptionRequest({
                                ...updateOptionRequest,
                                priority: Number(ev.target.value),
                              })
                            }
                          />
                        </form>
                        <Button onClick={() => applyOptionChange(option.optionId!)}>Apply</Button>
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    margin: '10px',
                    padding: '10px',
                    background: 'white',
                    border: 'solid 1px black',
                  }}
                >
                  <h2>Choices</h2>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    style={{ border: '1px solid #4a4a4a' }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Company ID</TableCell>
                        <TableCell align="right">Shop ID</TableCell>
                        <TableCell align="right">Shop Name</TableCell>
                        <TableCell align="right">Choice ID</TableCell>
                        <TableCell align="right">Choice Name</TableCell>
                        <TableCell align="right">Choice NameEn</TableCell>
                        <TableCell align="right">Choice ReceiptDisplayName</TableCell>
                        <TableCell align="right">Choice Description</TableCell>
                        <TableCell align="right">Choice DescriptionEn</TableCell>
                        <TableCell align="right">price</TableCell>
                        <TableCell align="right">posChoiceId</TableCell>
                        <TableCell align="right">max orderable num</TableCell>
                        <TableCell align="right">priority</TableCell>
                        <TableCell align="left">IsDefaultSelection</TableCell>
                        <TableCell align="left">IsDisplay</TableCell>
                        <TableCell align="left">IsSoldout</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {option.choices?.map(choice => (
                        <TableRow key={choice.choiceId}>
                          <TableCell>{choice.companyId}</TableCell>
                          <TableCell>{choice.shopId}</TableCell>
                          <TableCell>{shop.name}</TableCell>
                          <TableCell>{choice.choiceId}</TableCell>
                          <TableCell>
                            {choice.name}
                            <p>
                              choice name: {choice.name}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.name}
                                  type="string"
                                  id="filled-basic"
                                  label="name"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      name: ev.target.value,
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            {choice.nameEn}
                            <p>
                              choice nameEn: {choice.nameEn}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.nameEn}
                                  type="string"
                                  id="filled-basic"
                                  label="name en"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      nameEn: ev.target.value,
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            {choice.receiptDisplayName}
                            <p>
                              choice receiptDisplayName: {choice.receiptDisplayName}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.receiptDisplayName}
                                  type="string"
                                  id="filled-basic"
                                  label="receiptDisplayName"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      receiptDisplayName: ev.target.value,
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            {choice.description}
                            <p>
                              choice description: {choice.description}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.description}
                                  type="string"
                                  id="filled-basic"
                                  label="description"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      description: ev.target.value,
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            {choice.descriptionEn}
                            <p>
                              choice descriptionEn: {choice.descriptionEn}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.descriptionEn}
                                  type="string"
                                  id="filled-basic"
                                  label="descriptionEn"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      descriptionEn: ev.target.value,
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            <p>
                              choice price: {choice.price}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.price}
                                  type="number"
                                  id="filled-basic"
                                  label="price"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      price: Number(ev.target.value),
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            <p>
                              max orderable num: {choice.maxOrderableNum}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.maxOrderableNum}
                                  type="number"
                                  id="filled-basic"
                                  label="maxOrderableNum"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      maxOrderableNum: Number(ev.target.value),
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            <p>
                              posChoiceId: {choice.posChoiceId}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.posChoiceId}
                                  type="number"
                                  id="filled-basic"
                                  label="posChoiceId"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      posChoiceId: Number(ev.target.value),
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            <p>
                              priority: {choice.priority}
                              <form noValidate autoComplete="off">
                                <TextField
                                  defaultValue={choice.priority}
                                  type="number"
                                  id="filled-basic"
                                  label="priority"
                                  variant="filled"
                                  onChange={ev =>
                                    updateChoiceRequest(choice.choiceId, {
                                      ...updateOptionRequest,
                                      priority: Number(ev.target.value),
                                    })
                                  }
                                />
                              </form>
                              <Button onClick={() => applyChoiceChange(choice.choiceId!)}>
                                Apply
                              </Button>
                            </p>
                          </TableCell>
                          <TableCell>
                            {choice.isDefaultSelection}
                            <Switch
                              required
                              checked={choice.isDefaultSelection}
                              onChange={() =>
                                toggleChoice(optionIndex, choice, 'isDefaultSelection')
                              }
                              value="checkedA"
                            />
                          </TableCell>
                          <TableCell>
                            {choice.isDisplay}
                            <Switch
                              required
                              checked={choice.isDisplay}
                              onChange={() => toggleChoice(optionIndex, choice, 'isDisplay')}
                              value="checkedA"
                            />
                          </TableCell>
                          <TableCell>
                            {choice.isSoldout}
                            <Switch
                              required
                              checked={choice.isSoldout}
                              onChange={() => toggleChoice(optionIndex, choice, 'isSoldout')}
                              value="checkedA"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ width: '70vw', marginBottom: '30px', marginTop: '30px' }}
                    onClick={() => {
                      setSelectedOptionId(option.optionId);
                      setCreateChoiceModalOpen(true);
                    }}
                  >
                    Create Choice
                  </Button>
                </div>
              </Paper>
            ))}
            <Button onClick={onClose}>close</Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogMessage !== null}
          onClose={() => setDialogMessage(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Result</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
        <CreateOption
          reload={() =>
            menu !== undefined && menu.menuId ? reloadMenu(menu.menuId) : console.log('no menu')
          }
          updateOption={updatedOption => setOptions(prev => [...prev, updatedOption])}
          menu={menu}
          isOpen={createOptionModalOpen}
          onClose={() => setCreateOptionModalOpen(false)}
        />
        {selectedOptionId && (
          <CreateChoice
            updateChoice={choice =>
              setOptions(prev =>
                prev.map(p => {
                  if (p.optionId === choice.optionId) {
                    return {
                      ...p,
                      choices: [...(p.choices ? p.choices : []), choice],
                    };
                  } else {
                    return p;
                  }
                }),
              )
            }
            menu={menu}
            optionId={selectedOptionId}
            isOpen={createChoiceModalOpen}
            onClose={() => setCreateChoiceModalOpen(false)}
          />
        )}
      </>
    )
  );
};

export default OptionDialog;
