import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MainContext } from '../context/MainContext';
import { Shop } from '../entity/shop.entity';
import { Company } from '../entity/company.entity';
import request from '../plugins/api';

const KEY_SHOP = 'key.context.shop';
export const useShops = (canReload?: boolean) => {
  const [mainContext, setMainContext] = useContext(MainContext);
  const { shops, companies, selectedShop } = mainContext;

  // TODO: use reducer
  const setCompanies = useCallback(
    (companies: Company[]) => {
      setMainContext({
        ...mainContext,
        companies: [...companies],
      });
    },
    [mainContext, setMainContext],
  );

  const setShops = useCallback(
    (shops: Shop[]) => {
      setMainContext({
        ...mainContext,
        shops: [...shops],
      });
    },
    [mainContext, setMainContext],
  );

  const setSelectedShop = useCallback(
    (shop: Shop | null) => {
      setMainContext({
        ...mainContext,
        selectedShop: shop,
      });
    },
    [mainContext, setMainContext],
  );

  const [isLoading, setIsLoading] = useState(false);

  const setIsInitialized = useCallback(
    (isInitialized: boolean) => {
      setMainContext({
        ...mainContext,
        isInitialized,
      });
    },
    [mainContext, setMainContext],
  );

  // load context
  useEffect(() => {
    if (canReload) {
      const f = async () => {
        if (mainContext.isInitialized) return;
        setIsLoading(true);
        try {
          let selectedShop: Shop | null = null;
          if (localStorage.getItem(KEY_SHOP)) {
            const cacheShop = JSON.parse(localStorage.getItem(KEY_SHOP)!);
            if (cacheShop.name && cacheShop.shopId && cacheShop.companyId) {
              selectedShop = cacheShop as Shop;
            }
          }

          const companyResponse = await request.get('companies', {
            headers: { 'x-company-id': 1 },
          });
          const companies = companyResponse.data as Company[];
          const shopResponse = await request.get('shops/all', {
            headers: { 'x-company-id': companies[0].companyId },
          });
          setMainContext(
            Object.assign(mainContext, {
              shops: (shopResponse.data as Shop[]).sort(
                (shop1, shop2) => shop1.companyId - shop2.companyId,
              ),
              companies,
              selectedShop,
              isInitialized: true,
            }),
          );
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      f().then();
    }
  }, [canReload, mainContext, mainContext.isInitialized, setMainContext]);

  const selectedCompany = useMemo(() => {
    return selectedShop?.companyId
      ? companies.find(c => c.companyId === selectedShop?.companyId)
      : undefined;
  }, [selectedShop, companies]);

  useEffect(() => {
    if (selectedShop) {
      localStorage.setItem(KEY_SHOP, JSON.stringify(selectedShop));
    }
  }, [selectedShop]);

  return {
    shops,
    companies,
    selectedShop,
    setSelectedShop,
    setShops,
    setCompanies,
    isLoading,
    setIsLoading,
    setIsInitialized,
    selectedCompany,
  };
};
