import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import QRCode from 'qrcode.react';
import request, { getBaseURL } from '../../../plugins/api';
import { CompanyConfig } from '../../../entity/companyConfig.entity';
import { TableData } from '../../../entity/table.entity';
import dataTableRowComponent from '../components/dataTableRowComponent';
import ApiResponseDialogComponent from '../components/apiResponseDialog';
import CreateTableDialog from './CreateTableDialog/CreateTableDialog';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const backendUrl = () => {
  return getBaseURL()
    ?.replace('https://', '')
    .replace('http://', '');
};

const Tables: React.FC = () => {
  const classes = useStyles();
  const [tables, setTables] = useState([] as TableData[]);
  const [companyConfigMap, setCompanyConfigMap] = useState<CompanyConfig[]>([]);
  const { selectedCompany, selectedShop, companies } = useShops();
  const [checkInPrefix, setCheckInPrefix] = useState('https://localhost:3000');
  const [liffId, setLiffId] = useState('');
  const [editedTableName, setEditedTableName] = useState(null as null | string);
  const [editTableNameIndex, setEditTableNameIndex] = useState(null as null | number);
  const [editedPosTableId, setEditedPosTableId] = useState(null as null | string);
  const [editPosTableIdIndex, setEditPosTableIdIndex] = useState(null as null | number);
  const [tableUserId, setTableUserId] = useState('');
  // dialog
  const [isOpenLinkDialog, setIsOpenLinkDialog] = useState(false);
  const [isOpenCreateTableDialog, setIsOpenCreateTableDialog] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null as null | TableData);
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  useEffect(() => {
    if (!companies || companies.length === 0) {
      return;
    }
    if (companyConfigMap.length !== 0) {
      return;
    }
    const { companyId } = companies[0];
    request
      .get(`company_configs`, {
        headers: { 'x-company-id': companyId },
      })
      .then(response => {
        setCompanyConfigMap(response.data);
      });
  }, [companies, companyConfigMap.length]);

  const findConfig = useCallback(
    (companyId: number, configKey: keyof CompanyConfig) => {
      const config = companyConfigMap.find(companyConfig => {
        return companyConfig.companyId === companyId;
      });
      if (!config) return null;
      return config[configKey];
    },
    [companyConfigMap],
  );

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/tables`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setTables(response.data);
      });

    // NOTE: 選択された店舗が更新されたタイミングで、liffIdも更新する
    //  shop.liffId のマスクがまだなので，ステージングではshop.liffIdを使わないようにしておく
    const stage = process.env.REACT_APP_FIREBASE_PROJECT;
    const isStag = stage && stage.indexOf('stag') !== -1;

    if (!isStag && selectedShop.liffId) {
      setLiffId(selectedShop.liffId);
    } else {
      const companyConfigLiffId = findConfig(selectedShop.companyId, 'liffId') as string | null;
      if (companyConfigLiffId) {
        setLiffId(companyConfigLiffId);
      }
    }
  }, [selectedShop, findConfig]);

  const getCheckInUrl = (table: TableData): string => {
    return `${checkInPrefix}/check_in?companyId=${table.companyId}&shopId=${table.shopId}&tableId=${table.tableId}`;
  };

  const getDeepLinkCheckInUrl = (table: TableData): string => {
    return `https://liff.line.me/${liffId}?companyId=${table.companyId}&shopId=${table.shopId}&tableId=${table.tableId}`;
  };

  const getRedirectBaseUrl = (table: TableData): string => {
    return `https://${backendUrl()}/gateway?tableCode=${table.code}`;
  };

  const getBackToHomeMessagingUrl = (table: TableData, tableUserId: string): string => {
    const noTralingSlashBackendUrl = backendUrl();
    return `https://liff.line.me/${liffId}/?redirectTo=https%253A%252F%252F${noTralingSlashBackendUrl}%252Fgateway%253FcompanyId%253D${table.companyId}%2526shopId%253D${table.shopId}%2526tableUserId%253D${tableUserId}`;
  };

  const getReceiptMessagingUrl = (table: TableData, tableUserId: string): string => {
    return `https://liff.line.me/${liffId}?companyId=${table.companyId}&shopId=${table.shopId}&tableUserId=${tableUserId}&navigateTo=receipt`;
  };

  const cancelEdit = () => {
    setEditTableNameIndex(null);
    setEditedTableName(null);
  };
  const applyChange = (updateValue: any, updateKey: string, editedIndex: number) => {
    if (!selectedShop) return;
    const targetTable = tables[editedIndex];
    request
      .post(
        `shops/${selectedShop.shopId}/tables/${targetTable.tableId}`,
        { [updateKey]: updateValue },
        { headers: { 'x-company-id': selectedShop.companyId } },
      )
      .then(response => {
        setTables(
          tables.map(table => {
            if (table.tableId === targetTable.tableId) {
              return response.data;
            }
            return table;
          }),
        );
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
    cancelEdit();
  };

  const downloadQRs = () => {
    if (tables.length === 0) return;
    const element = document.createElement('a');
    const file = new Blob(
      [
        'このファイルを長谷川に送ってください\n' +
          `[${tables.map(t => `'${getDeepLinkCheckInUrl(t)}'`).join(',')}]`,
      ],
      { type: 'text/plain' },
    );
    element.href = URL.createObjectURL(file);
    element.download = 'urls.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const downloadNewQRs = () => {
    if (tables.length === 0) return;
    const element = document.createElement('a');
    const file = new Blob(
      [
        'このファイルを長谷川に送ってください\n' +
          `[${tables.map(t => `'${getRedirectBaseUrl(t)}'`).join(',')}]`,
      ],
      { type: 'text/plain' },
    );
    element.href = URL.createObjectURL(file);
    element.download = 'urls.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <Button onClick={() => setIsOpenCreateTableDialog(true)}>Create Table</Button>
      <form noValidate autoComplete="off">
        <TextField
          style={{ width: '300px' }}
          defaultValue={checkInPrefix}
          id="filled-basic"
          label="CheckIn Base URL"
          variant="filled"
          onChange={ev => setCheckInPrefix(ev.target.value)}
        />
      </form>
      <Button variant={'contained'} disabled={tables.length === 0} onClick={downloadQRs}>
        [Old LIFF] Download QR sources
      </Button>
      <Button variant={'contained'} disabled={tables.length === 0} onClick={downloadNewQRs}>
        [New Redirect-base] Download QR sources
      </Button>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">CheckIn Url</TableCell>
              <TableCell align="right">Table ID</TableCell>
              <TableCell align="right">Table Name</TableCell>
              <TableCell align="right">POS Table ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tables.map((table, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {table.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {selectedCompany?.name}
                </TableCell>
                <TableCell align="right">{table.shopId}</TableCell>
                <TableCell align="right">{selectedShop?.name}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setSelectedTable(table);
                      setIsOpenLinkDialog(true);
                    }}
                    color="primary"
                  >
                    open QR dialog
                  </Button>
                </TableCell>
                <TableCell align="right">{table.tableId}</TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: table.name,
                    startEdit: () => setEditTableNameIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedTableName, 'name', index),
                    formClassName: classes.form,
                    setValue: val => setEditedTableName(val),
                    isShowEditForm: editTableNameIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
                <TableCell align="right">
                  {dataTableRowComponent({
                    defaultValue: table.posTableId,
                    startEdit: () => setEditPosTableIdIndex(index),
                    cancelEdit,
                    applyChange: () => applyChange(editedPosTableId, 'posTableId', index),
                    formClassName: classes.form,
                    setValue: val => setEditedPosTableId(val),
                    isShowEditForm: editPosTableIdIndex === index,
                    dataType: 'string',
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={isOpenLinkDialog}
        onClose={() => {
          setIsOpenLinkDialog(false);
          setSelectedTable(null);
        }}
      >
        <DialogTitle>QR</DialogTitle>
        <DialogContent>
          {selectedTable && (
            <>
              <p style={{ fontSize: '24px' }}>Local host</p>
              <p> {getCheckInUrl(selectedTable!)}</p>
              <QRCode value={getCheckInUrl(selectedTable!)} />

              <p style={{ fontSize: '24px', borderTop: 'dotted 1px black' }}>DeepLink</p>
              <p>{getDeepLinkCheckInUrl(selectedTable!)}</p>
              <QRCode value={getDeepLinkCheckInUrl(selectedTable!)} />

              <p style={{ fontSize: '24px', borderTop: 'dotted 1px black' }}>
                リダイレクト基盤チェックイン
              </p>
              <p>{getRedirectBaseUrl(selectedTable!)}</p>
              <QRCode value={getRedirectBaseUrl(selectedTable!)} />

              <p style={{ fontSize: '24px', borderTop: 'dotted 1px black' }}>
                LINE Messaging 復帰リンク
                <TextField
                  type="string"
                  id="filled-basic"
                  label="TableUserId"
                  variant="filled"
                  onChange={ev => setTableUserId(ev.target.value)}
                />
              </p>
              <p>{getBackToHomeMessagingUrl(selectedTable!, tableUserId)}</p>
              <QRCode value={getBackToHomeMessagingUrl(selectedTable!, tableUserId)} />

              <p style={{ fontSize: '24px', borderTop: 'dotted 1px black' }}>
                LINE Messaging 領収書リンク
                <TextField
                  type="string"
                  id="filled-basic"
                  label="TableUserId"
                  variant="filled"
                  onChange={ev => setTableUserId(ev.target.value)}
                />
              </p>
              <p>{getReceiptMessagingUrl(selectedTable!, tableUserId)}</p>
              <QRCode value={getReceiptMessagingUrl(selectedTable!, tableUserId)} />
            </>
          )}
        </DialogContent>
      </Dialog>
      {selectedShop && (
        <CreateTableDialog
          isOpen={isOpenCreateTableDialog}
          onClose={() => setIsOpenCreateTableDialog(false)}
          companyId={selectedShop.companyId}
          shopId={selectedShop.shopId}
          updateTable={(updatedTable: TableData) => {
            setTables(prev => [...prev, updatedTable]);
          }}
        />
      )}
      <ApiResponseDialogComponent
        dialogMessage={dialogMessage}
        resetDialogMessage={() => setDialogMessage(null)}
      />
    </>
  );
};

export default Tables;
