import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import CreateShopClientDialog from './CreateShopClientDialog/CreateShopClientDialog';
import SetShopClientClaimDialog from './CreateShopClientDialog/SetShopClientClaimDialog';
import { ShopClient } from '../../../entity/shopClient.entity';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

enum ShopClientStatus {
  available = '使用可能',
  unavailable = '使用不可能',
}

const getShopClientStatus = (shopClient: ShopClient): ShopClientStatus => {
  try {
    if (Object.hasOwnProperty.call(shopClient.customClaims, 'shopId')) {
      return ShopClientStatus.available;
    } else {
      return ShopClientStatus.unavailable;
    }
  } catch (err) {
    console.error(err);
    return ShopClientStatus.unavailable;
  }
};

const ShopClients: React.FC = () => {
  const classes = useStyles();
  const [shopClients, setShopClients] = useState([] as ShopClient[]);
  const { selectedShop } = useShops();
  const [createShopClientModalOpen, setCreateShopClientModalOpen] = useState(false);
  const [selectedShopClient, setSelectedShopClient] = useState(null as null | ShopClient);

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/shop_clients`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setShopClients(response.data);
      });
  }, [selectedShop]);
  const firebaseProject = process.env.REACT_APP_FIREBASE_PROJECT;
  let stage = 'stag';
  if (firebaseProject && firebaseProject.indexOf('stag') === -1) {
    stage = 'prod';
  }
  return (
    <>
      {stage === 'stag' && (
        <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
          <p>
            <h3>ステージング用のログインアカウントについて</h3>
            <p>
              <b>ステージングでのパスワードは共通して testshop2020 になります</b>
            </p>
          </p>
        </div>
      )}
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '20vw', marginBottom: '30px' }}
        onClick={() => setCreateShopClientModalOpen(true)}
      >
        Create shopClient
      </Button>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">ShopClient ID</TableCell>
              <TableCell align="right">ShopClient Name</TableCell>
              <TableCell align="right">FirebaseAuth ID</TableCell>
              <TableCell align="right">FirebaseAuth Email</TableCell>
              <TableCell align="right">roles</TableCell>
              <TableCell align="right">この環境で使用できるか</TableCell>
              <TableCell align="right">custom claims</TableCell>
              <TableCell align="right">set custom claims</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopClients.map((shopClient, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {shopClient.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  company name
                </TableCell>
                <TableCell align="right">{shopClient.shopId}</TableCell>
                <TableCell align="right">{selectedShop && selectedShop.name}</TableCell>
                <TableCell align="right">{shopClient.shopClientId}</TableCell>
                <TableCell align="right">{shopClient.name}</TableCell>
                <TableCell align="right">TBD</TableCell>
                <TableCell align="right">{shopClient.email}</TableCell>
                <TableCell align="right">{shopClient.roles}</TableCell>
                <TableCell
                  align="right"
                  className={
                    getShopClientStatus(shopClient) === ShopClientStatus.available
                      ? classes.okCell
                      : classes.ngCell
                  }
                >
                  {getShopClientStatus(shopClient)}
                </TableCell>
                <TableCell align="right">
                  {JSON.stringify(shopClient.customClaims, null, 2)}
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedShopClient(shopClient)}
                  >
                    set custom claim
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {selectedShop && (
        <CreateShopClientDialog
          shop={selectedShop}
          isOpen={createShopClientModalOpen}
          onClose={() => setCreateShopClientModalOpen(false)}
        />
      )}
      {selectedShopClient && (
        <SetShopClientClaimDialog
          shopClient={selectedShopClient}
          isOpen={selectedShopClient !== null}
          onClose={() => setSelectedShopClient(null)}
        />
      )}
    </>
  );
};

export default ShopClients;
