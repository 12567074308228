import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  TextField,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

interface PosConfig {
  ip: string;
  basePath: string;
  seqNum: number;
}
const getPosConfig = async (companyId: number, shopId: string): Promise<PosConfig> => {
  const res = await request.get(`pos/config/${shopId}`, {
    headers: { 'x-company-id': companyId },
  });
  return res.data as PosConfig;
};

const Pos: React.FC = () => {
  const classes = useStyles();
  // TODO: add setting
  const { shops, companies, setSelectedShop, selectedShop, setShops } = useShops();
  const [posConfig, setPosConfig] = useState(null as PosConfig | null);
  const [updatePosValue, setUpdatePosValue] = useState({} as Partial<PosConfig>);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  // TODO: imple after backend api
  const applyChange = () => {
    if (!selectedShop) return;
    request
      .post(`pos/config/${selectedShop.shopId}`, updatePosValue, {
        headers: { 'x-company-id': selectedShop!.companyId },
      })
      .then(res => {
        setPosConfig(res.data);
        setDialogMessage('update success');
      })
      .catch(err => setDialogMessage(JSON.stringify(err)));
  };

  // get pos config
  useEffect(() => {
    if (!selectedShop) return;
    getPosConfig(selectedShop.companyId, selectedShop.shopId).then(conf => {
      setPosConfig(conf);
    });
  }, [selectedShop]);

  const reloadConfig = () => {
    if (!selectedShop) return;
    getPosConfig(selectedShop.companyId, selectedShop.shopId).then(conf => {
      setPosConfig(conf);
      setUpdatePosValue(conf);
    });
  };

  const enablePos = () => {
    if (!selectedShop) return;
    if (!selectedShop.enablePos) {
      if (
        window.confirm(
          'この機能をONにすると，POS連携モードになります。本当に有効化してよろしいですか？',
        )
      ) {
        request
          .post(
            `shops/${selectedShop.shopId}`,
            { enablePos: true },
            { headers: { 'x-company-id': selectedShop!.companyId } },
          )
          .then(response => {
            setShops(
              shops.map(existingShop => {
                if (existingShop.shopId === response.data.shopId) {
                  return response.data;
                }
                return existingShop;
              }),
            );
            setSelectedShop(response.data);
            setDialogMessage('update success');
          })
          .catch(err => setDialogMessage(JSON.stringify(err)));
      }
    } else {
      if (
        window.confirm(
          'この機能をOFFにすると，POS連携モードが解除されます。本当に有効化してよろしいですか？',
        )
      ) {
        request
          .post(
            `shops/${selectedShop.shopId}`,
            { enablePos: false },
            { headers: { 'x-company-id': selectedShop!.companyId } },
          )
          .then(response => {
            setShops(
              shops.map(existingShop => {
                if (existingShop.shopId === response.data.shopId) {
                  return response.data;
                }
                return existingShop;
              }),
            );
            setSelectedShop(response.data);
            setDialogMessage('update success');
          })
          .catch(err => setDialogMessage(JSON.stringify(err)));
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedShopId = event.target.value as string;
    const shop = shops.find(mShop => mShop.shopId === selectedShopId);
    if (!shop) return;
    const company = companies.find(mCompany => mCompany.companyId === shop.companyId);
    if (!company) return;
    setSelectedShop(shop);
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>Note: todo</div>
      <Button onClick={reloadConfig}>reload</Button>
      <FormControl className={classes.formControl} style={{ width: '300px' }}>
        <InputLabel id="demo-simple-select-label">CompanyId/ShopName selection</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedShop && selectedShop.shopId}
          onChange={handleChange}
        >
          {shops.map(shop => (
            <MenuItem
              value={shop.shopId}
              key={shop.shopId}
            >{`${shop.companyId}/${shop.name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Enable POS</TableCell>
              <TableCell align="left">Pos IP</TableCell>
              <TableCell align="left">Pos basePath</TableCell>
              <TableCell align="right">Pos seqNum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posConfig && (
              <TableRow>
                <TableCell align="right">
                  <Switch required checked={selectedShop?.enablePos} onChange={() => enablePos()} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <p>{posConfig.ip}</p>
                  <TextField
                    defaultValue={posConfig.ip}
                    value={updatePosValue?.ip}
                    type="string"
                    id="filled-basic"
                    label="Filled"
                    variant="filled"
                    onChange={ev => {
                      ev.persist();
                      setUpdatePosValue(prev => ({ ...prev, ip: ev.target.value }));
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <p>{posConfig.basePath}</p>
                  <TextField
                    defaultValue={posConfig.basePath}
                    value={updatePosValue?.basePath}
                    type="string"
                    id="filled-basic"
                    label="basePath"
                    variant="filled"
                    onChange={ev => {
                      ev.persist();
                      setUpdatePosValue(prev => ({ ...prev, basePath: ev.target.value }));
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <p>{`${posConfig.seqNum}`}</p>
                  <TextField
                    defaultValue={posConfig.seqNum}
                    value={updatePosValue?.seqNum}
                    type="number"
                    id="filled-basic"
                    label="seqNum"
                    variant="filled"
                    onChange={ev => {
                      ev.persist();
                      setUpdatePosValue(prev => ({ ...prev, seqNum: Number(ev.target.value) }));
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Button onClick={applyChange}>update</Button>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default Pos;
