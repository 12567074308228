import React, { useEffect, useState } from 'react';
import Main from './Main';
import firebase from '../plugins/firebase';
import Login from './Login';

const allowEmails = ['admin-viewer@dinii.jp', 'nec-admin@dinii.jp'];

const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    const f = firebase.auth().onAuthStateChanged(user => {
      if (user && user.email && allowEmails.includes(user.email)) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    });
    return f;
  }, [isLogin]);
  return isLogin ? <Main /> : <Login />;
};

export default Auth;
