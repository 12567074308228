import React, { useCallback, useEffect, useState } from 'react';
import firebase from '../../../../plugins/firebase';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Role } from '../../../../entity/role.entity';
import request from '../../../../plugins/api';
import { green, red } from '@material-ui/core/colors';

interface Props {
  isOpen: boolean;
  companyId?: string;
  shopId?: string;
  uid?: string;
  onClose: () => void;
}

interface Settings {
  roleId: number;
  roleName: string;
  macAddress: string;
  target: string;
  deviceName: string;
  deviceType: number;
  bdAddress: string;
  ipAddress: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

export const PrinterSettingsDialog: React.FC<Props> = ({
  isOpen,
  shopId,
  uid,
  companyId,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [settings, setSettings] = useState([] as Settings[]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(Date.now());

  const saveTarget = useCallback(
    async (roleSettings: Settings) => {
      setIsLoading(true);
      try {
        await firebase
          .firestore()
          .collection('deviceRemoteManagement')
          .doc(uid)
          .collection('printerSettings')
          .doc(roleSettings.roleId.toString())
          .set(roleSettings);
        setUpdatedAt(Date.now());
        window.alert('success');
      } catch (e) {
        console.log(e);
        window.alert('fail');
      } finally {
        setIsLoading(false);
      }
    },
    [uid],
  );

  const deleteTarget = useCallback(
    async (roleSettings: Settings) => {
      setIsLoading(true);
      try {
        await firebase
          .firestore()
          .collection('deviceRemoteManagement')
          .doc(uid)
          .collection('printerSettings')
          .doc(roleSettings.roleId.toString())
          .delete();
        setUpdatedAt(Date.now());
        window.alert('success');
      } catch (e) {
        console.log(e);
        window.alert('fail');
      } finally {
        setIsLoading(false);
      }
    },
    [uid],
  );

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);

      const response = await request.get(`shops/${shopId}/roles`, {
        headers: { 'x-company-id': companyId },
      });
      const roles = response.data as Role[];
      const r = await firebase
        .firestore()
        .collection('deviceRemoteManagement')
        .doc(uid)
        .collection('printerSettings')
        .get();
      const settings = r.docs.map(d => d.data() as Settings);
      setSettings(
        roles.map(role => {
          return (
            settings.find(s => s.roleId === role.roleId) || {
              ...role,
              roleName: role.name,
              macAddress: '',
              target: '',
              deviceName: '',
              deviceType: 0,
              bdAddress: '',
              ipAddress: '',
            }
          );
        }),
      );
      setIsLoading(false);
    };
    if (uid) {
      f();
    }
  }, [updatedAt, companyId, shopId, uid]);

  return (
    <>
      <Dialog className={classes.table} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">{`${companyId}-${shopId}-${uid}`}</DialogTitle>
        <DialogTitle id="scroll-dialog-title">
          targetは「TCP:MACAddress」の形で指定してください
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">role</TableCell>
                  <TableCell align="left">target</TableCell>
                  <TableCell align="left">ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings.map((setting, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {setting.roleId}:{setting.roleName}
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          value={setting.target}
                          onChange={e => {
                            const newTarget = e.target.value as string;
                            setSettings(
                              settings.map(m => {
                                if (m.roleId === setting.roleId) {
                                  return Object.assign(m, {
                                    target: newTarget,
                                  });
                                } else {
                                  return m;
                                }
                              }),
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          onClick={() => saveTarget(setting)}
                          disabled={!setting.target.includes(':')}
                        >
                          保存
                        </Button>
                        <Button onClick={() => deleteTarget(setting)} color={'secondary'}>
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
