import React from 'react';
import { Paper, TextField } from '@material-ui/core';

interface Props {
  setCompany: (data: {}) => void;
}

// TODO: companyとcompanyConfigの設定
const CompanySetting: React.FC<Props> = ({ setCompany }) => {
  return (
    <Paper style={{ padding: '10px', margin: '10px' }}>
      <h1>会社情報の登録</h1>
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="companyName"
        label="会社名"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setCompany({ name: ev.target.value });
        }}
      />
      <TextField
        style={{ margin: '10px' }}
        type="string"
        id="stripeId"
        label="Stripe ID"
        variant="outlined"
        onChange={ev => {
          ev.persist();
          setCompany({ stripeOwnerId: ev.target.value });
        }}
      />
    </Paper>
  );
};

export default CompanySetting;
