import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import { MenuType } from '../../../entity/menuType.entity';
import { Category } from '../../../entity/category.entity';
import { Role } from '../../../entity/role.entity';
import moment from 'moment-timezone';
import firebase from '../../../plugins/firebase';
import { firestoreVersionCode } from '../../../constants';
import { Order, PrintStatus } from '../../../entity/order.entity';
import PrintStatusComponent from '../components/printStatusComponent';
import TestOrderDialog from './TestOrderDialog';
import { isStaging } from '../../Constants';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Orders: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const { selectedShop } = useShops();
  const [categories, setCategories] = useState([] as Category[]);
  const [menuTypes, setMenuTypes] = useState([] as MenuType[]);
  const [roles, setRoles] = useState([] as Role[]);
  const [orders, setOrders] = useState([] as Order[]);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [openTestOrderDialog, setOpenTestOrderDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null as null | Order);

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/categories`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setCategories(response.data);
      });
    request
      .get(`shops/${selectedShop.shopId}/menu_types`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setMenuTypes(response.data);
      });
    request
      .get(`shops/${selectedShop.shopId}/roles`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setRoles(response.data);
      });
    const queryStartAt = moment()
      .subtract(3, 'h')
      .unix();
    const unsubscribe = firebase
      .firestore()
      .collection(`versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/orders`)
      .where('orderedAt', '>', queryStartAt)
      .orderBy('orderedAt', 'desc')
      .onSnapshot(snapshot => {
        const orders = snapshot.docs.map(snapshot => snapshot.data()) as Order[];
        setOrders(orders);
      });
    return unsubscribe;
  }, [selectedShop]);

  const toPrinted = (order: Order) => {
    updatePrintStatus(order, 'printed');
  };
  const toUnPrint = (order: Order) => {
    updatePrintStatus(order, 'unPrint');
  };
  const updatePrintStatus = (order: Order, to: 'printed' | 'unPrint') => {
    const currentStatus = order.status;
    const updatedStatus = {} as PrintStatus;
    Object.keys(currentStatus).forEach(_roleId => {
      const roleId = Number(_roleId);
      updatedStatus[roleId] = {
        isPrinted: to === 'printed',
        isRead: to === 'printed',
      };
    });
    request.post(
      `shops/${order.shopId}/orders/${order.orderId}`,
      { status: updatedStatus },
      { headers: { 'x-company-id': order.companyId } },
    );
  };
  const addRole = (role: Role, order: Order) => {
    if (!selectedShop) return;
    const currentOrderStatus = order.status;
    const newOrderStatus = {
      ...currentOrderStatus,
      [role.roleId]: {
        isRead: false,
        isPrinted: false,
      },
    };
    firebase
      .firestore()
      .collection(`versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/orders`)
      .doc(order.orderId)
      .update({ status: newOrderStatus });
    setSelectedOrder(null);
  };
  return (
    <>
      {isStaging() && <Button onClick={() => setOpenTestOrderDialog(true)}>test order</Button>}
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        Note: 過去48時間分のみ表示，リアルタイム更新
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">status</TableCell>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Order ID</TableCell>
              <TableCell align="right">Ordered at</TableCell>
              <TableCell align="right">menu id</TableCell>
              <TableCell align="right">menu name</TableCell>
              <TableCell align="right">quantity</TableCell>
              <TableCell align="right">備考</TableCell>
              <TableCell align="right">price</TableCell>
              <TableCell align="right">tableName</TableCell>
              <TableCell align="right">category</TableCell>
              <TableCell align="right">menuType</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow key={index}>
                <TableCell align="right">
                  <PrintStatusComponent printStatus={order.status} roles={roles} />
                  <Button onClick={() => toUnPrint(order)}>未印刷にする</Button>
                  <Button onClick={() => toPrinted(order)}>印刷済みにする</Button>
                  <Button color="secondary" onClick={() => setSelectedOrder(order)}>
                    印刷先を追加する
                  </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {order.companyId}
                </TableCell>
                <TableCell align="right">{order.shopId}</TableCell>
                <TableCell align="right">{selectedShop && selectedShop.name}</TableCell>
                <TableCell align="right">{order.orderId}</TableCell>
                <TableCell align="right">
                  {moment(order.orderedAt * 1000)
                    .tz('Asia/Tokyo')
                    .format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell align="right">{order.menuId}</TableCell>
                <TableCell align="right">{order.name}</TableCell>
                <TableCell align="right">{order.quantity}個</TableCell>
                <TableCell align="right">{order.description || ''}</TableCell>
                <TableCell align="right">
                  <p>本体 {order.price}円</p>
                  <p>本体+option {order.unitPriceWithOption}円</p>
                  <p>all {order.priceAll}円</p>
                </TableCell>
                <TableCell align="right">{order.tableName}</TableCell>
                <TableCell align="right">
                  {order.categoryId}:
                  {categories.find(c => c.categoryId === order.categoryId)?.name || 'no-name'}
                </TableCell>
                <TableCell align="right">
                  {order.menuTypeId}:
                  {menuTypes.find(c => c.menuTypeId === order.menuTypeId)?.name || 'no-name'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogActions />
      </Dialog>
      <Dialog
        open={selectedOrder !== null}
        onClose={() => setSelectedOrder(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Role</DialogTitle>
        {roles.map(role => {
          return (
            <Button key={role.roleId} onClick={() => addRole(role, selectedOrder!)}>
              {role.name}
            </Button>
          );
        })}
        <DialogActions />
      </Dialog>
      {selectedShop && (
        <TestOrderDialog
          shop={selectedShop}
          isOpen={openTestOrderDialog}
          roles={roles}
          onClose={() => {
            setOpenTestOrderDialog(false);
          }}
        />
      )}
    </>
  );
};

export default Orders;
