import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import firebase from '../../../plugins/firebase';
import { Company } from '../../../entity/company.entity';
import { Shop } from '../../../entity/shop.entity';
import request from '../../../plugins/api';
import moment from 'moment-timezone';
import OldDeviceLogsDialog from './OldDeviceLogsDialog/OldDeviceLogsDialog';
import { Role } from '../../../entity/role.entity';
import { ShopClient } from '../../../entity/shopClient.entity';
const oldCompanyIds = [1, 25, 27];
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface OldDeviceStatus {
  lastRebootedAt?: any;
  lastUpdatedAt: any;
  lastUpdatedType: string;
  companyId: number;
  shopId: string;
  roleId: number;
  battery: number;
}

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm');

const isToday = (date: Date) => {
  const now = moment
    .tz('Asia/Tokyo')
    .hour(0)
    .minute(0)
    .second(0);
  return moment(date).diff(now) > 0;
};

const OldDeviceStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as OldDeviceStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as OldDeviceStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);
  const [shops, setShops] = useState([] as Shop[]);
  const [roles, setRoles] = useState([] as Role[]);
  const [isOpenDeviceActionDialog, setIsOpenDeviceActionDialog] = useState(false);
  const [shopClients, setShopClients] = useState([] as ShopClient[]);

  useEffect(() => {
    request.get('companies', { headers: { 'x-company-id': 1 } }).then(response => {
      setCompanies(response.data);
    });
  }, []);
  useEffect(() => {
    if (shops.length !== 0) return;
    companies
      .filter(c => oldCompanyIds.includes(c.companyId))
      .forEach(company => {
        request
          .get('shops', {
            headers: { 'x-company-id': company.companyId },
          })
          .then(response => {
            setShops(prev => [...prev, ...response.data]);
          });
      });
  }, [companies, shops.length]);
  useEffect(() => {
    shops.forEach(shop => {
      request
        .get(`shops/${shop.shopId}/roles`, {
          headers: { 'x-company-id': shop.companyId },
        })
        .then(roleResponse => {
          setRoles(prev => [...prev, ...roleResponse.data]);
        });
    });
  }, [shops]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('deviceStatus')
      .orderBy('lastUpdatedAt', 'desc')
      .limit(100)
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const logs = snapshot.docs
            .filter(d => d.id.includes('OldDiniiDoc'))
            .map(value => value.data() as OldDeviceStatus)
            .filter(o => oldCompanyIds.includes(o.companyId)); // NOTE efhのみ
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);
  const openDeviceActionDialog = (status: OldDeviceStatus) => {
    request
      .get(`shops/${status.shopId}/shop_clients`, { headers: { 'x-company-id': status.companyId } })
      .then(shopClientsResponse => {
        setIsOpenDeviceActionDialog(true);
        setShopClients(shopClientsResponse.data as ShopClient[]);
      });
  };

  const getShopNameById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.name;
    } else {
      return '';
    }
  };

  const getOpenTimeById = (shopId: string): string => {
    const shop = shops.find(sh => sh.shopId === shopId);
    if (shop) {
      return shop.open;
    } else {
      return '';
    }
  };

  const getRoleNameById = (roleId: number): string => {
    const role = roles.find(ro => ro.roleId === roleId);
    if (role) {
      return role.name;
    } else {
      return '';
    }
  };

  const softReboot = (uid: string) => {
    const ref = firebase.firestore().collection('deviceRemoteManagement');
    ref
      .doc(uid)
      .get()
      .then(d => {
        if (window.confirm(`${uid}を再起動しますか？`)) {
          if (d.exists) {
            ref.doc(uid).update({
              softRebootId: firebase.firestore.FieldValue.increment(1),
            });
          } else {
            ref.doc(uid).set({
              softRebootId: 1,
            });
          }
        } else {
          window.alert('キャンセルされました'); // 警告ダイアログを表示
        }
      });
  };

  const reboot = (uid: string) => {
    const ref = firebase.firestore().collection('deviceRemoteManagement');
    ref
      .doc(uid)
      .get()
      .then(d => {
        if (window.confirm(`${uid}を再起動しますか？`)) {
          if (d.exists) {
            ref.doc(uid).update({
              rebootId: firebase.firestore.FieldValue.increment(1),
            });
          } else {
            ref.doc(uid).set({
              rebootId: 1,
            });
          }
        } else {
          window.alert('キャンセルされました'); // 警告ダイアログを表示
        }
      });
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        <p>
          Statusについて:
          <br />
          reboot→再起動実行時のログ
          <br /> waitingHome→通信開始待ち受け画面に到達した時のログ <br />
          startOperation→通信開始を押した時のログ
          <br />
          <br />
          ・正しい遷移は，夜間にreboot→waitingHome→営業開始時間前にstartOperation
          <br />
          ・last rebooted at 欄は，一日以上リブートがない場合に赤くなります
        </p>
        <hr />
        <p>
          異常かなと思ったら:
          <br />
          waitingHomeから開店時刻になってもstartOperationされない→電源切れの可能性あり．店舗へ連絡する
          <br />
          Battery欄のバッテリーがゼロである → 電源切れの可能性あり．店舗へ連絡する
          <br />
          last rebooted at が赤く点灯している →
          起動時間が24時間を超えている．長時間起動は不具合になるため，早急に店舗に連絡し再起動処理をしてもらう
        </p>
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">開店時間</TableCell>
              <TableCell align="right">Role ID</TableCell>
              <TableCell align="right">Role Name</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
              <TableCell align="right">Battery</TableCell>
              <TableCell align="right">Last rebooted at</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  setSelectedStatus(status);
                  setLogModalOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">{getShopNameById(status.shopId)}</TableCell>
                <TableCell align="right">{getOpenTimeById(status.shopId)}</TableCell>
                <TableCell align="right">{status.roleId}</TableCell>
                <TableCell align="right">{getRoleNameById(status.roleId)}</TableCell>
                <TableCell align="right">
                  {moment(status.lastUpdatedAt.toDate()).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
                <TableCell align="right">{status.battery}</TableCell>
                <TableCell
                  className={clsx(
                    status.lastRebootedAt && isToday(status.lastRebootedAt.toDate())
                      ? classes.okCell
                      : classes.ngCell,
                  )}
                  align="right"
                >
                  {status.lastRebootedAt && formatDate(status.lastRebootedAt.toDate())}
                </TableCell>
                <TableCell>
                  <Button onClick={() => openDeviceActionDialog(status)}>Open device action</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <OldDeviceLogsDialog
        isOpen={logModalOpen}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        roleId={selectedStatus.roleId}
        onClose={() => setLogModalOpen(false)}
      />
      <Dialog
        open={isOpenDeviceActionDialog}
        onClose={() => setIsOpenDeviceActionDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Device actions</DialogTitle>
        <DialogContent>
          <List>
            {shopClients.map(shopClient => {
              return (
                <ListItem key={shopClient.shopClientId}>
                  <div style={{ width: '40%', wordBreak: 'break-all', wordWrap: 'break-word' }}>
                    {shopClient.name}({shopClient.shopClientId})
                  </div>
                  <div style={{ width: '50%', marginLeft: '10%' }}>
                    <Button
                      variant={'contained'}
                      onClick={() => softReboot(shopClient.shopClientId)}
                    >
                      アプリ再起動
                    </Button>
                    <Button variant={'contained'} onClick={() => reboot(shopClient.shopClientId)}>
                      端末再起動
                    </Button>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OldDeviceStatus;
