import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { TransitionProps } from '@material-ui/core/transitions';
import firebase from '../../../../plugins/firebase';
import moment from 'moment-timezone';

const Moment = require('moment');

const formatDate = (date: Date) => Moment(date).format('YYYY-MM-DD HH:mm');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface ConnectionMonitoringLog {
  companyId: number;
  shopId: string;
  roleId: number;
  loggedAt: any;
  type: string;
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // eslint-disable-next-line
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  companyId?: number;
  shopId?: string;
  roleId?: number;
  onClose: () => void;
}

const OldConnectionLogsDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([] as ConnectionMonitoringLog[]);

  useEffect(() => {
    if (!(props.companyId && props.shopId && props.roleId)) {
      return undefined;
    }
    const subscribe = firebase
      .firestore()
      .collection('connectionMonitoring')
      .orderBy('loggedAt', 'desc')
      .endAt(
        moment()
          .subtract(1, 'd')
          .toDate(),
      )
      .onSnapshot(
        snapshot => {
          console.log('get query');
          const innerLogs = snapshot.docs
            .filter(value => {
              const log = value.data() as ConnectionMonitoringLog;
              return (
                log.shopId === props.shopId &&
                log.companyId === props.companyId &&
                log.roleId === props.roleId
              );
            })
            .map(v => v.data()) as ConnectionMonitoringLog[];
          setLogs(innerLogs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return subscribe;
  }, [props]);
  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={props.isOpen}
        onClose={props.onClose}
        onClick={props.onClose}
      >
        <DialogTitle id="scroll-dialog-title">
          {`${props.companyId}-${props.shopId}-${props.roleId}`}
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">logged at</TableCell>
                <TableCell align="left">type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{formatDate(log.loggedAt.toDate())}</TableCell>
                  <TableCell align="left">{log.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OldConnectionLogsDialog;
