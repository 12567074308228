import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import request from '../../../plugins/api';
import { PrintStatus } from '../../../entity/order.entity';
import { Role } from '../../../entity/role.entity';
import moment from 'moment-timezone';
import firebase from '../../../plugins/firebase';
import { firestoreVersionCode } from '../../../constants';
import { DishUpReceipt } from '../../../entity/dishUpReceipt.entity';
import PrintStatusComponent from '../components/printStatusComponent';
import { useShops } from '../../../hooks/useShops';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const DishUps: React.FC = () => {
  const classes = useStyles();
  // shop/company selection
  const [roles, setRoles] = useState([] as Role[]);
  const [dishUps, setDishUpReceipts] = useState([] as DishUpReceipt[]);
  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [selectedDishUp, setSelectedDishUp] = useState(null as null | DishUpReceipt);

  const { selectedShop } = useShops();

  useEffect(() => {
    if (!selectedShop) return;
    request
      .get(`shops/${selectedShop.shopId}/roles`, {
        headers: { 'x-company-id': selectedShop.companyId },
      })
      .then(response => {
        setRoles(response.data);
      });
    const queryStartAt = moment()
      .subtract(24, 'h')
      .unix();
    console.log('query start at:', queryStartAt);
    const unsubscribe = firebase
      .firestore()
      .collection(
        `versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/dishUpReceipts`,
      )
      .where('orderedAt', '>', queryStartAt)
      .orderBy('orderedAt', 'desc')
      .onSnapshot(snapshot => {
        const dishUps = snapshot.docs.map(snapshot => snapshot.data()) as DishUpReceipt[];
        setDishUpReceipts(dishUps);
      });
    return unsubscribe;
  }, [selectedShop]);

  const toPrinted = (dishUp: DishUpReceipt) => {
    updatePrintStatus(dishUp, 'printed');
  };

  const toUnPrint = (dishUp: DishUpReceipt) => {
    updatePrintStatus(dishUp, 'unPrint');
  };

  const updatePrintStatus = (dishUp: DishUpReceipt, to: 'printed' | 'unPrint') => {
    const currentStatus = dishUp.status;
    const updatedStatus = {} as PrintStatus;
    Object.keys(currentStatus).forEach(_roleId => {
      const roleId = Number(_roleId);
      updatedStatus[roleId] = {
        isPrinted: to === 'printed',
        isRead: to === 'printed',
      };
    });
    request.post(
      `shops/${dishUp.shopId}/dishUpReceipts/${dishUp.dishUpReceiptId}`,
      { status: updatedStatus },
      { headers: { 'x-company-id': dishUp.companyId } },
    );
  };

  const addRole = (role: Role, dishUp: DishUpReceipt) => {
    if (!selectedShop) return;
    const currentDishUpStatus = dishUp.status;
    const newDishUpStatus = {
      ...currentDishUpStatus,
      [role.roleId]: {
        isRead: false,
        isPrinted: false,
      },
    };
    firebase
      .firestore()
      .collection(
        `versionCode/${firestoreVersionCode}/process/${selectedShop.shopId}/dishUpReceipts`,
      )
      .doc(dishUp.dishUpReceiptId)
      .update({ status: newDishUpStatus });
    setSelectedDishUp(null);
  };

  return (
    <>
      <div style={{ margin: '30px', padding: '20px', border: 'solid 1px #333333' }}>
        Note: 過去24時間分のみ表示，リアルタイム更新
      </div>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">status</TableCell>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">DishUpReceipt ID</TableCell>
              <TableCell align="right">Ordered at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dishUps.map((dishUp, index) => (
              <TableRow key={index}>
                <TableCell align="right">
                  <PrintStatusComponent printStatus={dishUp.status} roles={roles} />
                  <Button onClick={() => toUnPrint(dishUp)}>未印刷にする</Button>
                  <Button onClick={() => toPrinted(dishUp)}>印刷済みにする</Button>
                  <Button color="secondary" onClick={() => setSelectedDishUp(dishUp)}>
                    印刷先を追加する
                  </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {dishUp.companyId}
                </TableCell>
                <TableCell align="right">{dishUp.shopId}</TableCell>
                <TableCell align="right">{selectedShop && selectedShop.name}</TableCell>
                <TableCell align="right">{dishUp.dishUpReceiptId}</TableCell>
                <TableCell align="right">
                  {moment(dishUp.orderedAt * 1000)
                    .tz('Asia/Tokyo')
                    .format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={selectedDishUp !== null}
        onClose={() => setSelectedDishUp(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Role</DialogTitle>
        {roles.map(role => {
          return (
            <Button key={role.roleId} onClick={() => addRole(role, selectedDishUp!)}>
              {role.name}
            </Button>
          );
        })}
        <DialogActions />
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default DishUps;
