import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Paper,
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Shop } from '../../../entity/shop.entity';
import { Role } from '../../../entity/role.entity';
import { Menu } from '../../../entity/menu.entity';
import request from '../../../plugins/api';
import moment from 'moment-timezone';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  shop: Shop;
  isOpen: boolean;
  roles: Role[];
  onClose: () => void;
}

const sendTestOrder = (companyId: number, shopId: string, menu: Menu, roleIds: string[]) => {
  const orderedAt = moment().unix();
  const roleIdsStatus = {} as { [key: string]: any };
  roleIds.forEach(roleId => {
    roleIdsStatus[`${roleId}`] = {
      isRead: false,
      isPrinted: false,
    };
  });
  request.post(
    `shops/${shopId}/orders`,
    {
      companyId: companyId,
      userId: 'testOrderUserId',
      menuId: menu.menuId,
      quantity: 1,
      unitPriceWithOption: 100,
      price: menu.price,
      priceAll: menu.price,
      name: menu.name,
      tableId: 1,
      tableUserId: 'testOrderTableUser',
      orderedAt: orderedAt,
      menuTypeId: menu.menuTypeId,
      categoryId: menu.categoryId,
      tableName: '特等席',
      userName: 'testUser',
      roleIds: roleIds,
      status: roleIdsStatus,
    },
    { headers: { 'x-company-id': companyId } },
  );
};

const TestOrderDialog: React.FC<Props> = ({ shop, isOpen, onClose, roles }: Props) => {
  const [menu, setMenu] = useState({} as Menu);
  const [menus, setMenus] = useState([] as Menu[]);
  const [roleIds, setRoleIds] = useState([] as string[]);

  useEffect(() => {
    if (!shop) return;
    const f = async () => {
      const menusRes = await request.get(`shops/${shop.shopId}/menus`, {
        headers: { 'x-company-id': shop.companyId },
      });
      setMenus(menusRes.data as Menu[]);
    };
    f();
  }, [shop]);
  const testOrder = () => {
    sendTestOrder(shop.companyId, shop.shopId, menu, roleIds);
  };
  const updateRoleId = (role: Role) => {
    if (Array.isArray(roleIds) && roleIds.includes(`${role.roleId}`)) {
      setRoleIds(roleIds.filter(roleId => roleId !== `${role.roleId}`));
    } else {
      setRoleIds([...(roleIds ? roleIds : []), `${role.roleId}`]);
    }
  };

  return (
    <>
      <Dialog TransitionComponent={Transition} open={isOpen} onClose={onClose}>
        <DialogTitle id="scroll-dialog-title">send test order</DialogTitle>
        <DialogContent>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: '80%', marginBottom: '30px' }}
            onClick={() => testOrder()}
          >
            send
          </Button>
          <Paper style={{ background: '#c0c0c0', padding: '5px', marginTop: '12px' }}>
            <div
              style={{
                margin: '10px',
                padding: '10px',
                background: 'white',
                border: 'solid 1px black',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    roleIds: {JSON.stringify(roleIds)}
                    <List dense style={{ width: '80%' }}>
                      {roles.map((role, roleIndex) => (
                        <ListItem key={roleIndex} button>
                          <ListItemText id={`${role.roleId}`} primary={role.name} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => updateRoleId(role)}
                              checked={Array.isArray(roleIds) && roleIds.includes(`${role.roleId}`)}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p>
                    menuId: {menu.menuId}
                    <List dense style={{ width: '80%' }}>
                      {menus.map((_menu, menuIndex) => (
                        <ListItem key={menuIndex} button>
                          <ListItemText id={`${_menu.menuId}`} primary={_menu.name} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => setMenu(_menu)}
                              checked={menu.menuId === _menu.menuId}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </p>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Button onClick={onClose}>close</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TestOrderDialog;
