import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { TransitionProps } from '@material-ui/core/transitions';
import firebase from '../../../../plugins/firebase';

const Moment = require('moment');

const formatDate = (date: Date) => Moment(date).format('YYYY-MM-DD HH:mm:ss');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

interface DeviceMonitoringLog {
  companyId: string;
  shopId: string;
  roleId: string;
  isCharging: boolean;
  battery: number;
  loggedAt: any;
  type: string;
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props, ref) => <Slide direction="up" ref={ref} {...props} />,
);

interface Props {
  isOpen: boolean;
  companyId?: number;
  shopId?: string;
  roleId?: number;
  onClose: () => void;
}

const OldDeviceLogsDialog: React.FC<Props> = ({
  companyId,
  shopId,
  roleId,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([] as DeviceMonitoringLog[]);

  useEffect(() => {
    const unsubscribe =
      companyId && shopId && roleId
        ? firebase
            .firestore()
            .collection('deviceMonitoring')
            .where('companyId', '==', companyId)
            .where('shopId', '==', shopId)
            .where('roleId', '==', roleId)
            .orderBy('loggedAt', 'desc')
            .onSnapshot(
              snapshot => {
                console.log('get query');
                const logData = snapshot.docs.map(value => value.data()) as DeviceMonitoringLog[];
                setLogs(logData);
              },
              err => {
                console.log(`Encountered error: ${err}`);
              },
            )
        : () => {};
    return unsubscribe;
  }, [companyId, shopId, roleId]);
  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
        onClick={onClose}
      >
        <DialogTitle id="scroll-dialog-title">{`${companyId}-${shopId}-${roleId}`}</DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">logged at</TableCell>
                <TableCell align="left">type</TableCell>
                <TableCell align="left">battery</TableCell>
                <TableCell align="left">charging</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{formatDate(log.loggedAt.toDate())}</TableCell>
                  <TableCell align="left">{log.type}</TableCell>
                  <TableCell align="left">{log.battery}</TableCell>
                  <TableCell align="left">{log.isCharging ? 'true' : 'false'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OldDeviceLogsDialog;
